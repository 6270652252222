<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Edit Webform</h1>
        </div>
        <div class="flex flex-row">
            <div class="flex-grow">
                <div class="m-5 bg-white shadow-md rounded">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Form Details</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('details')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="pl-4">
                        <p class="text-red-500 text-xs italic" v-if="errors && errors.webform">{{ errors.webform[0] }}</p>
                    </div>
                    <div class="bg-white my-6 p-4">
                    <div class="w-full">

                        <!-- Row One -->
                        <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="form-label" for="name">Name</label>
                            <input class="form-text" id="name" type="text" v-model="webform.name">
                            <p class="text-red-500 text-xs italic" v-if="errors && errors.name">{{ errors.name[0] }}</p>
                        </div>
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="form-label" for="status">Status</label>
                            <div class="relative">
                            <select class="form-select" id="status" name="status" v-model="webform.status">
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                            </div>
                            <p class="text-red-500 text-xs italic" v-if="errors && errors.status">{{ errors.status[0] }}</p>
                        </div>
                        </div>

                        <!-- Row Two -->
                        <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="form-label" for="fb_sequence_id">Sequence</label>
                            <div class="relative">
                            <select class="form-select" id="fb_sequence_id" name="fb_sequence_id" v-model="webform.fb_sequence_id">
                                <option :value="null">- Select Sequence -</option>
                                <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                            </div>
                            <p class="text-red-500 text-xs italic" v-if="errors && errors.fb_sequence_id">{{ errors.fb_sequence_id[0] }}</p>
                        </div>
                        </div>

                        <!-- Row Three -->
                        <div class="flex flex-wrap -mx-3 mb-6">

                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="form-label" for="button_text">Submit Button Label</label>
                            <input class="form-text" id="button_text" type="text" v-model="webform.button_text">
                            <p class="text-red-500 text-xs italic" v-if="errors && errors.button_text">{{ errors.button_text[0] }}</p>
                        </div>

                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="form-label" for="captcha_type">Captcha Type</label>
                            <div class="relative">
                            <select class="form-select" id="captcha_type" name="captcha_type" v-model="webform.captcha_type">
                                <option value="none">None</option>
                                <option value="text">Text</option>
                                <!--<option value="recaptcha">reCAPTCHA</option>-->
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                            </div>
                            <p class="text-red-500 text-xs italic" v-if="errors && errors.captcha_type">{{ errors.captcha_type[0] }}</p>
                        </div>
                        </div>

                        <!-- Row four -->
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="form-label" for="success_type">Success Type</label>
                                <div class="relative">
                                <select class="form-select" id="success_type" name="success_type" v-model="webform.success_type">
                                    <option value="send_message">Display Message</option>
                                    <option value="redirect">Redirect to URL</option>
                                </select>
                                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                </div>
                                </div>
                                <p class="text-red-500 text-xs italic" v-if="errors && errors.success_type">{{ errors.success_type[0] }}</p>
                            </div>

                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0" v-if="webform.success_type === 'send_message'">
                                <label class="form-label" for="success_message">Success Message</label>
                                <input class="form-text" id="success_message" name="success_message" type="text" v-model="webform.success_message">
                                <p class="text-red-500 text-xs italic" v-if="errors && errors.success_message">{{ errors.success_message[0] }}</p>
                            </div>
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0" v-if="webform.success_type === 'redirect'">
                                <label class="form-label" for="success_redirect_url">Redirect URL</label>
                                <input class="form-text" id="success_redirect_url" name="success_redirect_url" type="text" v-model="webform.success_redirect_url">
                                <p class="text-red-500 text-xs italic" v-if="errors && errors.success_redirect_url">{{ errors.success_redirect_url[0] }}</p>
                            </div>
                        </div>

                        <!-- Row Five -->
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="form-label" for="success_type">Optin Message</label>
                                <textarea class="form-text" id="optin_message" v-model="webform.optin_message"></textarea>
                                <p class="text-red-500 text-xs italic" v-if="errors && errors.optin_message">{{ errors.optin_message[0] }}</p>
                            </div>

                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="form-label" for="optin_button_text">Optin Button Text</label>
                                <input class="form-text" id="optin_button_text" name="optin_button_text" type="text" v-model="webform.optin_button_text">
                                <p class="text-red-500 text-xs italic" v-if="errors && errors.optin_button_text">{{ errors.optin_button_text[0] }}</p>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="m-5 bg-white shadow-md rounded">
                    <div class="flex">
                        <h2 class="flex-1 p-4">URL Details</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('url')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="pl-4">
                        <p class="text-red-500 text-xs italic" v-if="errors && errors.urls">{{ errors.urls[0] }}</p>
                        <p class="text-red-500 text-xs italic" v-if="errors && errors['urls.values']">{{ errors['urls.values'][0] }}</p>
                    </div>
                    <div class="bg-white my-6 p-4">
                    <div class="w-full">
                        <div class="flex flex-wrap -mx-3 mb-6" v-for="(value, index) in webform.urls" v-bind:key="index">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <input class="form-text" :id="'urls_'+index" :name="'urls_'+index" type="text" v-model="value.url">
                                <p class="text-red-500 text-xs italic" v-if="errors && errors['urls.'+index+'.url']">{{ errors['urls.'+index+'.url'][0] }}</p>
                            </div>
                            <div class="w-full md:w-1/5 cursor-pointer h-full pl-6 text-right">
                                <button class="btn btn-alert" @click="removeURL(index)"><i class="fas fa-minus-circle"></i> REMOVE</button>
                            </div>
                        </div>

                        <div class="flex flex-wrap mx-3 mb-6">
                            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-3" @click="addURL()"><i class="fas fa-plus-circle"></i> ADD</button>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="m-5 bg-white shadow-md rounded">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Field Details</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('field')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="pl-4">
                        <p class="text-red-500 text-xs italic" v-if="errors && errors.fields">{{ errors.fields[0] }}</p>
                        <p class="text-red-500 text-xs italic" v-if="errors && errors['fields']">{{ errors['fields'][0] }}</p>
                    </div>
                    <div class="bg-white my-6 p-4">
                        <div class="w-full">
                            <draggable :list="webform.fields" handle=".handle">
                                <div class="flex gap-4 -mx-3 mb-6" v-for="(field, index) in webform.fields" v-bind:key="index">
                                    <div class="px-3 mb-6 md:mb-0 flex-initial">
                                        <i class="fa fa-align-justify handle pt-8"></i>
                                    </div>

                                    <div class="px-3 mb-6 md:mb-0 w-1/7">
                                        <label class="form-label" :for="'fields_'+index+'_fb_attribute_id'">Attribute</label>
                                        <div class="relative" v-if="field.fb_attribute_id === 1">
                                            <select class="form-select" :id="'fields_'+index+'_fb_attribute_id'" :name="'fields_'+index+'_fb_attribute_id'" v-model="field.fb_attribute_id" disabled>
                                                <option :value="1">Phone Number</option>
                                            </select>
                                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            </div>
                                        </div>
                                        <div class="relative" v-if="field.fb_attribute_id !== 1">
                                            <select class="form-select" :id="'fields_'+index+'_fb_attribute_id'" :name="'fields_'+index+'_fb_attribute_id'" v-model="field.fb_attribute_id">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </select>
                                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            </div>
                                        </div>
                                        <p class="text-red-500 text-xs italic" v-if="errors && errors['fields.'+index+'.fb_attribute_id']">{{ errors['fields.'+index+'.fb_attribute_id'][0] }}</p>
                                    </div>
                                    <div class="px-3 mb-6 md:mb-0 w-1/7">
                                        <label class="form-label" :for="'fields_'+index+'_type'">Type</label>
                                        <div class="relative">
                                            <select class="form-select" :id="'fields_'+index+'_type'" :name="'fields_'+index+'_type'" v-model="field.type" :disabled="field.fb_attribute_id === 1">
                                                <option :value="null">- Select Type -</option>
                                                <option value="input">Text Input</option>
                                                <option value="textarea">Text Area</option>
                                                <option value="select">Select</option>
                                                <option value="checkbox">Checkbox</option>
                                                <option value="hidden">Hidden</option>
                                                <option value="query_param">Query Param</option>
                                            </select>
                                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            </div>
                                        </div>
                                        <p class="text-red-500 text-xs italic" v-if="errors && errors['fields.'+index+'.type']">{{ errors['fields.'+index+'.type'][0] }}</p>
                                    </div>

                                    <div class="px-3 mb-6 md:mb-0 w-1/7">
                                        <label class="form-label" :for="'fields_'+index+'_is_required'">Required?</label><br />
                                        <input class="form-checkbox" :id="'fields_'+index+'_is_required'" :name="'fields_'+index+'_is_required'" type="checkbox" v-model="field.params.is_required" :value="true" :disabled="field.fb_attribute_id === 1">
                                        <p class="text-red-500 text-xs italic" v-if="errors && errors['fields.'+index+'.params.is_required']">{{ errors['fields.'+index+'.params.is_required'][0] }}</p>
                                    </div>

                                    <div class="px-3 mb-6 md:mb-0 w-1/7" v-if="field.type !== 'hidden' && field.type !== 'query_param'">
                                        <label class="form-label" :for="'fields_'+index+'_label'">Label</label>
                                        <input class="form-text" :id="'fields_'+index+'_label'" :name="'fields_'+index+'_label'" type="text" v-model="field.params.label">
                                        <p class="text-red-500 text-xs italic" v-if="errors && errors['fields.'+index+'.params.label']">{{ errors['fields.'+index+'.params.label'][0] }}</p>
                                    </div>
                                    <div class="px-3 mb-6 md:mb-0 w-1/7" v-if="field.type === 'hidden'">
                                        <label class="form-label" :for="'fields_'+index+'_value'">Value</label>
                                        <input class="form-text" :id="'fields_'+index+'_value'" :name="'fields_'+index+'_value'" type="text" v-model="field.params.value">
                                        <p class="text-red-500 text-xs italic" v-if="errors && errors['fields.'+index+'.params.value']">{{ errors['fields.'+index+'.params.value'][0] }}</p>
                                    </div>
                                    <div class="px-3 mb-6 md:mb-0 w-1/7" v-if="field.type === 'query_param'">
                                        <label class="form-label" :for="'fields_'+index+'_param'">Param</label>
                                        <input class="form-text" :id="'fields_'+index+'_param'" :name="'fields_'+index+'_param'" type="text" v-model="field.params.param">
                                        <p class="text-red-500 text-xs italic" v-if="errors && errors['fields.'+index+'.params.param']">{{ errors['fields.'+index+'.params.param'][0] }}</p>
                                    </div>

                                    <div class="px-3 mb-6 md:mb-0 w-1/7" v-if="field.type !== 'checkbox' && field.type !== 'hidden'">
                                        <label class="form-label" :for="'fields_'+index+'_default_value'">Default Value</label>
                                        <input class="form-text" :id="'fields_'+index+'_default_value'" :name="'fields_'+index+'_default_value'" type="text" v-model="field.params.default_value">
                                        <p class="text-red-500 text-xs italic" v-if="errors && errors['fields.'+index+'.params.default_value']">{{ errors['fields.'+index+'.params.default_value'][0] }}</p>
                                    </div>
                                    <div class="px-3 mb-6 md:mb-0 w-1/7" v-if="field.type === 'checkbox' || field.type === 'hidden'">

                                    </div>

                                    <div class="px-3 mb-6 md:mb-0 w-1/7" v-if="field.type !== 'checkbox' && field.type !== 'hidden' && field.type !== 'query_param'">
                                        <label class="form-label" :for="'fields_'+index+'_placeholder'">Placeholder</label>
                                        <input class="form-text" :id="'fields_'+index+'_placeholder'" :name="'fields_'+index+'_placeholder'" type="text" v-model="field.params.placeholder">
                                        <p class="text-red-500 text-xs italic" v-if="errors && errors['fields.'+index+'.params.placeholder']">{{ errors['fields.'+index+'.params.placeholder'][0] }}</p>
                                    </div>
                                    <div class="px-3 mb-6 md:mb-0 w-1/7" v-if="field.type === 'checkbox' || field.type === 'hidden' || field.type === 'query_param'">

                                    </div>

                                    <div class="cursor-pointer h-full pl-6 mt-6 text-right w-1/7">
                                        <button class="btn btn-alert" @click="removeField(index)" v-if="field.fb_attribute_id !== 1"><i class="fas fa-minus-circle"></i> REMOVE</button>
                                    </div>
                                </div>
                            </draggable>

                            <div class="flex flex-wrap mx-3 mb-6">
                                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-3" @click="addField()"><i class="fas fa-plus-circle"></i> ADD</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-wrap m-5 px-3 pt-3">
                    <button class="btn btn-primary" v-on:click="save">
                        Update Webform
                    </button>

                    <button class="btn btn-secondary" v-on:click="cancel">
                        Cancel
                    </button>
                </div>
            </div>

            <div class="w-64" v-if="show_help_section">
                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'details'">
                    <h2 class="p-4">Webform Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Webforms provide the necessary code to setup a customizable web form that will submit data to the Chat system, and initiate an opt-in request with Facebook (if the person opts in).</p>
                            <p class="text-xs italic pb-2"><strong>Name</strong> - Provide a descriptive, unique name for the webform to help identify it for future edits and use.  Example: Join Us on Facebook.</p>
                            <p class="text-xs italic pb-2"><strong>Sequence</strong> - The sequence to trigger when the form is submitted.  This will be triggered if the person chooses to opt in.</p>
                            <p class="text-xs italic pb-2"><strong>Submit Button Label</strong> - Text to be shown on the submit button when displayed on the form.</p>
                            <p class="text-xs italic pb-2"><strong>Success Type</strong> - The type of success action to take.  Display Message will show a message, replacing the form.  Redirect to URL will send the
                            person to another page or site.</p>
                            <p class="text-xs italic pb-2"><strong>Optin Message</strong> - The message to display to the person asking them to select the provided button or reply to opt in to your page bot.</p>
                            <p class="text-xs italic pb-2"><strong>Optin Button Text</strong> - The text shown on the button included with the opt in message.  This should be a call to action, such as "Opt In" or "Chat With Our Bot"</p>
                        </div>
                    </div>
                </div>

                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'url'">
                    <h2 class="p-4">URL Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">To help secure forms and make sure submissions only come from approved sources, any URL that hosts a webform must be listed here before it can be activated.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'field'">
                    <h2 class="p-4">Field Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Fields are the individual form fields that the user can interact with, either directly or indirectly.  Phone Number is provided as the first field, and is
                                required on all forms.
                            </p>
                            <p class="text-xs italic pb-2"><strong>Text Input</strong> - Single line text input field.</p>
                            <p class="text-xs italic pb-2"><strong>Text Area</strong> - Multi-line text input field.</p>
                            <p class="text-xs italic pb-2"><strong>Select</strong> - Dropdown select list (only valid for attributes with predefined values).  Default value will act as the initial, empty value option and Placeholder
                            will select one of the predefined values when the form initially loads.</p>
                            <p class="text-xs italic pb-2"><strong>Checkbox</strong> - Checkbox field (only valid for attributes that have the yes/no type).</p>
                            <p class="text-xs italic pb-2"><strong>Hidden</strong> - Hidden input field that can have a static value provided.</p>
                            <p class="text-xs italic pb-2"><strong>Query Param</strong> - Hidden input field that can have a value provided as a default, or through a query string parameter on the page hosting the form.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'
    import Vue from 'vue'
    import draggable from 'vuedraggable'

    export default {
        name: 'EditWebform',
        components: {
            LayoutApp,
            draggable
        },
        created() {
            this.$emit('update:layout', LayoutApp)
            // Is this right?  Feels odd, but keeps the webform from building before the
            // attributes are ready.
            this.fetchSequences()
            this.fetchAttributes()
            this.fetchWebform()
        },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        data() {
            return {
                attributes: [],
                sequences: [],
                webform: {
                    fb_bot_id: this.$store.getters.current_bot_id,
                    name: '',
                    status: 'active',
                    captcha_type: 'none',
                    fb_sequence_id: null,
                    success_type: 'send_message',
                    success_message: '',
                    success_redirect_url: '',
                    optin_message: '',
                    optin_button_text: '',
                    urls: [
                        {
                            url: '',
                        }
                    ],
                    fields: [
                        {
                            fb_attribute_id: 1,
                            type: 'input',
                            params: {
                                is_required: true,
                                label: 'Phone Number',
                                param: '',
                                placeholder: '555-555-5555',
                                default_value: '',
                                value: '',
                            },
                        }
                    ],
                },
                errors: [],
                show_help_section: null,
            }
        },
        methods: {
            fetchWebform(data) {
                axios.get(this.webformUrl(this.id)).then(this.refreshWebform)
            },
            webformUrl() {
                return `/api/v1/webforms/${this.id}`;
            },
            refreshWebform({data}) {
                this.webform = data.data;
            },
            fetchSequences() {
                axios.get(this.sequenceUrl()).then(this.refreshSequences);
            },
            sequenceUrl() {
                return `/api/v1/sequences?fb_bot_id=${this.$store.getters.current_bot_id}&per_page=-1`;
            },
            refreshSequences({ data }) {
                this.sequences = data.data;
            },
            fetchAttributes() {
                return axios.get(this.attributesUrl()).then(this.refreshAttributes);
            },
            attributesUrl() {
                return `/api/v1/attributes?per_page=-1&search_scopes=client`;
            },
            refreshAttributes({ data }) {
                this.attributes = data.data;
            },
            save() {
              let item = JSON.parse(JSON.stringify(this.webform));

              axios.put('/api/v1/webforms/'+this.id, item).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The webform has been updated',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_webforms'});
              }).catch(error => {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
                } else {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while updating the webform',
                        duration: 5000,
                        speed: 1000
                    });
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            addURL() {
                this.webform.urls.push({
                    url: '',
                })
            },
            removeURL(index) {
                Vue.delete(this.webform.urls, index);
            },
            addField() {
                this.webform.fields.push({
                    fb_attribute_id: null,
                    type: 'input',
                    params: {
                        is_required: false,
                        label: '',
                        param: '',
                        placeholder: '',
                        default_value: '',
                        value: '',
                    },
                });
            },
            removeField(index) {
                Vue.delete(this.webform.fields, index);
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }
</script>
