require('./bootstrap');

import Vue from 'vue';
import VueRouter from 'vue-router';
import VModal from 'vue-js-modal';
import Notifications from 'vue-notification'
import Axios from 'axios';
import moment from 'moment-timezone'
import Paginate from 'vuejs-paginate'
import Datepicker from 'vue2-datepicker';
import Multiselect from 'vue-multiselect'
import VueMoment from 'vue-moment'
import Chartkick from 'vue-chartkick'
import VueCookies from 'vue-cookies'
import Chart from 'chart.js'
import VueClipboard from 'vue-clipboard2'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import VTooltip from 'v-tooltip'
import Main from './components/Main';
import bugsnag from '@bugsnag/js'
import bugsnagVue from '@bugsnag/plugin-vue'

import router from './routes';
import store from './store';

moment.tz.setDefault('UTC');

var bugsnagClient = bugsnag(process.env.MIX_BUGSNAG_API_KEY);
bugsnagClient.use(bugsnagVue, Vue);

Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false } })
Vue.use(Notifications)
Vue.use(VueMoment, {moment})
Vue.use(Chartkick.use(Chart))
Vue.use(VueCookies)
Vue.use(VueClipboard)
Vue.use(VTooltip)
Vue.component('paginate', Paginate)
Vue.component('datepicker', Datepicker)
Vue.component('multiselect', Multiselect)
Vue.component(VueQrcode.name, VueQrcode)

Vue.prototype.$http = Axios;
if(window.$cookies.get('token')) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer '+window.$cookies.get('token')
}

Vue.filter('capitalize', function (value) {
    if (!value)
        return value;

    let frags = value.split('_');
    for (let i = 0; i < frags.length; ++i) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
});

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).tz(moment.tz.guess()).format('MM/DD/YYYY h:mma z')
    }
});

// Vue Setup
new Vue({
  render: h => h(Main),
  router,
  store,
}).$mount('#app')
