<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Create Broadcast</h1>
        </div>

        <!-- Facebook Details -->
        <div class="m-5 bg-white shadow-md rounded">
            <h2 class="pt-4 pl-4 pb-2">Facebook Details</h2>
            <div class="bg-white px-6 pb-2">
                <div class="w-full">
                    <!-- Row One -->
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="form-label" for="messaging_type">Messaging Type</label>
                            <div class="relative">
                                <select class="form-select" id="messaging_type" name="messaging_type" v-model="broadcast.messaging_type" @change="updateEstimate()">
                                    <optgroup label="General Types">
                                        <option value="response">Response</option>
                                        <option value="update">Update</option>
                                    </optgroup>
                                    <optgroup label="Specific Types">
                                        <option value="confirmed_event_update">Confirmed Event Update</option>
                                        <option value="post_purchase_update">Post Purchase Update</option>
                                        <option value="account_update">Account Update</option>
                                    </optgroup>
                                </select>
                                <div class="form-select-arrow">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                </div>
                            </div>
                            <p class="text-red-500 text-xs italic" v-if="errors && errors.type">{{ errors.nambroadcaste[0] }}</p>
                        </div>
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <p v-if="broadcast.messaging_type === 'response'"><strong>Response</strong> - This message type should only be used when the broadcast is in response to a request.  If a request is answered by a
                            sequence, or the person's request is already answered, this should not be used.  This can include promotional and non-promotional content.</p>

                            <p v-if="broadcast.messaging_type === 'update'"><strong>Update</strong> - This message type should only be used when acting proactively, and is not in response to a previous request.  This can
                            include promotional and non-promotional content.</p>

                            <p v-if="broadcast.messaging_type === 'confirmed_event_update'"><strong>Confirmed Event Update</strong> - This message type should only be used when updating a person about an event they are already RSVP'ed to
                            or are currently attending.  This should not be used to promote event attendance, invite people, or update people regarding past events.  This can only include non-promotional content.</p>

                            <p v-if="broadcast.messaging_type === 'post_purchase_update'"><strong>Post Purchase Update</strong> - This message type should only be used when updating a person about an order they have placed.  This should be
                            used for shipping details, transaction details, or changes related to the order.  This should not be used to cross-sell or upsell products.  This can only include non-promotional content.</p>

                            <p v-if="broadcast.messaging_type === 'account_update'"><strong>Account Update</strong> - This message type should only be used when updating a person about a non-recurring change to their account.  This should be
                            used changes in account details, such as an application process, or alerts regarding account activites.  This should not be used for recurring details.  This can only include non-promotional content.</p>
                        </div>
                    </div>

                    <!-- Row Two -->
                    <!--<div class="flex flex-wrap -mx-3 mb-6" v-if="broadcast.messaging_type === 'response' || broadcast.messaging_type === 'update'">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="form-label" for="messaging_policy">Messaging Policy</label>
                            <div class="relative">
                                <select class="form-select" id="messaging_policy" name="messaging_policy" v-model="broadcast.messaging_policy" @change="updateEstimate()">
                                    <option value="24only">24 Hour Window Only</option>
                                    <option value="plus1only">+1 Window Only</option>
                                    <option value="24plus1">24 Hour Window/+1 Window</option>
                                </select>
                                <div class="form-select-arrow">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                </div>
                            </div>
                            <p class="text-red-500 text-xs italic" v-if="errors && errors.type">{{ errors.name[0] }}</p>
                        </div>
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <p v-if="broadcast.messaging_policy === '24only'"><strong>24 Hour Window Only</strong> - This message will only be sent to people that have interacted with your bot in the last 24 hours.  Users outside that window
                            will not receive a message.</p>

                            <p v-if="broadcast.messaging_policy === 'plus1only'"><strong>+1 Window Only</strong> - This message will only be sent to people that have interacted with your bot over 24 hours ago, and have not received a one off
                            message.  Users within the 24 hour window, or users that have received a one off message will not receive a message.</p>

                            <p v-if="broadcast.messaging_policy === '24plus1'"><strong>24 Hour Window/+1 Window</strong> - This message will only be sent to people that have interacted with your bot in the last 24 hours, or people that have not
                            received a one off message.  Users outside the 24 hour window that have received a one off message will not receive a message.</p>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>

        <!-- Audience Details -->
        <div class="m-5 bg-white shadow-md rounded">
            <h2 class="pt-4 pl-4 pb-2">Audience Details</h2>
            <div class="bg-white px-6 pb-2">
                <div class="w-full">
                    <!-- Row 1 -->
                    <div class="flex flex-wrap -mx-3 mb-6" v-for="(segment, index) in broadcast.audience.segments" v-bind:key="index">
                        <div class="w-full md:w-4/5 px-3 mb-6 md:mb-0">
                            <div class="relative">
                                <select class="form-select" :id="'broadcast.audience.'+index+'.id'" :name="'broadcast.audience.'+index+'.id'" v-model="broadcast.audience.segments[index].id" @change="updateEstimate()">
                                    <option :value="null">- Select Segment -</option>
                                    <option v-for="segment in segments" v-bind:key="segment.id" :value="segment.id">{{ segment.name }}</option>
                                </select>
                                <div class="form-select-arrow">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                </div>
                            </div>
                            <validation-notice :param="'audience.segments.'+index+'.id'" :errors="errors"></validation-notice>
                        </div>
                        <div class="w-full md:w-1/5 cursor-pointer h-full pt-1 pl-6 text-right">
                            <button class="btn btn-alert" @click="removeSegment(index)"><i class="fas fa-minus-circle"></i> REMOVE</button>
                        </div>
                    </div>

                    <div class="flex flex-wrap mx-3 mb-6">
                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-3" @click="addSegment()"><i class="fas fa-plus-circle"></i> ADD SEGMENT</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Broadcast Details -->
        <div class="m-5 bg-white shadow-md rounded">
          <h2 class="pt-4 pl-4 pb-2">Broadcast Details</h2>
          <div class="bg-white px-6 pb-2">
            <p class="text-red-500 text-xs italic" v-if="errors && errors.slices">{{ errors.slices[0] }}</p>
            <div class="w-full pt-6" v-for="(slice, slice_index) in broadcast.slices" v-bind:key="slice_index">

              <!-- Row One -->
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label class="form-label" :for="'broadcast.slices.'+slice_index+'.type'">Type</label>
                  <div class="relative">
                    <select class="form-select" :id="'broadcast.slices.'+slice_index+'.type'" :name="'broadcast.slices.'+slice_index+'.type'" v-model="broadcast.slices[slice_index].type" @change="updateEstimate()">
                        <option value="message">Message</option>
                        <option value="sequence">Sequence</option>
                        <option value="none">None</option>
                    </select>
                    <div class="form-select-arrow">
                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                  </div>
                  <validation-notice :param="'slices.'+slice_index+'.type'" :errors="errors"></validation-notice>
                </div>
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label class="form-label" :for="'broadcast.slices.'+slice_index+'.percentage'">Percentage</label>
                  <div class="relative">
                    <input class="form-text" :id="'broadcast.slices.'+slice_index+'.percentage'" :name="'broadcast.slices.'+slice_index+'.percentage'" type="text" v-model="broadcast.slices[slice_index].percentage" @blur="updateEstimate()">
                  </div>
                  <validation-notice :param="'slices.'+slice_index+'.percentage'" :errors="errors"></validation-notice>
                </div>
              </div>

              <!-- Row Two -->
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-full px-3 mb-6 md:mb-0" v-if="broadcast.slices[slice_index].type === 'message'">
                  <dynamic-textarea
                        label="Message"
                        :id="'slice_'+slice_index+'_message'"
                        :name="'slice_'+slice_index+'_message'"
                        :refName="'slice_'+slice_index+'_message'"
                        max="2000"
                        v-model="broadcast.slices[slice_index].message"
                        @input="broadcast.slices[slice_index].message = $event">
                    </dynamic-textarea>
                    <validation-notice :param="'slices.'+slice_index+'.message'" :errors="errors"></validation-notice>
                </div>
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0" v-if="broadcast.slices[slice_index].type === 'sequence'">
                  <label class="form-label" :for="'broadcast.slices.'+slice_index+'.fb_sequence_id'">Sequence</label>
                  <div class="relative">
                        <select class="form-select" :id="'broadcast.slices.'+slice_index+'.fb_sequence_id'" :name="'broadcast.slices.'+slice_index+'.fb_sequence_id'" v-model="broadcast.slices[slice_index].fb_sequence_id">
                            <option :value="null">- Select Sequences -</option>
                            <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                        </select>
                        <div class="form-select-arrow">
                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                  </div>
                  <validation-notice :param="'slices.'+slice_index+'.fb_sequence_id'" :errors="errors"></validation-notice>
                </div>
              </div>

              <!-- Row Three -->
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label class="form-label" :for="'broadcast.slices.'+slice_index+'.fb_attribute_id'">Tag Attribute</label>
                  <div class="relative">
                    <select class="form-select" :id="'broadcast.slices.'+slice_index+'.fb_attribute_id'" :name="'broadcast.slices.'+slice_index+'.fb_attribute_id'" v-model="broadcast.slices[slice_index].attribute">
                        <option :value="null">- Select Attribute -</option>
                        <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute">{{ attribute.name }}</option>
                    </select>
                    <div class="form-select-arrow">
                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                  </div>
                  <validation-notice :param="'slices.'+slice_index+'.fb_attribute_id'" :errors="errors"></validation-notice>
                </div>
                <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label class="form-label" :for="'broadcast.slices.'+slice_index+'.fb_attribute_value'">Attribute Value</label>
                    <input class="form-text" v-model="broadcast.slices[slice_index].fb_attribute_value" v-if="broadcast.slices[slice_index].attribute && broadcast.slices[slice_index].attribute.values_type === 'open'">
                    <div class="relative" v-if="broadcast.slices[slice_index].attribute && broadcast.slices[slice_index].attribute.values_type === 'predefined'">
                        <select class="form-select" :id="'broadcast.slices.'+slice_index+'.fb_attribute_value'" :name="'broadcast.slices.'+slice_index+'.fb_attribute_value'" v-model="broadcast.slices[slice_index].fb_attribute_value">
                            <option :value="null" disabled>- Select Value -</option>
                            <option v-for="(value, value_index) in broadcast.slices[slice_index].attribute.valid_values.values" v-bind:key="value_index" :value="value.value">{{ value.label || value.value }}</option>
                        </select>
                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                    </div>
                    <validation-notice :param="'slices.'+slice_index+'.fb_attribute_value'" :errors="errors"></validation-notice>
                </div>
                <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0 pt-4 text-right">
                    <button class="btn btn-alert" @click="removeSlice(slice_index)"><i class="fas fa-minus-circle"></i> REMOVE</button>
                </div>
              </div>
            </div>

            <div class="flex flex-wrap mx-3 mb-6">
                <button class="btn btn-primary" @click="addSlice()"><i class="fas fa-plus-circle"></i> ADD SLICE</button>
            </div>
          </div>
        </div>

        <!-- Schedule Details -->
        <div class="m-5 bg-white shadow-md rounded">
          <h2 class="pt-4 pl-4 pb-2">Schedule Details</h2>
          <div class="bg-white px-6 pb-2">
            <div class="w-full">

              <!-- Row One -->
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label class="form-label" for="send_at_type">Send At</label>
                  <div class="relative">
                        <select class="form-select" id="send_at_type" name="send_at_type" v-model="send_at_type">
                            <option value="now">Now</option>
                            <option value="later">Later</option>
                        </select>
                        <div class="form-select-arrow">
                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                  </div>
                  <p class="text-red-500 text-xs italic" v-if="errors && errors.send_at_type">{{ errors.send_at_type[0] }}</p>
                </div>
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0" v-if="send_at_type === 'later'">
                  <label class="form-label" for="send_at">Send At</label>
                  <datepicker input-class="form-text" ref="datepicker" v-model="broadcast.send_at" type="datetime" lang="en" format="YYYY-MM-DD hh:mm a" :time-picker-options="{start: '00:00',step: '00:15',end: '23:45'}"></datepicker>
                  <p class="text-red-500 text-xs italic" v-if="errors && errors.send_at">{{ errors.send_at[0] }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Summary Details -->
        <div class="m-5 bg-white shadow-md rounded">
          <h2 class="pt-4 pl-4 pb-2">Broadcast Summary</h2>
          <div class="bg-white px-6 pb-2">
            <div class="w-full pb-8">
                <p v-if="send_at_type === 'now'">Sending broadcast immediately to {{ estimate.recipients || 0 }} recipients.</p>
                <p v-if="send_at_type !== 'now'">Sending broadcast on {{ sendAtDate }} at {{ sendAtTime }} to {{ estimate.estimate || 0 }} recipients.</p>
                <ul class="pl-5 list-disc">
                    <li v-for="(slice, index) in estimate.slices" v-bind:key="index">Slice {{ (index + 1)}} Estimate: {{ estimate.slices[index].estimate || 0 }} recipients</li>
                </ul>
                <br /><br />
                <p class="text-xs" v-if="broadcast.messaging_type === 'response' || broadcast.messaging_type === 'update'">Please note: Recipient estimates will change closer to the send date/time due to how the 24 hour/+1 is calculated.</p>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap m-5 px-3 pt-3">
            <button class="btn btn-primary" v-on:click="save">
                Create Broadcast
            </button>

            <button class="btn btn-secondary" v-on:click="cancel">
                Cancel
            </button>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment-timezone'
    import LayoutApp from '../../layouts/App'
    import Vue from 'vue'
    import ValidationNotice from '../../shared/ValidationNotice';
    import DynamicTextarea from '../../shared/DynamicTextarea';

    export default {
        name: 'CreateBroadcast',
        components: {
            LayoutApp,
            ValidationNotice,
            DynamicTextarea,
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetchSegments();
            this.fetchSequences();
            this.fetchAttributes();
        },
        computed: {
            sendAtDate: function() {
                return moment(this.broadcast.send_at).format('MMM D, YYYY');
            },
            sendAtTime: function() {
                return moment(this.broadcast.send_at).format('h:mm a');
            }
        },
        data() {
            return {
                segments: [],
                sequences: [],
                attributes: [],
                all_attributes: [],
                send_at_type: 'now',
                broadcast: {
                  fb_bot_id: this.$store.getters.current_bot_id,
                  messaging_type: 'response',
                  messaging_policy: '24only',
                  status: 'active',
                  audience: {
                      segments: [{id: null}],
                      operator: 'OR'
                  },
                  slices: [
                      {
                          percentage: 100,
                          type: 'message',
                          message: '',
                          attribute: null,
                          fb_sequence_id: null,
                          fb_attribute_id: null,
                          fb_attribute_value: null,
                      }
                  ],
                  send_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                },
                estimate: {
                    recipients: 0,
                    slices: [{
                        recipients: 0
                    }]
                },
                errors: [],
            }
        },
        methods: {
            fetchSegments() {
                axios.get(this.segmentsUrl()).then(this.refreshSegments);
            },
            segmentsUrl() {
                return `/api/v1/segments?fb_bot_id=${this.broadcast.fb_bot_id}`;
            },
            refreshSegments({data}) {
                this.segments = data.data;
            },
            fetchSequences() {
                axios.get(this.sequencesUrl()).then(this.refreshSequences);
            },
            sequencesUrl() {
                return `/api/v1/sequences?fb_bot_id=${this.broadcast.fb_bot_id}`;
            },
            refreshSequences({data}) {
                this.sequences = data.data;
            },
            fetchAttributes() {
                axios.get(this.attributesUrl()).then(this.refreshAttributes);
            },
            attributesUrl() {
                return `/api/v1/attributes`;
            },
            refreshAttributes({
                data
            }) {
                for(let index in data.data) {
                    this.all_attributes.push(data.data[index]);

                    if (data.data[index].scope !== 'system') {
                        this.attributes.push(data.data[index]);
                    }
                }
            },
            save() {
              let item = JSON.parse(JSON.stringify(this.broadcast));

              for (let i = 0 ; i < item.slices.length ; ++i) {
                  if (item.slices[i].attribute) {
                    item.slices[i].fb_attribute_id = item.slices[i].attribute.id;
                  }

                  delete item.slices[i].attribute;
              }

              if (this.send_at_type === 'now') {
                  item.send_at = null;
              } else {
                  item.send_at = moment(item.send_at).tz('UTC').format('YYYY-MM-DD HH:mm:ss');
              }

              axios.post('/api/v1/broadcasts', item).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The broadcast has been created',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_broadcasts'});
              }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                } else {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while creating the broadcast',
                        duration: 5000,
                        speed: 1000
                    });
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            addSegment() {
                this.broadcast.audience.segments.push({
                    id: ''
                });
            },
            removeSegment(index) {
                Vue.delete(this.broadcast.audience.segments, index);
                this.updateEstimate();
            },
            addSlice() {
                this.estimate.slices.push({
                    recipient: 0
                });

                this.broadcast.slices.push({
                    percentage: 100,
                    type: 'message',
                    message: '',
                    fb_sequence_id: null,
                    fb_attribute_id: null,
                    fb_attribute_value: null
                });

                this.estimate.slices.push({
                    recipient: 0
                });

                this.updateEstimate();
            },
            removeSlice(index) {
                Vue.delete(this.broadcast.slices, index);
                Vue.delete(this.estimate.slices, index);

                this.updateEstimate();
            },
            updateEstimate() {
                let item = {
                    audience: this.broadcast.audience,
                    slices: this.broadcast.slices,
                    //messaging_policy: this.broadcast.messaging_policy,
                    messaging_type: this.broadcast.messaging_type,
                    fb_bot_id: this.broadcast.fb_bot_id
                };

                axios.post('/api/v1/broadcasts/calculate', item).then(data => {
                    this.estimate.recipients = data.data.estimate;
                    this.estimate.slices = data.data.slices;
                });
            }
        }
    }
</script>
