<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Edit Broadcast</h1>
        </div>
        <div class="m-5 bg-white">
            <h2 class="p-4">Audience Details</h2>
            <div class="bg-white shadow-md rounded my-6 p-4">
                <div class="w-full">
                    <!-- Row 1 -->
                    <div class="flex flex-wrap -mx-3 mb-6" v-for="(segment, index) in broadcast.audience.segments" v-bind:key="index">
                        <div class="w-full md:w-5/6 px-3 mb-6 md:mb-0">
                            <div class="relative">
                                <select class="form-select" id="status" name="status" v-model="broadcast.audience.segments[index].id">
                                    <option value="">- Select Segment -</option>
                                    <option v-for="segment in segments" v-bind:key="segment.id" :value="segment.id">{{ segment.name }}</option>
                                </select>
                                <div class="form-select-arrow">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                </div>
                            </div>
                        </div>
                        <div class="w-full md:w-1/6 cursor-pointer h-full pt-8 pl-6">
                            <i @click="removeSegment(index)" class="text-xl fas fa-minus-circle"></i>
                        </div>
                    </div>

                    <div class="flex flex-wrap mx-3 mb-6">
                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-3" @click="addSegment()"><i class="fas fa-plus-circle"></i> ADD SEGMENT</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="m-5 bg-white">
          <h2 class="p-4">Broadcast Details</h2>
          <div class="bg-white shadow-md rounded my-6 p-4">
            <div class="w-full" v-for="(slice, slice_index) in broadcast.slices" v-bind:key="slice_index">

              <!-- Row One -->
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label class="form-label" for="type">Type</label>
                  <select class="form-select" id="status" name="status" v-model="broadcast.slices[slice_index].type">
                      <option value="message">Message</option>
                      <option value="sequence">Sequence</option>
                    </select>
                    <div class="form-select-arrow">
                      <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                  <p class="text-red-500 text-xs italic" v-if="errors && errors.type">{{ errors.name[0] }}</p>
                </div>
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label class="form-label" for="status">Percentage</label>
                  <div class="relative">
                    <input class="form-text" id="name" type="text" v-model="broadcast.slices[slice_index].percentage">
                  </div>
                  <p class="text-red-500 text-xs italic" v-if="errors && errors.status">{{ errors.status[0] }}</p>
                </div>
              </div>

              <!-- Row Two -->
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-full px-3 mb-6 md:mb-0" v-if="broadcast.slices[slice_index].type === 'message'">
                  <label class="form-label" for="type">Message</label>
                  <textarea class="form-textarea" v-model="broadcast.slices[slice_index].message"></textarea>
                  <p class="text-red-500 text-xs italic" v-if="errors && errors.type">{{ errors.name[0] }}</p>
                </div>
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0" v-if="broadcast.slices[slice_index].type === 'sequence'">
                  <label class="form-label" for="type">Sequence</label>
                    <select class="form-select" id="status" name="status" v-model="broadcast.slices[slice_index].fb_sequence_id">
                        <option value="">- Select Sequences -</option>
                        <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                    </select>
                    <div class="form-select-arrow">
                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                  <p class="text-red-500 text-xs italic" v-if="errors && errors.type">{{ errors.name[0] }}</p>
                </div>
                <div class="w-full cursor-pointer h-full pt-8 pl-6">
                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-3" @click="removeSlice(slice_index)"><i class="fas fa-minus-circle"></i> REMOVE SLICE</button>
                </div>
              </div>
            </div>

            <div class="flex flex-wrap mx-3 mb-6">
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-3" @click="addSlice()"><i class="fas fa-plus-circle"></i> ADD SLICE</button>
            </div>
          </div>
        </div>

        <div class="m-5 bg-white">
          <h2 class="p-4">Schedule Details</h2>
          <div class="bg-white shadow-md rounded my-6 p-4">
            <div class="w-full">

              <!-- Row One -->
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label class="form-label" for="type">Send At</label>
                  <select class="form-select" id="status" name="status" v-model="broadcast.sent_at_type">
                      <option value="now">Now</option>
                      <option value="later">Later</option>
                    </select>
                    <div class="form-select-arrow">
                      <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                  <p class="text-red-500 text-xs italic" v-if="errors && errors.type">{{ errors.name[0] }}</p>
                </div>
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0" v-if="broadcast.sent_at_type === 'later'">
                  <label class="form-label" for="status">Send At</label>
                  <div class="relative">
                    <input class="form-text" id="name" type="text" v-model="broadcast.send_at">
                  </div>
                  <p class="text-red-500 text-xs italic" v-if="errors && errors.status">{{ errors.status[0] }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap m-5 px-3 pt-3">
            <button class="btn btn-primary" v-on:click="save">
                Update Broadcast
            </button>

            <button class="btn btn-secondary" v-on:click="cancel">
                Cancel
            </button>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'
    import Vue from 'vue'
    import ValidationNotice from '../../shared/ValidationNotice';

    export default {
        name: 'UpdateBroadcast',
        components: {
            LayoutApp,
            ValidationNotice,
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetchBroadcast();
            this.fetchSegments();
            this.fetchSequences();
        },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        data() {
            return {
                segments: [],
                sequences: [],
                broadcast: {
                  fb_bot_id: this.$store.getters.current_bot_id,
                  status: 'active',
                  audience: {
                      segments: [],
                      operator: 'OR'
                  },
                  slices: [
                      {
                          percentage: 100,
                          type: 'message',
                          message: '',
                          sequence_id: null,
                      }
                  ],
                  send_at_type: 'now',
                  send_at: null,
                },
                errors: [],
            }
        },
        methods: {
            fetchBroadcast() {
                axios.get(this.broadcastUrl()).then(this.refreshBroadcast);
            },
            broadcastUrl() {
                return `/api/v1/broadcasts/${this.id}`;
            },
            refreshBroadcast({data}) {
                this.broadcast = data.data;
            },
            fetchSegments() {
                axios.get(this.segmentsUrl()).then(this.refreshSegments);
            },
            segmentsUrl() {
                return `/api/v1/segments?fb_bot_id=${this.broadcast.fb_bot_id}`;
            },
            refreshSegments({data}) {
                this.segments = data.data;
            },
            fetchSequences() {
                axios.get(this.sequencesUrl()).then(this.refreshSequences);
            },
            sequencesUrl() {
                return `/api/v1/sequences?fb_bot_id=${this.broadcast.fb_bot_id}`;
            },
            refreshSequences({data}) {
                this.sequences = data.data;
            },
            save() {
              axios.put(`/api/v1/broadcasts/${this.id}`, this.broadcast).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The broadcast has been updated',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_broadcasts'});
              }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                } else {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while updating the broadcast',
                        duration: 5000,
                        speed: 1000
                    });
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            addSegment() {
                this.broadcast.audience.segments.push({
                    id: ''
                });
            },
            removeSegment(index) {
                Vue.delete(this.broadcast.audience.segments, index);
            },
            addSlice() {
                this.broadcast.slices.push({
                    percentage: 100,
                    type: 'message',
                    message: '',
                    sequence_id: null,
                });
            },
            removeSlice(index) {
                Vue.delete(this.broadcast.slices, index);
            },
        }
    }
</script>
