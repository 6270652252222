<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Integrations</h1>
        </div>
        <div class="max-w-sm rounded overflow-hidden shadow-lg m-4">
            <!--<img class="w-full" src="/img/google.png" alt="">-->
            <img class="w-full" src="/img/undraw_voting_nvu7.png" alt="Google Civic API" title="Google Civic API" />
            <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2">Google Civic API <span v-bind:class="{'bg-green-500': (integrations.google_civic_api.status === 'active'), 'bg-gray-600': (integrations.google_civic_api.status === 'inactive')}" class="text-white font-bold py-1 px-2 rounded-full text-sm mx-1">{{ integrations.google_civic_api.status | capitalize }}</span></div>
                <p class="text-gray-700 text-base">
                    Provie your API key to access sequence blocks specific to the Google Civic API.
                </p>
            </div>
            <div class="flex flex-wrap px-6 py-4 mb-2">
                <button class="btn btn-primary" v-on:click="showGoogleCivicAPI()">{{ (integrations.google_civic_api.status === 'inactive' ? 'Enable' : 'Update') }}</button>
            </div>
        </div>

        <modal name="edit-google-civic-api" class="confirm-modal" :height="320" :width="600">
            <div class="modal-container">
                <div class="modal-content">
                    <div class="modal-c-title">Enable</div>
                    <div class="modal-c-text">
                        <div class="relative">
                            <select class="form-select" id="gca_status" name="gca_status" v-model="integrations.google_civic_api.status" @change="updateGoogleCivicStatus()">
                                <option value="active">Enable</option>
                                <option value="inactive">Disable</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                        </div>
                    </div>
                    <div class="modal-c-title">API Key</div>
                    <div class="modal-c-text mt-2">
                        <input type="text" class="form-text" v-model="integrations.google_civic_api.parameters.api_key" :disabled="disable_google_civic_api_key" />
                        <p v-if="google_civic_api_key_error" class="text-red-500 text-xs italic">{{ google_civic_api_key_error }}</p>
                    </div>

                </div>
                <div class="modal-buttons">
                    <button type="button" class="modal-button" @click="$modal.hide('edit-google-civic-api')">Cancel</button>
                    <button type="button" class="modal-button-primary" @click="googleCivicAPITest()">Update</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'

    export default {
        name: 'ListIntegrations',
        components: {
            LayoutApp
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetch(this.$store.getters.current_bot_id, 1);
        },
        data() {
            return {
                integrations: {
                    google_civic_api: {
                        status: 'inactive',
                        parameters: {
                            api_key: null
                        }
                    }
                },
                disable_google_civic_api_key: true,
                google_civic_api_key_error: null
            }
        },
        methods: {
            fetch() {
                axios.get(this.url()).then(this.refresh);
            },
            url() {
                return `/api/v1/integrations`;
            },
            refresh({data}) {
                for (let index in data.data) {
                    if (data.data[index].integration === 'google_civic_api') {
                        this.integrations.google_civic_api.status = data.data[index].status;
                        this.integrations.google_civic_api.parameters = data.data[index].parameters;
                    }
                }

                this.updateGoogleCivicStatus();
                window.scrollTo(0,0);
            },
            showGoogleCivicAPI() {
                this.$modal.show('edit-google-civic-api');
            },
            googleCivicAPITest() {
                this.google_civic_api_key_error = null;

                if (this.integrations.google_civic_api.status === 'active' && this.integrations.google_civic_api.parameters.api_key !== null) {
                    axios.get(`https://www.googleapis.com/civicinfo/v2/elections?key=${this.integrations.google_civic_api.parameters.api_key}`, {transformRequest: (data, headers) => {
                        delete headers.common['Authorization']
                        delete headers.common['X-CSRF-TOKEN']
                        delete headers['X-Socket-Id']
                    }}).then(this.googleCivicAPIUpdate).catch(error => {
                        this.google_civic_api_key_error = 'An error occurred saving the API key.  Please check the API key and try again.';
                    });
                } else if (this.integrations.google_civic_api.status === 'active') {
                    this.google_civic_api_key_error = 'Please provide a valid API key.';
                } else {
                    this.googleCivicAPIUpdate()
                }
            },
            googleCivicAPIUpdate() {
                let integration = this.integrations.google_civic_api;
                axios.put(`/api/v1/integrations/google_civic_api`, integration).then(this.postGoogleCivicAPIUpdate);
            },

            postGoogleCivicAPIUpdate() {
                this.$modal.hide('edit-google-civic-api');
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The integration has been updated',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch(1);
            },
            updateGoogleCivicStatus() {
                if (this.integrations.google_civic_api.status === 'inactive') {
                    this.disable_google_civic_api_key = true;
                    this.integrations.google_civic_api.parameters.api_key = null;
                } else {
                    this.disable_google_civic_api_key = false;
                }
            }
        }
    }
</script>
