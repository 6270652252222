<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">People</h1>
        </div>
        <div class="m-5 bg-white">
            <div class="bg-white shadow-md rounded grid grid-cols-4 gap-4 pt-4 px-4">
                <div>
                    <input class="form-text" type="text" id="search" name="search" v-model="search" placeholder="Search" @blur="updateSearch()" />
                </div>
                <div>
                    <multiselect v-model="search_segment_ids" :options="segments" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Filter by Segment" @input="updateSearch()"></multiselect>
                </div>
                <div>
                    <multiselect v-model="search_webform_ids" :options="webforms" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Filter by Webform" @input="updateSearch()"></multiselect>
                </div>
                <div>
                    <multiselect v-model="search_sources" :options="sources" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Filter by Source" @input="updateSearch()"></multiselect>
                </div>
            </div>
        </div>
        <div class="m-5 bg-white">
            <div class="">
                <div class="bg-white shadow-md rounded my-6">
                    <table class="listing">
                        <thead>
                            <tr>
                                <th><a href="javascript:void(0)" @click="updateSort('id')">ID</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('last_name')">Name</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('added_at')">Date Joined</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('source')">Source</a></th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="hover:bg-grey-lighter" v-for="person in people" v-bind:key="person.id">
                                <td class="py-4 px-6 border-b border-grey-light">{{ person.id }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">{{ person.first_name }} {{ person.last_name }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">{{ person.added_at | formatDate }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">{{ person.source | capitalize }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">
                                    <router-link :to="{ name: 'view_person', params: { id: person.id }}" class="mx-3"><i class="fas fa-id-card"></i></router-link>
                                    <a href="javascript:void(0);" v-on:click="confirmDelete(person.id)" class="mx-3"><i class="fas fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <paginate
            :page-count="page_count"
            :click-handler="fetch"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'pagination-page'"
            :prev-class="'pagination-prev'"
            :next-class="'pagination-next'"
            :disabled-class="'pagination-inactive'">
        </paginate>

        <modal name="delete-confirm" class="confirm-modal" :height="220">
            <div class="modal-container">
                <div class="modal-content">
                    <div class="modal-c-title">Delete Person</div>
                    <div class="modal-c-text">Are you sure you want to delete this person?  This action cannot be undone.</div>
                </div>
                <div class="modal-buttons">
                    <button type="button" class="modal-button" @click="$modal.hide('delete-confirm')">Cancel</button>
                    <button type="button" class="modal-button-primary" @click="deletePerson()">Delete</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'

    export default {
        name: 'ListPeople',
        components: {
            LayoutApp
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetch(1)
            this.fetchSegments()
            this.fetchWebforms()
        },
        data() {
            return {
                people: [],
                delete_id: null,
                page_count: 1,
                sort: 'desc',
                order: 'id',
                segments: [],
                webforms: [],
                sources: [
                    {id: 'keyword', name: 'Keyword'},
                    {id: 'webform', name: 'Webform'},
                    {id: 'engagement', name: 'Engagement'},
                    {id: 'api', name: 'API'},
                    {id: 'import', name: 'Import'},
                ],
                search: this.$route.query.search || '',
                search_segment_ids: [],
                search_webform_ids: [],
                search_sources: [],
            }
        },
        methods: {
            fetch(page) {
                this.current_page = page;

                let search_segment_ids = this.search_segment_ids.map(segment => segment.id)
                let search_webform_ids = this.search_webform_ids.map(webform => webform.id)
                let search_sources = this.search_sources.map(source => source.id)

                axios.get(`/api/v1/people?fb_bot_id=${this.$store.getters.current_bot_id}&page=${this.current_page}&sort=${this.sort}&order=${this.order}&search=${this.search}&search_segment_ids=${search_segment_ids}&search_webform_ids=${search_webform_ids}&search_sources=${search_sources}`).then(this.refresh);

                this.$router.push({
                    query: {
                        page: this.current_page,
                        sort: this.sort,
                        order: this.order,
                        search: this.search,
                    }
                }).catch(err => {})
            },
            refresh({data}) {
                this.people = data.data;
                this.page_count = data.meta.pages;
                window.scrollTo(0,0);
            },
            fetchSegments() {
                axios.get(`/api/v1/segments?fb_bot_id=${this.$store.getters.current_bot_id}&per_page=-1`).then(this.refreshSegments);
            },
            refreshSegments({data}) {
                this.segments = data.data;
            },
            fetchWebforms() {
                axios.get(`/api/v1/webforms?fb_bot_id=${this.$store.getters.current_bot_id}&per_page=-1`).then(this.refreshWebforms);
            },
            refreshWebforms({data}) {
                this.webforms = data.data;
            },
            confirmDelete(id) {
                this.delete_id = id;

                this.$modal.show('delete-confirm');
            },
            deleteBroadcast() {
                axios.delete(`/api/v1/people/${this.delete_id}`).then(this.postDelete);
            },
            postDelete() {
                this.$modal.hide('delete-confirm');
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The person has been deleted',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch(1);
            },
            updateSort(field) {
                if (field === this.order) {
                    this.sort = (this.sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.sort = 'asc';
                }

                this.order = field;

                this.fetch(1);
            },
            updateSearch() {
                this.fetch(1);
            },
        }
    }
</script>
