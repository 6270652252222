import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from './components/app/Home';

import ListBots from './components/app/bots/ListBots';
import CreateBot from './components/app/bots/CreateBot';
import EditBot from './components/app/bots/EditBot';

import ListSequences from './components/app/sequences/ListSequences';
import CreateSequence from './components/app/sequences/CreateSequence';
import EditSequence from './components/app/sequences/EditSequence';

import ListWebforms from './components/app/webforms/ListWebforms';
import CreateWebform from './components/app/webforms/CreateWebform';
import EditWebform from './components/app/webforms/EditWebform';

import ListAttributes from './components/app/attributes/ListAttributes';
import CreateAttribute from './components/app/attributes/CreateAttribute';
import EditAttribute from './components/app/attributes/EditAttribute';

import ListSegments from './components/app/segments/ListSegments';
import CreateSegment from './components/app/segments/CreateSegment';
import EditSegment from './components/app/segments/EditSegment';

import ListBroadcasts from './components/app/broadcasts/ListBroadcasts';
import CreateBroadcast from './components/app/broadcasts/CreateBroadcast';
import EditBroadcast from './components/app/broadcasts/EditBroadcast';

import ListReports from './components/app/reports/ListReports';
import CreateReport from './components/app/reports/CreateReport';
import EditReport from './components/app/reports/EditReport';

import ListInbox from './components/app/inbox/ListInbox';
import ListPeople from './components/app/people/ListPeople';
import ViewPerson from './components/app/people/ViewPerson';

import ListComments from './components/app/comments/ListComments';
import CreateComment from './components/app/comments/CreateComment';
import EditComment from './components/app/comments/EditComment';

import ListIntegrations from './components/app/integrations/ListIntegrations';

import ListEngagements from './components/app/engagements/ListEngagements';
import CreateEngagement from './components/app/engagements/CreateEngagement';
import EditEngagement from './components/app/engagements/EditEngagement';

import NotFound from './components/app/NotFound';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '*',
            component: NotFound
        },
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/bots',
            name: 'list_bots',
            component: ListBots
        },
        {
            path: '/bots/create',
            name: 'create_bot',
            component: CreateBot
        },
        {
            path: '/bots/edit/:id',
            name: 'edit_bot',
            component: EditBot,
            props: true
        },
        {
            path: '/sequences',
            name: 'list_sequences',
            component: ListSequences
        },
        {
            path: '/sequences/create',
            name: 'create_sequence',
            component: CreateSequence
        },
        {
            path: '/sequences/edit/:id',
            name: 'edit_sequence',
            component: EditSequence,
            props: true
        },
        {
            path: '/webforms',
            name: 'list_webforms',
            component: ListWebforms
        },
        {
            path: '/webforms/create',
            name: 'create_webform',
            component: CreateWebform
        },
        {
            path: '/webforms/edit/:id',
            name: 'edit_webform',
            component: EditWebform,
            props: true
        },
        {
            path: '/attributes',
            name: 'list_attributes',
            component: ListAttributes
        },
        {
            path: '/attributes/create',
            name: 'create_attribute',
            component: CreateAttribute
        },
        {
            path: '/attributes/edit/:id',
            name: 'edit_attribute',
            component: EditAttribute,
            props: true
        },
        {
            path: '/segments',
            name: 'list_segments',
            component: ListSegments
        },
        {
            path: '/segments/create',
            name: 'create_segment',
            component: CreateSegment
        },
        {
            path: '/segments/edit/:id',
            name: 'edit_segment',
            component: EditSegment,
            props: true
        },
        {
            path: '/broadcasts',
            name: 'list_broadcasts',
            component: ListBroadcasts
        },
        {
            path: '/broadcasts/create',
            name: 'create_broadcast',
            component: CreateBroadcast
        },
        {
            path: '/broadcasts/edit/:id',
            name: 'edit_broadcast',
            component: EditBroadcast,
            props: true
        },
        {
            path: '/reports',
            name: 'list_reports',
            component: ListReports
        },
        {
            path: '/reports/create',
            name: 'create_report',
            component: CreateReport
        },
        {
            path: '/reports/edit/:id',
            name: 'edit_report',
            component: EditReport,
            props: true
        },
        {
            path: '/comments',
            name: 'list_comments',
            component: ListComments
        },
        {
            path: '/comments/create',
            name: 'create_comment',
            component: CreateComment
        },
        {
            path: '/comments/edit/:id',
            name: 'edit_comment',
            component: EditComment,
            props: true
        },
        {
            path: '/inbox',
            name: 'list_inbox',
            component: ListInbox
        },
        {
            path: '/people',
            name: 'list_people',
            component: ListPeople
        },
        {
            path: '/people/view/:id',
            name: 'view_person',
            component: ViewPerson,
            props: true
        },
        {
            path: '/integrations',
            name: 'list_integrations',
            component: ListIntegrations
        },
        {
            path: '/engagements',
            name: 'list_engagements',
            component: ListEngagements
        },
        {
            path: '/engagements/create',
            name: 'create_engagement',
            component: CreateEngagement
        },
        {
            path: '/engagements/edit/:id',
            name: 'edit_engagement',
            component: EditEngagement,
            props: true
        },
    ]
});

export default router
