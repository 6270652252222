<template>
    <div class="inbox-message p-3 rounded-lg" :class="{'col-start-1 col-end-8': message.type === 'inbound', 'col-start-6 col-end-13 ': message.type === 'outbound'}">
        <div v-if="message.type === 'inbound'">
            <div class="flex flex-row items-center">
                <div class="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0"  v-tooltip.bottom="{content: message.updated_at, offset: 10}">
                    User
                </div>
                <div class="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl">
                    <div v-if="message.attachments === null">{{ message.text }}</div>
                    <div v-if="message.attachments !== null">
                        <div v-for="(attachment, attachment_index) in message.attachments" v-bind:key="attachment_index" >
                            <img :src="attachment.payload.url" width="60" height="60" class="pr-2" v-if="attachment.type === 'image'" />
                            <audio controls :src="attachment.payload.url" class="pr-2" v-if="attachment.type === 'audio'"></audio>
                            <video controls width="70%" :src="attachment.payload.url" class="pr-2" v-if="attachment.type === 'video'"></video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="message.type === 'outbound'">
            <div class="flex items-center justify-start flex-row-reverse">
                <div class="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0"  v-tooltip.bottom="{content: message.updated_at, offset: 10}">
                    Bot
                </div>
                <div class="relative mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl">
                    <div v-if="message.attachments === null">{{ message.text }}</div>
                    <div v-if="message.attachments !== null">
                        <div v-for="(attachment, attachment_index) in message.attachments" v-bind:key="attachment_index" >
                            <img :src="attachment.payload.url" width="60" height="60" class="pr-2" v-if="attachment.type === 'image'" />
                            <audio controls :src="attachment.payload.url" class="pr-2" v-if="attachment.type === 'audio'"></audio>
                            <video controls width="70%" :src="attachment.payload.url" class="pr-2" v-if="attachment.type === 'video'"></video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'list-message',
        props: ['message'],
        created() {},
        data() {
            return {}
        },
        methods: {}
    }

</script>
