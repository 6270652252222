<template>
    <div>
        <div class="mb-2">
            <label class="form-label" :for="id">{{ label }}</label>
            <div class="float-right">
                <a class="btn-small" @click="showModal()">{Attribute}</a>
                <a class="btn-small" @click="showPollingLocatorModal()" v-if="showPollingLocatorOptions">{Poll Result}</a>
            </div>
        </div>
        <textarea class="form-textarea-dynamic" :id="id" :name="name" :ref="refName" v-model="text" @click="checkSelection($event)" @keyup="checkSelection($event);"></textarea>
        <p class="text-xs italic mb-2" :class="{'text-red-600': remainingWarn}" v-if="max">{{ remaining }}</p>

        <modal :name="'dynamic-tag-selector-'+id" class="confirm-modal" :height="400">
            <div class="modal-container">
                <div class="modal-content">
                    <div class="modal-c-title">Insert Token</div>
                    <div class="modal-c-text">Select an attribute to insert dynamic when the sequence block is sent to a person.<br />&nbsp;</div>

                    <div class="modal-c-title">Field</div>
                    <div class="modal-c-text">
                        <div class="relative">
                            <select class="form-select" id="dt_field" name="dt_field" v-model="tag.attribute_id">
                                <option :value="null">- Select Attribute -</option>
                                <option v-for="attribute in this.$parent.all_attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                        </div>
                    </div>
                    <div class="modal-c-title">Default Value</div>
                    <div class="modal-c-text mt-2">
                        <input type="text" class="form-text" v-model="tag.default_value" />
                    </div>

                </div>
                <div class="modal-buttons">
                    <button type="button" class="modal-button" @click="$modal.hide('dynamic-tag-selector-'+id)">Cancel</button>
                    <button type="button" class="modal-button-primary" @click="insertTag()">Insert</button>
                </div>
            </div>
        </modal>

        <modal :name="'polling-locator-selector-'+id" class="confirm-modal" :height="400">
            <div class="modal-container">
                <div class="modal-content">
                    <div class="modal-c-title">Insert Token</div>
                    <div class="modal-c-text">Select a field to insert dynamic when the sequence block is sent to a person.<br />&nbsp;</div>

                    <div class="modal-c-title">Field</div>
                    <div class="modal-c-text">
                        <div class="relative">
                            <select class="form-select" id="pl_field" name="pl_field" v-model="pl_tag.name">
                                <option :value="null">- Select Field -</option>
                                <option value="location">Location</option>
                                <option value="address1">Address (line 1)</option>
                                <option value="address2">Address (line 2)</option>
                                <option value="address3">Address (line 3)</option>
                                <option value="city">City</option>
                                <option value="state">State</option>
                                <option value="zip">ZIP</option>
                                <option value="hours">Hours</option>
                                <option value="notes">Notes</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                        </div>
                    </div>
                    <div class="modal-c-title">Default Value</div>
                    <div class="modal-c-text mt-2">
                        <input type="text" class="form-text" v-model="pl_tag.default_value" />
                    </div>

                </div>
                <div class="modal-buttons">
                    <button type="button" class="modal-button" @click="$modal.hide('polling-locator-selector-'+id)">Cancel</button>
                    <button type="button" class="modal-button-primary" @click="insertPollingLocatorTag()">Insert</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>

    export default {
        name: 'DynamicTextarea',
        data() {
            return {
                tag: {
                    attribute_id: null,
                    default_value: '',
                },
                pl_tag: {
                    name: '',
                    default_value: ''
                },
                remainingWarn: false,
            }
        },
        methods: {
            showModal() {
                this.$modal.show('dynamic-tag-selector-'+this.id);
            },
            showPollingLocatorModal() {
                this.$modal.show('polling-locator-selector-'+this.id);
            },
            insertTag() {
                let start = this.$refs[this.refName].selectionStart;
                let end = this.$refs[this.refName].selectionEnd;
                let value = this.text;
                let prefix = value.substring(0, start);
                let postfix = value.substring(end, value.length);

                let result = prefix + '{{attribute_id='+this.tag.attribute_id;

                if (this.tag.default_value !== '') {
                    result += ';default='+this.tag.default_value;
                }

                result += '}}' + postfix;

                this.text = result;
                this.$modal.hide('dynamic-tag-selector-'+this.id);
            },
            insertPollingLocatorTag() {
                let start = this.$refs[this.refName].selectionStart;
                let end = this.$refs[this.refName].selectionEnd;
                let value = this.text;
                let prefix = value.substring(0, start);
                let postfix = value.substring(end, value.length);

                let result = prefix + '{{pl='+this.pl_tag.name;

                if (this.pl_tag.default_value !== '') {
                    result += ';default='+this.pl_tag.default_value;
                }

                result += '}}' + postfix;

                this.text = result;
                this.$modal.hide('polling-locator-selector-'+this.id);
            },
            checkSelection(event) {
                if (event.type === 'key' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight' && event.key !== 'ArrowUp' && event.key !== 'ArrowDown') {
                    return;
                }

                let cursor = this.$refs[this.refName].selectionStart;
                let search = RegExp('{{.*?}}','g');
                let match = null;

                while ((match = search.exec(this.text)) !== null) {
                    let start = match.index;
                    let end = start + match[0].length;

                    if (cursor > start && cursor < end) {
                        this.$refs[this.refName].focus();
                        this.$refs[this.refName].setSelectionRange(start, end, 'forward');

                        if (event.type === 'click') {
                            let parts = match[0].replace(/^{{|}}$/g, '').split(';');

                                let attribute = parts[0].split('=');
                                let default_value = (parts.length === 2 ? parts[1].split('=') : ['default', '']);

                                if (attribute[0] === 'attribute_id') {
                                    this.tag.attribute_id = attribute[1];
                                    this.tag.default_value = default_value[1];

                                    this.showModal();
                                } else if(attribute[0] === 'pl') {
                                    this.pl_tag.name = attribute[1];
                                    this.pl_tag.default_value = default_value[1];

                                    this.showPollingLocatorModal();
                                }


                        }

                        break;
                    }
                }
            }
        },
        computed: {
            text: {
                get() {
                    return this.value;
                },
                set(v) {
                    this.$emit('input', v)
                }
            },
            remaining: {
                get() {
                    let search = RegExp('{{.*?}}','g');

                    if (this.text !== null && this.text.match(search)) {
                        this.remainingWarn = false;

                        return 'Character counting not available with dynamic tokens'
                    } else if(this.text !== null) {
                        let characters = this.max - this.text.length;
                        this.remainingWarn = (characters < 0 ? true : false);

                        return ((characters < 0) ? Math.abs(characters) + ' characters over' : characters + ' characters remaining');
                    }
                }
            }
        },
        props: {
            refName: {
                required: true
            },
            id: {
                required: true
            },
            name: {
                required: true
            },
            value: {
                required: true
            },
            label: {
                required: true
            },
            max: {

            },
            showPollingLocatorOptions: Boolean
        }
    };
</script>
