<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Create New Bot</h1>
        </div>
        <div class="flex flex-row">
            <div class="flex-grow">
                <div class="m-5 bg-white shadow-md rounded ">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Bot Details</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('details')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="bg-white my-6 p-4">
                        <div class="w-full">

                            <!-- Row One -->
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="form-label" for="name">Name</label>
                                <input class="form-text" id="name" type="text" v-model="bot.name">
                                <p class="text-red-500 text-xs italic" v-if="errors && errors.name">{{ errors.name[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="form-label" for="status">Status</label>
                                <div class="relative">
                                    <select class="form-select" id="status" name="status" v-model="bot.status">
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                    </select>
                                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                    </div>
                                </div>
                                <p class="text-red-500 text-xs italic" v-if="errors && errors.status">{{ errors.status[0] }}</p>
                                </div>
                            </div>

                            <!-- Row Two -->
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="fb_page_id">Facebook Page</label>
                                    <div class="relative">
                                        <select class="form-select" id="fb_page_id" name="fb_page_id" v-model="bot.fb_page_id">
                                        <option v-for="page in pages" v-bind:key="page.id" :value="page.id">{{ page.name }}</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.fb_page_id">{{ errors.fb_page_id[0] }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-wrap m-5 px-3 pt-3">
                    <button class="btn btn-primary" v-on:click="save">
                        Create Bot
                    </button>

                    <button class="btn btn-secondary" v-on:click="cancel">
                        Cancel
                    </button>
                </div>
            </div>

            <div class="w-64" v-if="show_help_section">
                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'details'">
                    <h2 class="p-4">Bot Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Bots are used to organize interactions and person data, relating to a specific Facebook page.</p>
                            <p class="text-xs italic pb-2"><strong>Name</strong> - Provide a descriptive, unique name for the bot to help identify it for future edits and use.  Example: Campaign Bot.</p>
                            <p class="text-xs italic pb-2"><strong>Facebook Page</strong> - Select the Facebook Page this bot will be associated with for handling messages.  Note: if you don't see a Page you manage, please visit the Home page and Re-Authorize
                             your account, providing permissions on the missing page.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'
    import Vue from 'vue'

    export default {
        name: 'CreateBot',
        components: {
            LayoutApp
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetchPages();
        },
        data() {
            return {
                pages: [],
                bot: {
                    name: '',
                    fb_page_id: '',
                    status: 'active',
                    messenger_profile_details: {
                        enable_greeting: false,
                        greeting: [{
                            'locale': 'default',
                            'text': '',
                        }],
                        enable_persistent_menu: false,
                        persistent_menu: [{
                            'locale': 'default',
                            'composer_input_disabled': false,
                            'call_to_actions': []
                        }],
                        whitelisted_domains: [],
                    },
                },
                errors: [],
                show_help_section: false,
            }
        },
        methods: {
            fetchPages() {
                axios.get(this.pagesUrl()).then(this.refreshPages);
            },
            pagesUrl() {
                return `/api/v1/me/pages`;
            },
            refreshPages({data}) {
                this.pages = data.pages;

                if (this.pages.length > 0) {
                    this.bot.fb_page_id = this.pages[0].id;
                }
            },
            fetchSequences() {
                axios.get(this.sequencesUrl()).then(this.refreshSequences);
            },
            sequencesUrl() {
                return `/api/v1/sequences?fb_bot_id=${this.sequence.fb_bot_id}`;
            },
            refreshSequences({
                data
            }) {
                this.sequences = data.data;
            },
            save() {
                axios.post('/api/v1/bots', this.bot).then(response => {
                    this.$notify({
                        group: 'alerts',
                        type: 'success',
                        title: 'The bot has been created',
                        duration: 5000,
                        speed: 1000
                    });
                    this.$root.$emit('bots_updated');

                    this.$router.push({name: 'list_bots'});
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            },
            cancel() {
                this.$router.back();
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }

</script>
