<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Broadcasts</h1>
            <router-link class="mr-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" to="/broadcasts/create">
                Create New Broadcast
            </router-link>
        </div>
        <div class="m-5 bg-white">
            <div class="">
                <div class="bg-white shadow-md rounded my-6">
                    <table class="listing">
                        <thead>
                            <tr>
                                <th><a @click="updateSort('id')">ID</a></th>
                                <th>Segment(s)</th>
                                <th>Est. Recipients</th>
                                <th><a @click="updateSort('send_at')">Scheduled/Sent</a></th>
                                <th><a @click="updateSort('status')">Status</a></th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="hover:bg-grey-lighter" v-for="broadcast in broadcasts" v-bind:key="broadcast.id">
                                <td class="py-4 px-6 border-b border-grey-light">{{ broadcast.id }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">
                                    <span class="bg-blue-500 text-white font-bold py-1 px-2 rounded-full text-sm mx-1" v-for="segment in broadcast.segments" v-bind:key="segment.id">{{ segment.name }}</span>
                                </td>
                                <td class="py-4 px-6 border-b border-grey-light">{{ broadcast.estimated }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">{{ broadcast.send_at | formatDate }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">{{ broadcast.status | capitalize }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">
                                    <!--<router-link v-if="broadcast.status === 'scheduled'" :to="{ name: 'edit_broadcast', params: { id: broadcast.id }}" class="mx-3"><i class="fas fa-pencil-alt"></i></router-link>-->
                                    <a href="javascript:void(0);" v-if="broadcast.status === 'scheduled'" v-on:click="confirmDelete(broadcast.id)" class="mx-3"><i class="fas fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <paginate
            :page-count="page_count"
            :click-handler="fetch"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'pagination-page'"
            :prev-class="'pagination-prev'"
            :next-class="'pagination-next'"
            :disabled-class="'pagination-inactive'">
        </paginate>

        <modal name="delete-confirm" class="confirm-modal" :height="220">
            <div class="modal-container">
                <div class="modal-content">
                    <div class="modal-c-title">Delete Broadcast</div>
                    <div class="modal-c-text">Are you sure you want to delete this broadcast?  This action cannot be undone.</div>
                </div>
                <div class="modal-buttons">
                    <button type="button" class="modal-button" @click="$modal.hide('delete-confirm')">Cancel</button>
                    <button type="button" class="modal-button-primary" @click="deleteBroadcast()">Delete</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'

    export default {
        name: 'ListBroadcasts',
        components: {
            LayoutApp
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetch(1);
        },
        data() {
            return {
                broadcasts: [],
                delete_id: null,
                page_count: 1,
                sort: 'desc',
                order: 'id'
            }
        },
        methods: {
            fetch(page) {
                axios.get(this.url(page)).then(this.refresh);
            },
            url(page) {
                if (!page) {
                    page = 1;
                }
                return `/api/v1/broadcasts?fb_bot_id=${this.$store.getters.current_bot_id}&page=${page}&sort=${this.sort}&order=${this.order}`;
            },
            refresh({data}) {
                this.broadcasts = data.data;
                this.page_count = data.meta.pages;
                window.scrollTo(0,0);
            },
            confirmDelete(id) {
                this.delete_id = id;

                this.$modal.show('delete-confirm');
            },
            deleteBroadcast() {
                axios.delete(`/api/v1/broadcasts/${this.delete_id}`).then(this.postDelete);
            },
            postDelete() {
                this.$modal.hide('delete-confirm');
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The broadcast has been deleted',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch(1);
            },
            updateSort(field) {
                if (field === this.order) {
                    this.sort = (this.sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.sort = 'asc';
                }

                this.order = field;

                this.fetch(1);
            }
        }
    }
</script>
