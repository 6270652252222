<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Webforms</h1>
            <router-link class="mr-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" to="/webforms/create">
                Create New Webform
            </router-link>
        </div>
        <div class="m-5 bg-white">
            <div class="bg-white shadow-md rounded my-6 flex flex-row">
                <div class="w-1/3 flex-initial text-center px-4 py-2 m-2">
                    <input class="form-text" type="text" id="search" name="search" v-model="search" placeholder="Search" @blur="updateSearch()" />
                </div>
                <div class="w-1/3 flex-initial text-center px-4 py-2 m-2">
                    <multiselect v-model="search_fb_sequence_ids" :options="sequences" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Filter by Sequence" @input="updateSearch()"></multiselect>
                </div>
                <div class="w-1/3 flex-initial text-center px-4 py-2 m-2">
                    <multiselect v-model="search_statuses" :options="statuses" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Filter by Status" @input="updateSearch()"></multiselect>
                </div>
            </div>
        </div>
        <div class="m-5 bg-white">
            <div class="">
                <div class="bg-white shadow-md rounded my-6">
                    <table class="listing">
                        <thead>
                            <tr>
                                <th><a href="javascript:void(0)" @click="updateSort('id')">ID</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('name')">Name</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('sequence.name')">Sequence</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('submissions_count')">Submissions</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('status')">Status</a></th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="hover:bg-grey-lighter" v-for="webform in webforms" v-bind:key="webform.id">
                                <td class="py-4 px-6 border-b border-grey-light">{{ webform.id }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">{{ webform.name }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">{{ webform.sequence.name }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">{{ webform.submissions_count }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">
                                    <span v-bind:class="{'bg-green-500': (webform.status === 'active'), 'bg-gray-600': (webform.status === 'inactive')}" class="text-white font-bold py-1 px-2 rounded-full text-sm">{{ webform.status | capitalize }}</span>
                                </td>
                                <td class="py-4 px-6 border-b border-grey-light">
                                    <a href="javascript:void(0);" v-on:click="renderFormCode(webform.id)" class="mx-3"><i class="fab fa-wpforms"></i></a>
                                    <router-link :to="{ name: 'edit_webform', params: { id: webform.id }}" class="mx-3"><i class="fas fa-pencil-alt"></i></router-link>
                                    <a href="javascript:void(0);" v-on:click="confirmDelete(webform.id)" class="mx-3"><i class="fas fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <paginate
            :page-count="page_count"
            :click-handler="fetch"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'pagination-page'"
            :prev-class="'pagination-prev'"
            :next-class="'pagination-next'"
            :disabled-class="'pagination-inactive'">
        </paginate>

        <modal name="delete-confirm" class="confirm-modal" :height="220">
            <div class="modal-container">
                <div class="modal-content">
                    <div class="modal-c-title">Delete Webform</div>
                    <div class="modal-c-text">Are you sure you want to delete this webform?  This action cannot be undone.</div>
                </div>
                <div class="modal-buttons">
                    <button type="button" class="modal-button" @click="$modal.hide('delete-confirm')">Cancel</button>
                    <button type="button" class="modal-button-primary" @click="deleteWebform()">Delete</button>
                </div>
            </div>
        </modal>

        <modal name="form-modal" class="confirm-modal" :height="720" :width="1000">
            <div class="modal-container">
                <div class="modal-content">
                    <div class="modal-c-title">SBR Code</div>
                    <div class="modal-c-text">Place the following code inside the &lt;head&gt; tag of every page the widget will be used on.</div>
                    <textarea class="overflow-auto bg-gray-300 m-2 min-w-full" style="height: 60px;" v-model="form_head_code"></textarea>
                </div>
                <div class="modal-content">
                    <div class="modal-c-title">Embed Code</div>
                    <div class="modal-c-text">Use the following code to place the engagement on your page. Look for the text ##REPLACE ME## and replace it with the domain the form will be hosted on.</div>
                    <textarea class="overflow-auto bg-gray-300 m-2 min-w-full" style="height: 290px;" v-model="form_embed_code"></textarea>
                </div>
                <div class="modal-buttons">
                    <button type="button" class="modal-button-primary" @click="$modal.hide('form-modal')">Close</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'

    export default {
        name: 'ListWebforms',
        components: {
            LayoutApp
        },
        created() {
            this.$emit('update:layout', LayoutApp)
            this.fetch(1)
            this.fetchSequences()
        },
        data() {
            return {
                webforms: [],
                show_form_id: null,
                form_head_code: '<script src="'+process.env.MIX_APP_URL+'/js/public.js?app_id='+process.env.MIX_FB_APP_ID+'" async><\/script>',
                form_embed_code: null,
                delete_id: null,
                page_count: 1,
                sort: 'desc',
                order: 'id',
                sequences: [],
                statuses: [
                    {id: 'active', name: 'Active'},
                    {id: 'inactive', name: 'Inactive'},
                ],
                search: this.$route.query.search || '',
                search_statuses: [],
                search_fb_sequence_ids: [],
            }
        },
        methods: {
            fetch(page) {
                this.current_page = page;

                let search_fb_sequence_ids = this.search_fb_sequence_ids.map(sequence => sequence.id)
                let search_statuses = this.search_statuses.map(status => status.id)

                axios.get(`/api/v1/webforms?page=${this.current_page}&sort=${this.sort}&order=${this.order}&search=${this.search}&search_fb_sequence_ids=${search_fb_sequence_ids}&search_statuses=${search_statuses}&`).then(this.refresh);

                this.$router.push({
                    query: {
                        page: this.current_page,
                        sort: this.sort,
                        order: this.order,
                        search: this.search,
                    }
                }).catch(err => {})
            },
            refresh({data}) {
                this.webforms = data.data;
                this.page_count = data.meta.pages;
                window.scrollTo(0,0);
            },
            fetchSequences() {
                axios.get(`/api/v1/sequences?per_page=-1`).then(this.refreshSequences);
            },
            refreshSequences({data}) {
                this.sequences = data.data;
            },
            confirmDelete(id) {
                this.delete_id = id;

                this.$modal.show('delete-confirm');
            },
            deleteWebform() {
                axios.delete(`/api/v1/webforms/${this.delete_id}`).then(this.postDelete);
            },
            postDelete() {
                this.$modal.hide('delete-confirm');
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The webform has been deleted',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch(1);
            },
            updateSort(field) {
                if (field === this.order) {
                    this.sort = (this.sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.sort = 'asc';
                }

                this.order = field;

                this.fetch(1);
            },
            updateSearch() {
                this.fetch(1);
            },
            renderFormCode(id) {
                axios.get(`/api/v1/webforms/${id}/render`).then(this.postRender)
            },
            postRender({data}) {
                this.form_embed_code = data
                this.$modal.show('form-modal')
            }
        }
    }
</script>
