<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Edit Sequence</h1>
        </div>
        <div class="flex flex-row">
            <div class="flex-grow">
                <div class="m-5 bg-white shadow-md rounded">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Sequence Details</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('details')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <p class="text-red-500 text-xs italic mx-6" v-if="errors && errors.sequence">{{ errors.sequence[0] }}</p>
                    <div class="bg-white shadow-md rounded my-6 p-4">
                        <div class="w-full">

                        <!-- Row One -->
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="form-label" for="name">Name</label>
                            <input class="form-text" id="name" type="text" v-model="sequence.name">
                            <p class="text-red-500 text-xs italic" v-if="errors && errors.name">{{ errors.name[0] }}</p>
                            </div>
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="form-label" for="status">Status</label>
                            <div class="relative">
                                <select class="form-select" id="status" name="status" v-model="sequence.status">
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                                </select>
                                <div class="form-select-arrow">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                </div>
                            </div>
                            <p class="text-red-500 text-xs italic" v-if="errors && errors.status">{{ errors.status[0] }}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div class="m-5 bg-white shadow-md rounded">
                        <div class="flex">
                            <h2 class="flex-1 p-4">Keyword Details</h2>
                            <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('keywords')"><i class="far fa-question-circle"></i></a></h2>
                        </div>
                        <div class="bg-white my-6 p-4">
                            <div class="w-full">
                                <!-- Row 1 -->
                                <div class="flex flex-wrap -mx-3 mb-6" v-for="(keyword, index) in sequence.keywords" v-bind:key="keyword.id">
                                    <div class="w-full md:w-2/5 px-3 mb-6 md:mb-0">
                                        <label class="form-label" :for="'keyword_keyword_'+index">Keyword/Phrase</label>
                                        <input class="form-text" :id="'keyword_keyword_'+index" :name="'keyword_keyword_'+index" type="text" v-model="sequence.keywords[index].keyword">
                                        <validation-notice :param="'keywords.'+index+'.keyword'" :errors="errors"></validation-notice>
                                    </div>
                                    <div class="w-full md:w-2/5 px-3 mb-6 md:mb-0">
                                        <label class="form-label" :for="'keyword_rule_'+index">Rule</label>
                                        <div class="relative">
                                            <select class="form-select" :id="'keyword_rule_'+index" :name="'keyword_rule_'+index" v-model="sequence.keywords[index].rule">
                                                <option value="exact">Exact Match</option>
                                                <option value="start">Starts With</option>
                                                <option value="contain">Contains</option>
                                            </select>
                                            <div class="form-select-arrow">
                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            </div>
                                        </div>
                                        <validation-notice :param="'keywords.'+index+'.rule'" :errors="errors"></validation-notice>
                                    </div>
                                    <div class="w-full md:w-1/5 cursor-pointer h-full pt-6 pl-6 text-right">
                                        <button class="btn btn-alert" @click="removeKeyword(index)"><i class="fas fa-minus-circle"></i> REMOVE</button>
                                    </div>
                                </div>

                                <div class="flex flex-wrap mx-3 mb-6">
                                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-3" @click="addKeyword()"><i class="fas fa-plus-circle"></i> ADD</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="m-5 bg-white">
                        <div class="flex">
                            <h2 class="flex-1 p-4">Block Details</h2>
                            <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('blocks')"><i class="far fa-question-circle"></i></a></h2>
                        </div>
                        <div class="bg-white shadow-md rounded">
                            <div class="w-full flex m-6">
                                <!-- Pallette -->
                                <div class="w-1/6 flex flex-col pr-6">
                                    <label v-on:click="addBlock('message')" class="pallette-label">
                                        <i class="far fa-comment pr-3"></i> Send Message
                                    </label>

                                    <label v-on:click="addBlock('attachment')" class="pallette-label">
                                        <i class="far fa-comment pr-3"></i> Send Image
                                    </label>

                                    <label v-on:click="addBlock('typing')" class="pallette-label">
                                        <i class="far fa-comment-dots pr-3"></i> Typing
                                    </label>

                                    <label v-on:click="addBlock('quick_reply')" class="pallette-label">
                                        <i class="fas fa-reply pr-3"></i> Quick Reply
                                    </label>

                                    <label v-on:click="addBlock('quick_reply_email_address')" class="pallette-label">
                                        <i class="fas fa-reply pr-3"></i> Quick Reply (Email)
                                    </label>

                                    <label v-on:click="addBlock('quick_reply_phone_number')" class="pallette-label">
                                        <i class="fas fa-reply pr-3"></i> Quick Reply (Phone)
                                    </label>

                                    <label v-on:click="addBlock('random')" class="pallette-label">
                                        <i class="fas fa-random pr-3"></i> Random
                                    </label>

                                    <label v-on:click="addBlock('template_generic')" class="pallette-label">
                                        <i class="fas fa-list-alt pr-3"></i> Webview Template
                                    </label>

                                    <label v-on:click="addBlock('template_button')" class="pallette-label">
                                        <i class="fas fa-stream pr-3"></i> Button Template
                                    </label>

                                    <label v-on:click="addBlock('conditional')" class="pallette-label">
                                        <i class="fas fa-bezier-curve pr-3"></i> Conditional
                                    </label>

                                    <label v-on:click="addBlock('collect_attribute')" class="pallette-label">
                                        <i class="fas fa-user-tag pr-3"></i> Collect Attr
                                    </label>

                                    <label v-on:click="addBlock('tag_attribute')" class="pallette-label">
                                        <i class="fas fa-user-tag pr-3"></i> Tag Attr
                                    </label>

                                    <label v-on:click="addBlock('add_to_segment')" class="pallette-label">
                                        <i class="fas fa-user-plus pr-3"></i> Add to Segment
                                    </label>

                                    <label v-on:click="addBlock('forward_to_sequence')" class="pallette-label">
                                        <i class="fas fa-share pr-3"></i> Forward to Sequence
                                    </label>

                                    <label v-on:click="addBlock('forward_to_inbox')" class="pallette-label">
                                        <i class="fas fa-envelope-open-text pr-3"></i> Forward to Inbox
                                    </label>

                                    <label v-on:click="addBlock('drip_message')" class="pallette-label">
                                        <i class="fas fa-tint pr-3"></i> Drip Message
                                    </label>

                                    <label v-on:click="addBlock('dynamic_content')" class="pallette-label">
                                        <i class="fas fa-code pr-3"></i> Dynamic Content
                                    </label>

                                    <label v-if="integrations.google_civic_api.status === 'active'" v-on:click="addBlock('google_civic_polling_locator')" class="pallette-label">
                                        <i class="fab fa-google pr-3"></i> Polling Locator
                                    </label>

                                    <label v-on:click="addBlock('notification')" class="pallette-label">
                                        <i class="far fa-paper-plane pr-3"></i> Notification
                                    </label>
                                </div>

                                <!-- Builder -->
                                <div class="w-1/2 pb-4 overflow-hidden">
                                    <Builder :sequence="sequence" :current_block="current_block" :set-active-block="setActiveBlock" :remove-block="removeBlock"></Builder>
                                </div>

                                <!-- Properties -->
                                <div class="w-1/3">
                                    <div class="mb-6">
                                        <div v-if="current_block !== null" class="flex flex-wrap bg-gray-100 mx-6 p-4 rounded border-gray-700 border">
                                            <div class="w-full px-3 mb-6 md:mb-0">
                                                <!-- Send Message Block -->
                                                <div v-if="current_block.messaging_type === 'message'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Send Message Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Send a message to the person.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="Text"
                                                            id="block_send_message_message"
                                                            name="block_send_message_message"
                                                            refName="block_send_message_message"
                                                            max="2000"
                                                            v-model="current_block.params.message"
                                                            @input="current_block.params.message = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.message" :errors="current_block.errors"></validation-notice>
                                                    </div>
                                                </div>

                                                <!-- Send Image Block -->
                                                <div v-if="current_block.messaging_type === 'attachment'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Send Image Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Send an image to the user.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <label class="form-label" for="block_attachment_type">Type</label>
                                                        <div class="relative pb-3">
                                                            <select class="form-select" id="block_attachment_type" name="block_attachment_type" v-model="current_block.params.file_type">
                                                                <option value="url">URL</option>
                                                                <option value="attachment">Attachment</option>
                                                            </select>
                                                            <div class="form-select-arrow">
                                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                        <validation-notice param="params.file_type" :errors="current_block.errors"></validation-notice>

                                                        <div v-if="current_block.params.file_type === 'attachment'">
                                                            <div v-if="current_block.params.attachment_id === null">
                                                                <label class="form-label" for="block_attachment_image">Image File</label>
                                                                <input class="form-text" id="block_attachment_image" name="block_attachment_image" ref="file" @change="handleFileUpload('file')" type="file" />
                                                                <validation-notice param="params.attachment_id" :errors="current_block.errors"></validation-notice>

                                                                <div class="flex flex-wrap mx-3 mb-6">
                                                                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-3" @click="submitFile()"><i class="fas fa-plus-circle"></i> UPLOAD</button>
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                <img v-if="current_block.params.attachment_id !== null" :src="current_block.params.url" height="200" width="200" class="pb-2" />

                                                                <div class="flex flex-wrap mx-3 mb-6 text-right">
                                                                    <button class="btn btn-alert" @click="removeFile()"><i class="fas fa-minus-circle"></i> REMOVE</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <label class="form-label" for="block_attachment_url">Image URL</label>
                                                            <input class="form-text" id="block_attachment_url" name="block_attachment_url" type="url" v-model="current_block.params.url" />
                                                            <validation-notice param="params.url" :errors="current_block.errors"></validation-notice>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Typing Block -->
                                                <div v-if="current_block.messaging_type === 'typing'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Send Typing Notice</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Display the typing bubble to the user for the set duration (in seconds).</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <label class="form-label" for="block_typing_duration">Duration</label>
                                                        <input class="form-text" id="block_typing_duration" name="block_typing_duration" type="number" min="1" max="5" v-model.number="current_block.params.duration">
                                                        <validation-notice param="params.duration" :errors="current_block.errors"></validation-notice>
                                                    </div>
                                                </div>

                                                <!-- Call Button Block -->
                                                <div v-if="current_block.messaging_type === 'template_call_button'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Call Button Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Prompt the user to call the specified number.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="Text"
                                                            id="block_template_call_button_message"
                                                            name="block_template_call_button_message"
                                                            refName="block_template_call_button_message"
                                                            max="2000"
                                                            v-model="current_block.params.message"
                                                            @input="current_block.params.message = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.message" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <label class="form-label" for="block_template_call_button_title">Button Text</label>
                                                        <input type="text" class="form-text" id="block_template_call_button_title" name="block_template_call_button_title" v-model="current_block.params.title" maxlength="20" />
                                                        <validation-notice param="params.title" :errors="current_block.errors"></validation-notice>

                                                        <label class="form-label" for="block_template_call_button_number">Phone Number</label>
                                                        <input type="text" class="form-text" id="block_template_call_button_number" name="block_template_call_button_number" placeholder="+15555555555" v-model="current_block.params.number" />
                                                        <validation-notice param="params.number" :errors="current_block.errors"></validation-notice>
                                                    </div>
                                                </div>

                                                <!-- Generic Template -->
                                                <div v-if="current_block.messaging_type === 'template_generic'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Send Webview Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Send a message asking for the user's quick reply by selecting an option.</div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <label class="form-label" for="block_menu_elements">Elements</label>
                                                        <input class="form-text" name="block_menu_elements" id="block_menu_elements" type="number" :value="getBlocksLength()" @change="updateGenericTemplateElements($event)" />

                                                        <label class="form-label" for="block_template_generic_type">Type</label>
                                                        <div class="relative pb-3">
                                                            <select class="form-select" id="block_template_generic_type" name="block_template_generic_type" v-model="current_block.params.type">
                                                                <option :value="null">- Select Type -</option>
                                                                <option value="blocking">Blocking</option>
                                                                <option value="non_blocking">Non-Blocking</option>
                                                            </select>
                                                            <div class="form-select-arrow">
                                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                        <validation-notice param="params.type" :errors="current_block.errors"></validation-notice>
                                                    </div>
                                                </div>

                                                <!-- Generic Template Element -->
                                                <div v-if="current_block.messaging_type === 'template_generic_element'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Send Webview Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Send a message asking for the user's quick reply by selecting an option.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <label class="form-label" for="block_menu_buttons">Buttons</label>
                                                        <input class="form-text" name="block_menu_buttons" id="block_menu_buttons" type="number" :value="getBlocksLength()" @change="updateElementButtons($event)" />

                                                        <label class="form-label" for="'block_template_generic_element_fb_attribute_id'">Attribute</label>
                                                        <div class="relative pb-3">
                                                            <select class="form-select" id="block_template_generic_element_fb_attribute_id" name="block_template_generic_element_fb_attribute_id" v-model="current_block.params.fb_attribute_id">
                                                                <option :value="null">- Select Attribute -</option>
                                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                                            </select>
                                                            <div class="form-select-arrow">
                                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                        <validation-notice param="params.fb_attribute_id" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <label class="form-label" for="'block_template_generic_element_title'">Title</label>
                                                        <input type="text" class="form-text" id="block_template_generic_element_title" name="block_template_generic_element_title" v-model="current_block.params.title" maxlength="20" />
                                                        <validation-notice param="params.title" :errors="current_block.errors"></validation-notice>

                                                        <label class="form-label" for="block_template_generic_element_subtitle">Subtitle</label>
                                                        <input type="text" class="form-text" id="block_template_generic_element_subtitle" name="block_template_generic_element_subtitle" v-model="current_block.params.subtitle" />
                                                        <validation-notice param="params.subtitle" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <div class="pl-4">
                                                            <h3>Default Action</h3>

                                                            <label class="form-label" for="block_template_generic_element_default_action_url">URL</label>
                                                            <input type="text" class="form-text" id="block_template_generic_element_default_action_url" name="block_template_generic_element_default_action_url" v-model="current_block.params.default_action.url" />
                                                            <validation-notice param="params.default_action.url" :errors="current_block.errors"></validation-notice>

                                                            <label class="form-label" for="block_template_generic_element_default_action_webheigh_ratio">Webview Height Ratio</label>
                                                            <div class="relative pb-3">
                                                                <select class="form-select" id="block_template_generic_element_default_action_webheigh_ratio" name="block_template_generic_element_default_action_webheigh_ratio" v-model="current_block.params.default_action.webview_height_ratio">
                                                                    <option value="compact">Compact</option>
                                                                    <option value="tall">Tall</option>
                                                                    <option value="full">Full</option>
                                                                </select>
                                                                <div class="form-select-arrow">
                                                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                                </div>
                                                            </div>
                                                            <validation-notice param="params.default_action.webview_height_ration" :errors="current_block.errors"></validation-notice>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Generic Button Template -->
                                                <div v-if="current_block.messaging_type === 'template_generic_button'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Send Webview Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Send a message asking for the user's quick reply by selecting an option.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>
                                                    <validation-notice param="blocks" :errors="current_block.errors"></validation-notice>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <label class="form-label" for="template_generic_button_type">Type</label>
                                                        <div class="relative pb-3">
                                                            <select class="form-select" id="template_generic_button_type" name="template_generic_button_type" v-model="current_block.params.type">
                                                                <option :value="null">- Select Type -</option>
                                                                <option value="postback">Payload</option>
                                                                <option value="phone_number">Call</option>
                                                                <option value="web_url">Web URL</option>
                                                            </select>
                                                            <div class="form-select-arrow">
                                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                        <validation-notice param="params.type" :errors="current_block.errors"></validation-notice>

                                                        <label class="form-label" for="template_generic_button_title">Title</label>
                                                        <input type="text" class="form-text" id="template_generic_button_title" name="template_generic_button_title" v-model="current_block.params.title" />
                                                        <validation-notice param="params.title" :errors="current_block.errors"></validation-notice>

                                                        <div v-if="current_block.params.type === 'phone_number'">
                                                            <label class="form-label" for="template_button_button_phone_number">Phone Number</label>
                                                            <input type="text" class="form-text" id="template_button_button_phone_number" name="template_button_button_phone_number" v-model="current_block.params.phone_number" placeholder="+15555555555" />
                                                            <validation-notice param="params.phone_number" :errors="current_block.errors"></validation-notice>
                                                        </div>

                                                        <div v-if="current_block.params.type === 'web_url'">
                                                            <label class="form-label" for="template_generic_button_url">URL</label>
                                                            <input type="text" class="form-text" id="template_generic_button_url" name="template_generic_button_url" v-model="current_block.params.url" />
                                                            <validation-notice param="params.url" :errors="current_block.errors"></validation-notice>
                                                        </div>

                                                        <div v-if="current_block.params.type === 'web_url'">
                                                            <label class="form-label" for="template_generic_button_webview_height_ratio">Webview Height Ratio</label>
                                                            <div class="relative pb-3">
                                                                <select class="form-select" id="template_generic_button_webview_height_ratio" name="template_generic_button_webview_height_ratio" v-model="current_block.params.webview_height_ratio">
                                                                    <option value="compact">Compact</option>
                                                                    <option value="tall">Tall</option>
                                                                    <option value="full">Full</option>
                                                                </select>
                                                                <div class="form-select-arrow">
                                                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                                </div>
                                                            </div>
                                                            <validation-notice param="params.webview_height_ratio" :errors="current_block.errors"></validation-notice>
                                                        </div>

                                                        <div v-if="current_block.params.type === 'postback'">
                                                            <label class="form-label" for="template_generic_button_fb_attribute_value">Attribute Value</label>
                                                            <input type="text" class="form-text" id="template_generic_button_fb_attribute_value" name="template_generic_button_fb_attribute_value" v-model="current_block.params.fb_attribute_value" />
                                                            <validation-notice param="params.fb_attribute_value" :errors="current_block.errors"></validation-notice>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Button Template -->
                                                <div v-if="current_block.messaging_type === 'template_button'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Send Button Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Send a message asking for the user's quick reply by selecting an option.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>
                                                    <validation-notice param="blocks" :errors="current_block.errors"></validation-notice>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <label class="form-label" for="block_template_button_buttons">Buttons</label>
                                                        <input class="form-text" name="block_template_button_buttons" id="block_template_button_buttons" type="number" :value="getBlocksLength()" @change="updateButtonTemplateButtons($event)" />

                                                        <label class="form-label" for="block_template_generic_type">Type</label>
                                                        <div class="relative pb-3">
                                                            <select class="form-select" id="block_template_generic_type" name="block_template_generic_type" v-model="current_block.params.type">
                                                                <option :value="null">- Select Type -</option>
                                                                <option value="blocking">Blocking</option>
                                                                <option value="non_blocking">Non-Blocking</option>
                                                            </select>
                                                            <div class="form-select-arrow">
                                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                        <validation-notice param="params.type" :errors="current_block.errors"></validation-notice>

                                                        <label class="form-label" for="block_template_button_fb_attribute_id">Attribute</label>
                                                        <div class="relative pb-3">
                                                            <select class="form-select" id="block_template_button_fb_attribute_id" name="block_template_button_fb_attribute_id" v-model="current_block.params.fb_attribute_id">
                                                                <option :value="null">- Select Attribute -</option>
                                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                                            </select>
                                                            <div class="form-select-arrow">
                                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                        <validation-notice param="params.fb_attribute_id" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="Text"
                                                            id="block_template_button_message"
                                                            name="block_template_button_message"
                                                            refName="block_template_button_message"
                                                            max="2000"
                                                            v-model="current_block.params.message"
                                                            @input="current_block.params.message = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.message" :errors="current_block.errors"></validation-notice>
                                                    </div>
                                                </div>

                                                <!-- Button Button Template -->
                                                <div v-if="current_block.messaging_type === 'template_button_button'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Button Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Send a message asking for the user's quick reply by selecting an option.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>
                                                    <validation-notice param="blocks" :errors="current_block.errors"></validation-notice>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <label class="form-label" for="template_button_button_type">Type</label>
                                                        <div class="relative pb-3">
                                                            <select class="form-select" id="template_button_button_type" name="template_button_button_type" v-model="current_block.params.type">
                                                                <option :value="null">- Select Type -</option>
                                                                <option value="postback">Payload</option>
                                                                <option value="phone_number">Call</option>
                                                                <option value="web_url">Web URL</option>
                                                            </select>
                                                            <div class="form-select-arrow">
                                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                        <validation-notice param="params.type" :errors="current_block.errors"></validation-notice>

                                                        <label class="form-label" for="template_button_button_title">Title</label>
                                                        <input type="text" class="form-text" id="template_button_button_title" name="template_button_button_title" v-model="current_block.params.title" maxlength="20" />
                                                        <validation-notice param="params.title" :errors="current_block.errors"></validation-notice>

                                                        <div v-if="current_block.params.type === 'phone_number'">
                                                            <label class="form-label" for="template_button_button_phone_number">Phone Number</label>
                                                            <input type="text" class="form-text" id="template_button_button_phone_number" name="template_button_button_phone_number" v-model="current_block.params.phone_number" placeholder="+15555555555" />
                                                            <validation-notice param="params.phone_number" :errors="current_block.errors"></validation-notice>
                                                        </div>

                                                        <div v-if="current_block.params.type === 'web_url'">
                                                            <label class="form-label" for="template_button_button_url">URL</label>
                                                            <input type="text" class="form-text" id="template_button_button_url" name="template_button_button_url" v-model="current_block.params.url" />
                                                            <validation-notice param="params.url" :errors="current_block.errors"></validation-notice>
                                                        </div>

                                                        <div v-if="current_block.params.type === 'web_url'">
                                                            <label class="form-label" for="template_button_button_webview_height_ratio'">Webview Height Ratio</label>
                                                            <div class="relative pb-3">
                                                                <select class="form-select" id="template_button_button_webview_height_ratio" name="template_button_button_webview_height_ratio" v-model="current_block.params.webview_height_ratio">
                                                                    <option value="compact">Compact</option>
                                                                    <option value="tall">Tall</option>
                                                                    <option value="full">Full</option>
                                                                </select>
                                                                <div class="form-select-arrow">
                                                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                                </div>
                                                            </div>
                                                            <validation-notice param="params.webview_height_ratio" :errors="current_block.errors"></validation-notice>
                                                        </div>

                                                        <div v-if="current_block.params.type === 'postback'">
                                                            <label class="form-label" for="template_button_button_fb_attribute_value">Attribute Value</label>
                                                            <input type="text" class="form-text" id="template_button_button_fb_attribute_value" name="template_button_button_fb_attribute_value" v-model="current_block.params.fb_attribute_value" />
                                                            <validation-notice param="params.fb_attribute_value" :errors="current_block.errors"></validation-notice>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Quick Reply Block -->
                                                <div v-if="current_block.messaging_type === 'quick_reply'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Send Quick Reply Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Send a message asking for the user's quick reply by selecting an option.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>
                                                    <validation-notice param="blocks" :errors="current_block.errors"></validation-notice>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <label class="form-label" for="block_menu_choices">Choices</label>
                                                        <input class="form-text" name="block_menu_choices" id="block_menu_choices" type="number" :value="getBlocksLength()" @change="updateQuickReplyChoices($event)" />

                                                        <label class="form-label" for="block_quick_reply_fb_attribute_id">Attribute</label>
                                                        <div class="relative pb-3">
                                                            <select class="form-select" id="block_quick_reply_fb_attribute_id" name="block_quick_reply_fb_attribute_id" v-model="current_block.params.fb_attribute_id">
                                                                <option :value="null">- Select Attribute -</option>
                                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                                            </select>
                                                            <div class="form-select-arrow">
                                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                        <validation-notice param="params.fb_attribute_id" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="Request Message"
                                                            id="block_quick_reply_request"
                                                            name="block_quick_reply_request"
                                                            refName="block_quick_reply_request"
                                                            max="2000"
                                                            v-model="current_block.params.request"
                                                            @input="current_block.params.request = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.request" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="Invalid Response Message"
                                                            id="block_quick_reply_invalid"
                                                            name="block_quick_reply_invalid"
                                                            refName="block_quick_reply_invalid"
                                                            max="2000"
                                                            v-model="current_block.params.invalid"
                                                            @input="current_block.params.invalid = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.invalid" :errors="current_block.errors"></validation-notice>
                                                    </div>
                                                </div>

                                                <!-- Quick Reply Item Block -->
                                                <div v-if="current_block.messaging_type === 'quick_reply_item'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Quick Reply Item Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">View details for a specific quick reply item.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>

                                                    <label class="form-label" for="block_quick_reply_item_title">Button Text</label>
                                                    <input type="text" class="form-text" id="block_quick_reply_item_title" name="block_quick_reply_item_title" v-model="current_block.params.title" />
                                                    <validation-notice param="params.title" :errors="current_block.errors"></validation-notice>

                                                    <label class="form-label" for="block_quick_reply_item_fb_attribute_value">Attribute Value</label>
                                                    <input type="text" class="form-text" id="block_quick_reply_item_fb_attribute_value" name="block_quick_reply_item_fb_attribute_value" v-model="current_block.params.fb_attribute_value" />
                                                    <validation-notice param="params.fb_attribute_value" :errors="current_block.errors"></validation-notice>
                                                </div>

                                                <!-- Quick Reply (Email) Block -->
                                                <div v-if="current_block.messaging_type === 'quick_reply_email_address'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Send Quick Reply (Email) Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Send a message asking for the user's email address.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <label class="form-label" for="block_quick_reply_email_fb_attribute_id">Attribute</label>
                                                        <div class="relative pb-3">
                                                            <select class="form-select" id="block_quick_reply_email_fb_attribute_id" name="block_quick_reply_email_fb_attribute_id" v-model="current_block.params.fb_attribute_id">
                                                                <option :value="null">- Select Attribute -</option>
                                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                                            </select>
                                                            <div class="form-select-arrow">
                                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                        <validation-notice param="params.fb_attribute_id" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="Request Message"
                                                            id="block_quick_reply_email_request"
                                                            name="block_quick_reply_email_request"
                                                            refName="block_quick_reply_email_request"
                                                            max="2000"
                                                            v-model="current_block.params.request"
                                                            @input="current_block.params.request = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.request" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="Valid Response Message"
                                                            id="block_quick_reply_email_valid"
                                                            name="block_quick_reply_email_valid"
                                                            refName="block_quick_reply_email_valid"
                                                            max="2000"
                                                            v-model="current_block.params.valid"
                                                            @input="current_block.params.valid = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.valid" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="Invalid Response Message"
                                                            id="block_quick_reply_email_invalid"
                                                            name="block_quick_reply_email_invalid"
                                                            refName="block_quick_reply_email_invalid"
                                                            max="2000"
                                                            v-model="current_block.params.invalid"
                                                            @input="current_block.params.invalid = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.invalid" :errors="current_block.errors"></validation-notice>
                                                    </div>
                                                </div>

                                                <!-- Quick Reply (Phone) Block -->
                                                <div v-if="current_block.messaging_type === 'quick_reply_phone_number'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Send Quick Reply (Phone) Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Send a message asking for the user's phone number.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <label class="form-label" for="block_quick_reply_phone_fb_attribute_id">Attribute</label>
                                                        <div class="relative pb-3">
                                                            <select class="form-select" id="block_quick_reply_phone_fb_attribute_id" name="block_quick_reply_phone_fb_attribute_id" v-model="current_block.params.fb_attribute_id">
                                                                <option :value="null">- Select Attribute -</option>
                                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                                            </select>
                                                            <div class="form-select-arrow">
                                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                        <validation-notice param="params.fb_attribute_id" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="Request Message"
                                                            id="block_quick_reply_phone_request"
                                                            name="block_quick_reply_phone_request"
                                                            refName="block_quick_reply_phone_request"
                                                            max="2000"
                                                            v-model="current_block.params.request"
                                                            @input="current_block.params.request = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.request" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="Valid Response Message"
                                                            id="block_quick_reply_phone_valid"
                                                            name="block_quick_reply_phone_valid"
                                                            refName="block_quick_reply_phone_valid"
                                                            max="2000"
                                                            v-model="current_block.params.valid"
                                                            @input="current_block.params.valid = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.valid" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="Invalid Response Message"
                                                            id="block_quick_reply_phone_invalid"
                                                            name="block_quick_reply_phone_invalid"
                                                            refName="block_quick_reply_phone_invalid"
                                                            max="2000"
                                                            v-model="current_block.params.invalid"
                                                            @input="current_block.params.invalid = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.invalid" :errors="current_block.errors"></validation-notice>
                                                    </div>
                                                </div>

                                                <!-- Random Block -->
                                                <div v-if="current_block.messaging_type === 'random'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Random Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Provide a list of random options for the user.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>
                                                    <validation-notice param="blocks" :errors="current_block.errors"></validation-notice>

                                                    <label class="form-label" for="block_menu_choices">Choices</label>
                                                    <input class="form-text" name="block_menu_choices" id="block_menu_choices" type="number" :value="getBlocksLength()" @change="updateRandomChoices($event)" />

                                                    <label class="form-label" for="block_menu_fb_attribute_id">Attribute</label>
                                                    <div class="relative pb-3">
                                                        <select class="form-select" id="block_menu_fb_attribute_id" name="block_menu_fb_attribute_id" v-model="current_block.params.fb_attribute_id">
                                                            <option value="">- Select Attribute -</option>
                                                            <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                                        </select>
                                                        <div class="form-select-arrow">
                                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div>
                                                    </div>
                                                    <validation-notice param="params.fb_attribute_id" :errors="current_block.errors"></validation-notice>
                                                </div>

                                                <!-- Random Item Block -->
                                                <div v-if="current_block.messaging_type === 'random_item'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Menu Item Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">View details for a specific menu item.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>

                                                    <label class="form-label" for="block_random_item_name">Name</label>
                                                    <input type="text" class="form-text" id="block_random_item_name" name="block_random_item_name" v-model="current_block.params.name" />
                                                    <validation-notice param="params.name" :errors="current_block.errors"></validation-notice>

                                                    <label class="form-label" for="block_random_item_percent">Percentage</label>
                                                    <input type="text" class="form-text" id="block_random_item_percent" name="block_random_item_percent" v-model.number="current_block.params.percent" />
                                                    <validation-notice param="params.name" :errors="current_block.errors"></validation-notice>

                                                    <label class="form-label" for="block_random_item_fb_attribute_value">Attribute Value</label>
                                                    <input type="text" class="form-text" id="block_random_item_fb_attribute_value" name="block_random_item_fb_attribute_value" v-model="current_block.params.fb_attribute_value" />
                                                    <validation-notice param="params.fb_attribute_value" :errors="current_block.errors"></validation-notice>
                                                </div>

                                                <!-- Conditional Block -->
                                                <div v-if="current_block.messaging_type === 'conditional'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Conditional Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Check a set of criteria and perform an action based on result.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>
                                                    <validation-notice param="blocks" :errors="current_block.errors"></validation-notice>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <h2>Conditions</h2>
                                                        <div class="w-full">
                                                            <!-- Row 1 -->
                                                            <div class="flex flex-wrap -mx-3 mb-6">
                                                                <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
                                                                    <label class="form-label" for="'block_conditional_operator'">Join By</label>
                                                                    <div class="relative">
                                                                        <select class="form-select" id="block_conditional_operator" name="block_conditional_operator" v-model="current_block.params.conditions.operator">
                                                                            <option value="AND">AND</option>
                                                                            <option value="OR">OR</option>
                                                                        </select>
                                                                        <div class="form-select-arrow">
                                                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                                        </div>
                                                                    </div>
                                                                    <validation-notice param="params.conditions.operator" :errors="current_block.errors"></validation-notice>
                                                                </div>
                                                            </div>

                                                            <!-- Conditions -->
                                                            <div class="flex flex-wrap -mx-3 mb-6" v-for="(condition, condition_index) in current_block.params.conditions.conditions" v-bind:key="condition_index">
                                                                <validation-notice :param="'params.conditions.conditions.'+condition_index" :errors="current_block.errors"></validation-notice>

                                                                <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
                                                                    <label class="form-label" :for="'block_conditional_'+condition_index+'_operator'">Operator</label>
                                                                    <div class="relative">
                                                                        <select class="form-select" :id="'block_conditional_'+condition_index+'_operator'" :name="'block_conditional_'+condition_index+'_operator'" v-model="condition.operator">
                                                                            <optgroup label="Attribute">
                                                                                <option value="=">Equal To</option>
                                                                                <option value="!=">Not Equal To</option>
                                                                                <option value="<">Less Than</option>
                                                                                <option value="<=">Less Than Or Equal To</option>
                                                                                <option value=">">Greater Than</option>
                                                                                <option value=">=">Greater Than Or Euqal To</option>
                                                                                <option value="in">In</option>
                                                                                <option value="not_in">Not In</option>
                                                                                <option value="exists">Exists</option>
                                                                                <option value="not_exists">Does Not Exist</option>
                                                                            </optgroup>
                                                                            <optgroup label="Segment">
                                                                                <option value="on">On Segment</option>
                                                                                <option value="not_on">Not On Segment</option>
                                                                            </optgroup>
                                                                        </select>
                                                                        <div class="form-select-arrow">
                                                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                                        </div>
                                                                    </div>
                                                                    <validation-notice :param="'params.conditions.conditions.'+condition_index+'.operator'" :errors="current_block.errors"></validation-notice>
                                                                </div>

                                                                <div class="w-full md:w-2/5 px-3 mb-6 md:mb-0" v-if="condition.operator !== 'on' && condition.operator !== 'not_on'">
                                                                    <label class="form-label" :for="'block_conditional_'+condition_index+'_fb_attribute_id'">Attribute</label>
                                                                    <div class="relative pb-3">
                                                                        <select class="form-select" :id="'block_conditional_'+condition_index+'_fb_attribute_id'" :name="'block_conditional_'+condition_index+'_fb_attribute_id'" v-model="condition.fb_attribute_id">
                                                                            <option :value="null">- Select Attribute -</option>
                                                                            <option v-for="attribute in all_attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                                                        </select>
                                                                        <div class="form-select-arrow">
                                                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                                        </div>
                                                                    </div>
                                                                    <validation-notice :param="'params.conditions.conditions.'+condition_index+'.fb_attribute_id'" :errors="current_block.errors"></validation-notice>
                                                                </div>

                                                                <div class="w-full md:w-3/5 px-3 mb-6 md:mb-0" v-if="condition.operator === 'on' || condition.operator === 'not_on'">
                                                                    <label class="form-label" :for="'block_conditional_'+condition_index+'_fb_segment_id'">Segment</label>
                                                                    <div class="relative pb-3">
                                                                        <select class="form-select" :id="'block_conditional_'+condition_index+'_fb_segment_id'" :name="'block_conditional_'+condition_index+'_fb_segment_id'" v-model="condition.fb_segment_id">
                                                                            <option :value="null">- Select Segment -</option>
                                                                            <option v-for="segment in segments" v-bind:key="segment.id" :value="segment.id">{{ segment.name }}</option>
                                                                        </select>
                                                                        <div class="form-select-arrow">
                                                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                                        </div>
                                                                    </div>
                                                                    <validation-notice :param="'params.conditions.conditions.'+condition_index+'.fb_segment_id'" :errors="current_block.errors"></validation-notice>
                                                                </div>

                                                                <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0" v-if="condition.operator !== 'on' && condition.operator !== 'not_on' && condition.operator !== 'exists' && condition.operator !== 'not_exists'">
                                                                    <label class="form-label" :for="'block_conditional_'+condition_index+'_value'">Value</label>
                                                                    <input class="form-text" :id="'block_conditional_'+condition_index+'_value'" :name="'block_conditional_'+condition_index+'_value'" v-model="condition.value">
                                                                    <validation-notice :param="'params.conditions.conditions.'+condition_index+'.value'" :errors="current_block.errors"></validation-notice>
                                                                </div>

                                                                <div class="w-full md:w-1/5 cursor-pointer h-full pt-6 pl-6 text-right">
                                                                    <button class="btn btn-alert" @click="removeCondition(condition_index)"><i class="fas fa-minus-circle"></i></button>
                                                                </div>
                                                            </div>

                                                            <div class="flex flex-wrap mx-3 mb-6">
                                                                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-3" @click="addCondition()"><i class="fas fa-plus-circle"></i> ADD</button>
                                                            </div>

                                                            <validation-notice param="params.conditions.conditions" :errors="current_block.errors"></validation-notice>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Forward to Sequence Block -->
                                                <div v-if="current_block.messaging_type === 'forward_to_sequence'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Forward to Sequence Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Forward the person to another sequence.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>

                                                    <label class="form-label" for="block_forward_to_sequence_fb_sequence_id">Sequence</label>
                                                    <div class="relative pb-3">
                                                        <select class="form-select" id="block_forward_to_sequence_fb_sequence_id" name="block_forward_to_sequence_fb_sequence_id" v-model="current_block.params.fb_sequence_id">
                                                            <option :value="null">- Select Sequence -</option>
                                                            <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                                                        </select>
                                                        <div class="form-select-arrow">
                                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                        </div>
                                                    </div>
                                                    <validation-notice param="params._fb_sequence_id" :errors="current_block.errors"></validation-notice>
                                                </div>

                                                <!-- Forward to Inbox Block -->
                                                <div v-if="current_block.messaging_type === 'forward_to_inbox'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Forward to Sequence Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Forward the person to the inbox for live agent chat.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>

                                                    <label class="form-label" for="block_forward_to_inbox_delay_timespan">Delay Sequence Processing (in hours)</label>
                                                    <input type="number" class="form-text" id="block_forward_to_inbox_delay_timespan" name="block_forward_to_inbox_delay_timespan" v-model.number="current_block.params.delay_timespan" />
                                                    <validation-notice param="params.delay_timespan" :errors="current_block.errors"></validation-notice>
                                                </div>

                                                <!-- True Block -->
                                                <div v-if="current_block.messaging_type === 'true'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">True Result</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Execute items if the conditions specified are true.</div>
                                                </div>

                                                <!-- False Block -->
                                                <div v-if="current_block.messaging_type === 'false'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">False Result</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Execute items if the conditions specified are not true.</div>
                                                </div>

                                                <!-- Collect Attribute Block -->
                                                <div v-if="current_block.messaging_type === 'collect_attribute'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Collect Attribute Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Request a response to store as an attribute field on the user's profile.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <label class="form-label" for="block_collect_attribute_fb_attribute_id">Attribute</label>
                                                        <div class="relative pb-3">
                                                            <select class="form-select" id="block_collect_attribute_fb_attribute_id" name="block_collect_attribute_fb_attribute_id" v-model="current_block.params.fb_attribute_id">
                                                                <option :value="null">- Select Attribute -</option>
                                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                                            </select>
                                                            <div class="form-select-arrow">
                                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                        <validation-notice param="params.fb_attribute_id" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="Request Message"
                                                            id="block_collect_attribute_request"
                                                            name="block_collect_attribute_request"
                                                            refName="block_collect_attribute_request"
                                                            max="2000"
                                                            v-model="current_block.params.request"
                                                            @input="current_block.params.request = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.request" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="Valid Response Message"
                                                            id="block_collect_attribute_valid"
                                                            name="block_collect_attribute_valid"
                                                            refName="block_collect_attribute_valid"
                                                            max="2000"
                                                            v-model="current_block.params.valid"
                                                            @input="current_block.params.valid = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.valid" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="Invalid Response Message"
                                                            id="block_collect_attribute_invalid"
                                                            name="block_collect_attribute_invalid"
                                                            refName="block_collect_attribute_invalid"
                                                            max="2000"
                                                            v-model="current_block.params.invalid"
                                                            @input="current_block.params.invalid = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.invalid" :errors="current_block.errors"></validation-notice>
                                                    </div>
                                                </div>

                                                <!-- Tag Attribute Block -->
                                                <div v-if="current_block.messaging_type === 'tag_attribute'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Tag Attribute Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Tag a user with the associated attribute and value.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <label class="form-label" for="block_tag_attribute_fb_attribute_id">Attribute</label>
                                                        <div class="relative pb-3">
                                                            <select class="form-select" id="block_tag_attribute_fb_attribute_id" name="block_tag_attribute_fb_attribute_id" v-model="current_block.params.fb_attribute_id">
                                                                <option :value="null">- Select Attribute -</option>
                                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                                            </select>
                                                            <div class="form-select-arrow">
                                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                        <validation-notice param="params.fb_attribute_id" :errors="current_block.errors"></validation-notice>

                                                        <label class="form-label" for="block_tag_fb_attribute_value">Value</label>
                                                        <input type="text" class="form-text" id="block_tag_fb_attribute_value" name="block_tag_fb_attribute_value" v-model="current_block.params.fb_attribute_value" />
                                                        <validation-notice param="params.fb_attribute_value" :errors="current_block.errors"></validation-notice>
                                                    </div>
                                                </div>

                                                <!-- Add to Segment Block -->
                                                <div v-if="current_block.messaging_type === 'add_to_segment'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Add to Segment Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Add a user to a preconfigured segment.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <label class="form-label" for="block_add_to_segment_segment_id">Segment</label>
                                                        <div class="relative pb-3">
                                                            <select class="form-select" id="block_add_to_segment_segment_id" name="block_add_to_segment_segment_id" v-model="current_block.params.fb_segment_id">
                                                                <option :value="null">- Select Segment -</option>
                                                                <option v-for="segment in segments" v-bind:key="segment.id" :value="segment.id">{{ segment.name }}</option>
                                                            </select>
                                                            <div class="form-select-arrow">
                                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                        <validation-notice param="params.fb_segment_id" :errors="current_block.errors"></validation-notice>
                                                    </div>
                                                </div>

                                                <!-- Drip Message Block -->
                                                <div v-if="current_block.messaging_type === 'drip_message'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Drip Message Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Send a message to a person after a delay.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <label class="form-label" for="block_drip_message_send_at">Send After</label>
                                                        <div class="relative pb-3">
                                                            <select class="form-select" id="block_drip_message_send_at" name="block_drip_message_send_at" v-model="current_block.params.send_at">
                                                                <option :value="null">- Select Hours -</option>
                                                                <option v-for="hour in hours" v-bind:key="hour" :value="hour">{{ hour + (hour === 1 ? ' hour' : ' hours') }}</option>
                                                            </select>
                                                            <div class="form-select-arrow">
                                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                        <validation-notice param="params.send_at" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <label class="form-label" for="block_drip_message_send_active">Send If Active</label>
                                                        <div class="relative pb-3">
                                                            <select class="form-select" id="block_drip_message_send_active" name="block_drip_message_send_active" v-model="current_block.params.send_active">
                                                                <option :value="true">Yes</option>
                                                                <option :value="false">No</option>
                                                            </select>
                                                            <div class="form-select-arrow">
                                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                        <p class="text-xs italic mb-2">If yes, will only send if the user doesn't respond/trigger another sequence. If no, will send at that time regardless of the user's interactions with other sequences.</p>
                                                        <validation-notice param="params.send_active" :errors="current_block.errors"></validation-notice>
                                                    </div>
                                                </div>

                                                <!-- Notification Block -->
                                                <div v-if="current_block.messaging_type === 'notification'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Notification Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Send a notification outside the system during or after a sequence interaction.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <label class="form-label" for="block_notification_type">Notification Type</label>
                                                        <div class="relative pb-3">
                                                            <select class="form-select" id="block_notification_type" name="block_notification_type" v-model="current_block.params.type">
                                                                <option value="email">Email</option>
                                                                <option value="url_get">URL - GET Request</option>
                                                                <option value="url_post_xml">URL - POST Request (XML)</option>
                                                                <option value="url_post_json">URL - POST Request (JSON)</option>
                                                                <option value="sms">SMS</option>
                                                            </select>
                                                            <div class="form-select-arrow">
                                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                        <validation-notice param="params.type" :errors="current_block.errors"></validation-notice>

                                                        <div v-if="current_block.params.type === 'email' || current_block.params.type === 'sms'">
                                                            <label class="form-label" for="block_notification_recipient">Recipient</label>
                                                            <input class="form-text" id="block_notification_recipient" name="block_notification_recipient" type="text" v-model="current_block.params.recipient">
                                                            <validation-notice param="params.recipient" :errors="current_block.errors"></validation-notice>
                                                        </div>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="Subject"
                                                            id="block_notification_subject"
                                                            name="block_notification_subject"
                                                            refName="block_notification_subject"
                                                            max="2000"
                                                            v-if="current_block.params.type === 'email'"
                                                            v-model="current_block.params.subject"
                                                            @input="current_block.params.subject = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.subject" :errors="current_block.errors"></validation-notice>

                                                        <dynamic-textarea
                                                            label="URL"
                                                            id="block_notification_url"
                                                            name="block_notification_url"
                                                            refName="block_notification_url"
                                                            max="2000"
                                                            v-if="current_block.params.type === 'url_get' || current_block.params.type === 'url_post_xml' || current_block.params.type === 'url_post_json'"
                                                            v-model="current_block.params.url"
                                                            @input="current_block.params.url = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.url" :errors="current_block.errors"></validation-notice>

                                                        <dynamic-textarea
                                                            label="Body"
                                                            id="block_notification_body"
                                                            name="block_notification_body"
                                                            refName="block_notification_body"
                                                            max="2000"
                                                            v-if="current_block.params.type !== 'url_get'"
                                                            v-model="current_block.params.body"
                                                            @input="current_block.params.body = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.body" :errors="current_block.errors"></validation-notice>
                                                    </div>
                                                </div>

                                                <!-- Dynamic Content Block -->
                                                <div v-if="current_block.messaging_type === 'dynamic_content'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Dynamic Content Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Send an HTTP post out to a 3rd party service, providing dynamic interactions.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="URL"
                                                            id="block_dynamic_content_url"
                                                            name="block_dynamic_content_url"
                                                            refName="block_dynamic_content_url"
                                                            max="2000"
                                                            v-model="current_block.params.url"
                                                            @input="current_block.params.url = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.url" :errors="current_block.errors"></validation-notice>
                                                    </div>
                                                </div>

                                                <!-- Google Civic Polling Locator Block -->
                                                <div v-if="current_block.messaging_type === 'google_civic_polling_locator'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg">Google Civic Polling Locator Block</div>
                                                    </div>
                                                    <div class="text-sm pb-4 italic">Request an address to check against an election for polling location details.</div>
                                                    <validation-notice param="params" :errors="current_block.errors"></validation-notice>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <label class="form-label" for="block_polling_locator_election_id">Election</label>
                                                        <div class="relative pb-3">
                                                            <select class="form-select" id="block_google_civic_polling_locator_election_id" name="block_google_civic_polling_locator_election_id" v-model="current_block.params.election_id">
                                                                <option :value="null">- Select Election -</option>
                                                                <option v-for="election in integrations.google_civic_api.elections" v-bind:key="election.id" :value="election.id">{{ election.name }}</option>
                                                            </select>
                                                            <div class="form-select-arrow">
                                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                        <validation-notice param="params.election_id" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="Request Message"
                                                            id="block_collect_attribute_request"
                                                            name="block_collect_attribute_request"
                                                            refName="block_collect_attribute_request"
                                                            max="2000"
                                                            v-model="current_block.params.request"
                                                            @input="current_block.params.request = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.request" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="Valid Response Message"
                                                            id="block_collect_attribute_valid"
                                                            name="block_collect_attribute_valid"
                                                            refName="block_collect_attribute_valid"
                                                            max="2000"
                                                            showPollingLocatorOptions
                                                            v-model="current_block.params.valid"
                                                            @input="current_block.params.valid = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.valid" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="No Results Response Message"
                                                            id="block_collect_attribute_notfound"
                                                            name="block_collect_attribute_notfound"
                                                            refName="block_collect_attribute_notfound"
                                                            max="2000"
                                                            v-model="current_block.params.notfound"
                                                            @input="current_block.params.notfound = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.notfound" :errors="current_block.errors"></validation-notice>
                                                    </div>

                                                    <div class="rounded-lg bg-blue-200 p-4 m-4">
                                                        <dynamic-textarea
                                                            label="Invalid Response Message"
                                                            id="block_collect_attribute_invalid"
                                                            name="block_collect_attribute_invalid"
                                                            refName="block_collect_attribute_invalid"
                                                            max="2000"
                                                            v-model="current_block.params.invalid"
                                                            @input="current_block.params.invalid = $event">
                                                        </dynamic-textarea>
                                                        <validation-notice param="params.invalid" :errors="current_block.errors"></validation-notice>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>


                    <div class="flex flex-wrap m-5 px-3 pt-3">
                        <button class="btn btn-primary" v-on:click="save">
                            Update Sequence
                        </button>

                        <button class="btn btn-secondary" v-on:click="cancel">
                            Cancel
                        </button>
                    </div>
                </div>

            <div class="w-64" v-if="show_help_section">
                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'details'">
                    <h2 class="p-4">Sequence Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Sequences define the logic a person will have to follow when interacting with the bot, based on the provided keyword or action triggered.  Some elements will block while
                                waiting for input, while others will simple provide audio feedback.
                            </p>
                            <p class="text-xs italic pb-2"><strong>Name</strong> - Provide a descriptive, unique name for the sequence to help identify it for future edits and use.  Example: Contact Congress Sequence.</p>
                        </div>
                    </div>
                </div>

                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'keywords'">
                    <h2 class="p-4">Keyword Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Keywords provide a way to catch user input to trigger a sequence.  Caution should be used when creating keyword rules, since overlapping rules could result in the incorrect
                                sequence being processed.
                            </p>
                            <p class="text-xs italic pb-2"><strong>Keyword/Phrase</strong> - A word or words that are matched against when looking at user input, not part of a response to an existing sequence.</p>
                            <p class="text-xs italic pb-2"><strong>Rule</strong> - The rule in which the keyword/phrase will be matched.  Exact Match will only match when the person's input and keyword/phrase match exactly; Starts With
                            will only match when the person's input starts with the keyword/phrase; Contains will match when the person's input contains the keyword/phrase.  All rules are case-insensitive.</p>
                        </div>
                    </div>
                </div>

                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'blocks'">
                    <h2 class="p-4">Blocks Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Blocks are the individual components that define how a sequence will function when a person interacts with it.</p>
                            <p class="text-xs italic pb-2"><strong>Send Message</strong> - Provide a text message that will be sent back to the person.</p>
                            <p class="text-xs italic pb-2"><strong>Send Image</strong> - Provide an image that will be sent back to the person.</p>
                            <p class="text-xs italic pb-2"><strong>Typing</strong> - Displays a temporary typing indicator in the chat with the person.</p>
                            <p class="text-xs italic pb-2"><strong>Quick Reply</strong> - Prompts the person with text and a series of buttons for each choice. A person can select a button, or enter text as a response.</p>
                            <p class="text-xs italic pb-2"><strong>Quick Reply (Email)</strong> - Prompts the person with text and a button showing their email from their account. A person can select the button, or enter text as a response.</p>
                            <p class="text-xs italic pb-2"><strong>Quick Reply (Phone)</strong> - Prompts the person with text and a button showing their phone number from their account. A person can select the button, or enter text as a response.</p>
                            <p class="text-xs italic pb-2"><strong>Webview Template</strong> - Template that provides multiple elements, each with their own set of button options.  If set to Blocking, the user must select a provided button option to continue.
                            If set to Non-Blocking, the user can choose to enter text as a response, and if invalid for the attribute, the value will not be saved.</p>
                            <p class="text-xs italic pb-2"><strong>Button Template</strong> - Template that provides multiple button options.  If set to Blocking, the user must select a provided button option to continue.
                            If set to Non-Blocking, the user can choose to enter text as a response, and if invalid for the attribute, the value will not be saved.</p>
                            <p class="text-xs italic pb-2"><strong>Conditional</strong> - A true/false logic block that proceeds based on the set of conditionls defined, in relation to the current person in the chat.</p>
                            <p class="text-xs italic pb-2"><strong>Collect Attr</strong> - Prompts the user with text asking for a value and stores the value as an attribute for the person. If the
                            attribute value is not valid based on the attribute type, the invalid message will be returned, and require the user to provide a valid value.  If the value is valid, the valid message will be returned.</p>
                            <p class="text-xs italic pb-2"><strong>Tag Attr</strong> - Silently tags the person with the specified a attribute and value.</p>
                            <p class="text-xs italic pb-2"><strong>Add to Segment</strong> - Adds the person to a static segment.</p>
                            <p class="text-xs italic pb-2"><strong>Forward Sequence</strong> - Forwards the person over to another sequence and beings processing there.</p>
                            <p class="text-xs italic pb-2"><strong>Drip Message</strong> - Sends a followup message after a set period of time.</p>
                            <p class="text-xs italic pb-2"><strong>Dynamic Content</strong> - Provides a method for sending a request to an API endpoint, that can then send back a dynamic response the bot will process and forward to the person.</p>
                            <p class="text-xs italic pb-2"><strong>Polling Locator</strong> - Polling locator prompt that uses the Google Civic API to find polling location details to send back and share with the person.</p>
                            <p class="text-xs italic pb-2"><strong>Notification</strong> - Sends an outbound notification either through an API call, email, or text message.  Email and text message limited to an account administrator contact.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'
    import Vue from 'vue'
    import ValidationNotice from '../../shared/ValidationNotice'
    import DynamicTextarea from '../../shared/DynamicTextarea'
    import Builder from './Builder'

    export default {
        name: 'UpdateSequence',
        components: {
            LayoutApp,
            ValidationNotice,
            DynamicTextarea,
            Builder,
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetchBots();
            this.fetchAttributes();
            this.fetchSegments();
            this.fetchSequences();
            this.fetchSequence();
            this.fetchIntegrations();
        },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        watch: {
            hasErrors: function (obj, key) {
                var item = _.get(obj, key);

                if (item !== undefined) {
                    return true;
                } else {
                    return false;
                }
            },
            showErrors: function (obj, key) {
                var item = _.get(obj, key);

                if (item !== undefined) {
                    return item[0];
                } else {
                    return '';
                }
            }
        },
        data() {
            return {
                bots: [],
                attributes: [],
                all_attributes: [],
                segments: [],
                sequences: [],
                integrations: {
                    google_civic_api: {
                        status: 'inactive',
                        elections: []
                    }
                },
                sequence: {
                  id: '',
                  name: '',
                  fb_bot_id: this.$store.getters.current_bot_id,
                  status: 'active',
                  keywords: [],
                  blocks: []
                },
                current_block: null,
                current_id: 0,
                errors: [],
                block_errors: {},
                current_block_index: null,
                attachment_file: null,
                hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
                show_help_section: null,
            }
        },
        methods: {
            fetchSequence(id) {
                axios.get(this.sequenceUrl(id)).then(this.refreshSequence);
            },
            sequenceUrl() {
                return `/api/v1/sequences/${this.id}`;
            },
            refreshSequence({data}) {
                this.setupBlocks(data.data.blocks)
                this.sequence = data.data;
            },
            fetchBots() {
                axios.get(this.botsUrl()).then(this.refreshBots);
            },
            botsUrl() {
                return `/api/v1/bots`;
            },
            refreshBots({data}) {
                this.bots = data.data;
            },
            fetchSegments() {
                axios.get(this.segmentsUrl()).then(this.refreshSegments);
            },
            segmentsUrl() {
                return `/api/v1/segments?fb_bot_id=${this.sequence.fb_bot_id}&search_types=static`;
            },
            refreshSegments({data}) {
                this.segments = data.data;
            },
            fetchSequences() {
                axios.get(this.sequencesUrl()).then(this.refreshSequences);
            },
            sequencesUrl() {
                return `/api/v1/sequences?fb_bot_id=${this.sequence.fb_bot_id}`;
            },
            refreshSequences({data}) {
                this.sequences = data.data;
            },
            fetchAttributes() {
                axios.get(this.attributesUrl()).then(this.refreshAttributes);
            },
            attributesUrl() {
                return `/api/v1/attributes`;
            },
            refreshAttributes({data}) {
                for(let index in data.data) {
                    this.all_attributes.push(data.data[index]);

                    if (data.data[index].scope !== 'system') {
                        this.attributes.push(data.data[index]);
                    }
                }
            },
            fetchIntegrations() {
                axios.get(this.integrationsUrl()).then(this.refreshIntegrtations);
            },
            integrationsUrl() {
                return `/api/v1/integrations`;
            },
            refreshIntegrtations({
                data
            }) {
                for (let index in data.data) {
                    if (data.data[index].integration === 'google_civic_api' && data.data[index].status === 'active') {
                        this.integrations.google_civic_api.status = 'active';
                        this.integrations.google_civic_api.elections = this.getGoogleCivicAPIElections(data.data[index].parameters.api_key);
                    }
                }
            },
            getGoogleCivicAPIElections(api_key) {
                axios.get(`https://www.googleapis.com/civicinfo/v2/elections?key=${api_key}`, {transformRequest: (data, headers) => {
                    delete headers.common['Authorization']
                    delete headers.common['X-CSRF-TOKEN']
                    delete headers['X-Socket-Id']
                }}).then(this.refreshElections);
            },
            refreshElections(data) {
                this.integrations.google_civic_api.elections = data.data.elections;
            },
            setupBlocks(blocks) {
                for (let i = 0 ; i < blocks.length ; ++i) {
                    blocks[i]['errors'] = []

                    if (blocks[i].hasOwnProperty('blocks')) {
                        this.setupBlocks(blocks[i].blocks)
                    }
                }
            },
            save() {
              axios.put('/api/v1/sequences/'+this.id, this.sequence).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The sequence has been updated',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_sequences'});
              }).catch(error => {
                if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {}
                        this.block_errors = {}
                        this.clearAllBlockErrors(this.sequence.blocks)

                        for (var block_index in this.errors) {
                            if (block_index.substr(0, 6) === 'blocks') {
                                var full_index = block_index.substring(7, block_index.lastIndexOf('.params'))
                                var param = block_index.substring(block_index.lastIndexOf('.params') + 1)

                                if (!this.block_errors[full_index]) {
                                    this.block_errors[full_index] = {}
                                }

                                if (!this.block_errors[full_index][param]) {
                                    this.block_errors[full_index][param] = []
                                }

                                this.block_errors[full_index][param] = this.errors[block_index]

                                full_index = block_index.substring(7, block_index.lastIndexOf('.blocks'))
                                param = block_index.substring(block_index.lastIndexOf('.blocks') + 1)

                                if (!this.block_errors[full_index]) {
                                    this.block_errors[full_index] = {}
                                }

                                if (!this.block_errors[full_index][param]) {
                                    this.block_errors[full_index][param] = []
                                }

                                this.block_errors[full_index][param] = this.errors[block_index]
                            }
                        }

                        this.checkBlockErrors(this.sequence.blocks, '')
                    } else {
                        this.$notify({
                            group: 'alerts',
                            type: 'error',
                            title: 'An error occurred while creating the sequence',
                            duration: 5000,
                            speed: 1000
                        });
                    }
              });
            },
            cancel() {
                this.$router.back();
            },
            clearBlockErrors(block) {
                block.errors = []
            },
            clearAllBlockErrors(blocks) {
                for (var block_index in blocks) {
                    blocks[block_index].errors = []

                    if (this.isNestedBlockType(blocks[block_index].messaging_type)) {
                        this.clearAllBlockErrors(blocks[block_index].blocks)
                    }
                }
            },
            checkBlockErrors(blocks, parent_index) {
                for (var block_index in blocks) {
                    let display_index = this.getDisplayIndex(parent_index, block_index)

                    if (display_index in this.block_errors) {
                        blocks[block_index].errors = this.block_errors[display_index]
                    }

                    if (this.isNestedBlockType(blocks[block_index].messaging_type)) {
                        this.checkBlockErrors(blocks[block_index].blocks, display_index)
                    }
                }
            },
            getDisplayIndex(parent_index, block_index) {
                return ((parent_index === '' ? '' : parent_index + '.') + block_index)
            },
            isNestedBlockType(type) {
                if (type === 'quick_reply' || type === 'quick_reply_item'
                    || type === 'template_generic' || type === 'template_generic_element'
                    || type === 'template_generic_button' || type === 'template_button'
                    || type === 'template_button_button' || type === 'conditional'
                    || type === 'true' || type === 'false'
                    || type === 'random' || type === 'random_item') {
                    return true
                } else {
                    return false
                }
            },
            addBlock(type) {
                let block = null

                if (type === 'message') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'message',
                        'params': {
                            'message': ''
                        },
                        'errors': [],
                    }
                } else if (type === 'attachment') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'attachment',
                        'params': {
                            'type': 'image',
                            'file_type': 'url',
                            'attachment_id': null,
                            'url': null
                        },
                        'errors': [],
                    }
                } else if (type === 'template_call_button') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'template_call_button',
                        'params': {
                            'message': '',
                            'title': '',
                            'number': '',
                        },
                        'errors': [],
                    }
                } else if (type === 'typing') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'typing',
                        'params': {
                            'duration': 1,
                        },
                        'errors': [],
                    }
                } else if (type === 'quick_reply') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'quick_reply',
                        'params': {
                            'request': '',
                            'invalid': '',
                            'fb_attribute_id': null,
                        },
                        'blocks': [],
                        'errors': [],
                    }
                } else if (type === 'template_generic') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'template_generic',
                        'blocks': [
                            {
                                'id': ('new_'+this.current_id++),
                                'messaging_type': 'template_generic_element',
                                'params': {
                                    'title': '',
                                    'image_url': '',
                                    'subtitle': '',
                                    'default_action': {
                                        'url': '',
                                        'webview_height_ratio': '',
                                    },
                                    'fb_attribute_id': null,
                                },
                                'blocks': [],
                                'errors': [],
                            },
                        ],
                        'errors': [],
                    }
                } else if (type === 'template_button') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'template_button',
                        'params': {
                            'message': '',
                            'fb_attribute_id': null,
                        },
                        'blocks': [],
                        'errors': [],
                    }
                } else if (type === 'quick_reply_email_address') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'quick_reply_email_address',
                        'params': {
                            'request': '',
                            'valid': '',
                            'invalid': '',
                            'fb_attribute_id': null,
                        },
                        'errors': [],
                    }
                } else if (type === 'quick_reply_phone_number') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'quick_reply_phone_number',
                        'params': {
                            'request': '',
                            'valid': '',
                            'invalid': '',
                            'fb_attribute_id': null,
                        },
                        'errors': [],
                    }
                } else if (type === 'random') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'random',
                        'choices': 1,
                        'params': {
                            'fb_attribute_id': '',
                        },
                        'blocks': [
                            {
                                'id': ('new_'+this.current_id++),
                                'messaging_type': 'random_item',
                                'params': {
                                    'percent': 100,
                                    'name': 'Choice 1',
                                    'fb_attribute_value': null,
                                },
                                'blocks': [],
                                'errors': [],
                            }
                        ],
                        'errors': [],
                    }
                } else if (type === 'collect_attribute') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'collect_attribute',
                        'params': {
                            'request': '',
                            'valid': '',
                            'invalid': '',
                            'fb_attribute_id': null,
                        },
                        'errors': [],
                    }
                } else if (type === 'conditional') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'conditional',
                        'params': {
                            'conditions': {
                                'operator': 'AND',
                                'conditions': []
                            },
                        },
                        'blocks': [
                            {
                                'id': ('new_'+this.current_id++),
                                'messaging_type': 'true',
                                'blocks': [],
                                'errors': [],
                            },
                            {
                                'id': ('new_'+this.current_id++),
                                'messaging_type': 'false',
                                'blocks': [],
                                'errors': [],
                            },
                        ],
                        'errors': [],
                    }
                } else if (type === 'tag_attribute') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'tag_attribute',
                        'params': {
                            'fb_attribute_value': '',
                            'fb_attribute_id': null,
                        },
                        'errors': [],
                    }
                } else if (type === 'add_to_segment') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'add_to_segment',
                        'params': {
                            'fb_segment_id': null,
                        },
                        'errors': [],
                    }
                } else if (type === 'forward_to_sequence') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'forward_to_sequence',
                        'params': {
                            'fb_sequence_id': null,
                        },
                        'errors': [],
                    }
                } else if (type === 'forward_to_inbox') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'forward_to_inbox',
                        'params': {
                            'delay_timespan': '',
                        },
                        'errors': [],
                    }
                } else if (type === 'drip_message') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'drip_message',
                        'params': {
                            'send_at': 1,
                            'send_active': true
                        },
                        'errors': [],
                    }
                } else if (type === 'notification') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'notification',
                        'params': {
                            'type': 'email',
                            'recipient': '',
                            'body': '',
                            'subject': '',
                            'url': '',
                        },
                        'errors': [],
                    }
                } else if (type === 'dynamic_content') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'dynamic_content',
                        'params': {
                            'url': ''
                        },
                        'errors': [],
                    }
                } else if (type === 'google_civic_polling_locator') {
                    block = {
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'google_civic_polling_locator',
                        'params': {
                            'request': '',
                            'valid': '',
                            'invalid': '',
                            'notfound': '',
                            'election_id': ''
                        },
                        'errors': [],
                    }
                }

                this.sequence.blocks.push(block)
                this.setActiveBlock(block)
            },
            setActiveBlock(block) {
                this.current_block = block
            },
            removeBlock(block, parent = null) {
                this.current_block = null;
                this.block_errors = {};
                this.errors = [];

                if (parent === null) {
                    parent = this.sequence
                }

                if (parent.blocks) {
                    for (let i = 0 ; i < parent.blocks.length ; ++i) {
                        if (parent.blocks[i].id === block.id) {
                            Vue.delete(parent.blocks, i)
                            break
                        }

                        this.removeBlock(block, parent.blocks[i])
                    }
                }
            },
            getBlocksLength() {
                return this.current_block.blocks.length
            },
            findItemByID(id, parent) {
                if (parent.hasOwnProperty(blocks)) {
                    if (parent.id === id) {
                        return
                    }

                    for (let i = 0 ; i < parent.blocks ; ++i) {
                        this.findItemByID(id, parent.blocks[i])
                    }
                }
            },
            addKeyword() {
                this.sequence.keywords.push({
                    keyword: '',
                    rule: 'exact',
                });
            },
            removeKeyword(index) {
                Vue.delete(this.sequence.keywords, index);
            },
            addCondition() {
                this.current_block.params.conditions.conditions.push({
                    fb_attribute_id: '',
                    fb_segment_id: '',
                    operator: '=',
                    value: ''
                });
            },
            removeCondition(condition_index) {
                Vue.delete(this.current_block.params.conditions.conditions, condition_index);
            },
            submitFile() {
                let formData = new FormData();
                formData.append('file', this.attachment_file);
                formData.append('fb_bot_id', this.$store.getters.current_bot_id);

                axios.post('/api/v1/attachments', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.current_block.params.attachment_id = response.data.attachment_id;
                    this.current_block.params.url = response.data.attachment_url;
                }).catch(error => {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while uploading the attachment',
                        duration: 5000,
                        speed: 1000
                    });
                });
            },
            removeFile() {
                if (this.current_block.params.attachment_id) {
                    this.current_block.params.attachment_id = null;
                    this.current_block.params.url = null;
                }
            },
            handleFileUpload(offset) {
                this.attachment_file = this.$refs[offset].files[0];
            },
            updateQuickReplyChoices(event) {
                if (event.target.value > this.current_block.blocks.length) {
                    this.current_block.blocks.push({
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'quick_reply_item',
                        'params': {
                            'title': 'Choice 1',
                            'fb_attribute_value': null,
                        },
                        'blocks': [],
                        'errors': [],
                    })
                } else if (event.target.value < this.current_block.blocks.length) {
                    Vue.delete(this.current_block.blocks, this.current_block.blocks.length - 1);
                }
            },
            updateGenericTemplateElements(event) {
                if (event.target.value > this.current_block.blocks.length) {
                    this.current_block.blocks.push({
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'template_generic_element',
                        'params': {
                            'title': '',
                            'image_url': '',
                            'subtitle': '',
                            'default_action': {
                                'url': '',
                                'webview_height_ratio': '',
                            },
                        },
                        'blocks': [],
                        'errors': [],
                    })
                } else if (event.target.value < this.current_block.blocks.length) {
                    Vue.delete(this.current_block.blocks, this.current_block.blocks.length - 1);
                }
            },
            updateElementButtons(event) {
                if (event.target.value > this.current_block.blocks.length) {
                    this.current_block.blocks.push({
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'template_generic_button',
                        'params': {
                            'type': '',
                            'title': '',
                            'fb_attribute_value': '',
                            'url': '',
                            'webview_height_ratio': '',
                            'phone_number': '',
                        },
                        'blocks': [],
                        'errors': [],
                    })
                } else if (event.target.value < this.current_block.blocks.length) {
                    Vue.delete(this.current_block.blocks, this.current_block.blocks.length - 1);
                }
            },
            updateButtonTemplateButtons(event) {
                if (event.target.value > this.current_block.blocks.length) {
                    this.current_block.blocks.push({
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'template_button_button',
                        'params': {
                            'type': '',
                            'title': '',
                            'fb_attribute_value': '',
                            'url': '',
                            'webview_height_ratio': '',
                            'phone_number': '',
                        },
                        'blocks': [],
                        'errors': [],
                    })
                } else if (event.target.value < this.current_block.blocks.length) {
                    Vue.delete(this.current_block.blocks, this.current_block.blocks.length - 1);
                }
            },
            updateRandomChoices(event) {
                if (event.target.value > this.current_block.blocks.length) {
                    this.current_block.blocks.push({
                        'id': ('new_'+this.current_id++),
                        'messaging_type': 'random_item',
                        'params': {
                            'percent': 100,
                            'name': 'Choice 1',
                            'fb_attribute_value': null,
                        },
                        'blocks': [],
                        'errors': [],
                    })
                } else if (event.target.value < this.current_block.blocks.length) {
                    Vue.delete(this.current_block.blocks, this.current_block.blocks.length - 1);
                }
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }
</script>
