import Pusher from "pusher-js"
import Echo from "laravel-echo"

window._ = require('lodash');
window.axios = require('axios');

window.axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_API_KEY,
    cluster: process.env.MIX_PUSHER_CLUSTER,
    encrypted: true
});
