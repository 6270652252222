<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Attributes</h1>
            <router-link class="mr-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" to="/attributes/create">
                Create New Attribute
            </router-link>
        </div>
        <div class="m-5 bg-white">
            <div class="bg-white shadow-md rounded my-6 flex flex-row">
                <div class="w-1/5 flex-initial text-center px-4 py-2 m-2">
                    <input class="form-text" type="text" id="search" name="search" v-model="search" placeholder="Search" @blur="updateSearch()" />
                </div>
                <div class="w-1/5 flex-initial text-center px-4 py-2 m-2">
                    <multiselect v-model="search_scopes" :options="scopes" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Filter by Scope" @input="updateSearch()"></multiselect>
                </div>
                <div class="w-1/5 flex-initial text-center px-4 py-2 m-2">
                    <multiselect v-model="search_values_types" :options="values_types" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Filter by Value Type" @input="updateSearch()"></multiselect>
                </div>
                <div class="w-1/5 flex-initial text-center px-4 py-2 m-2">
                    <multiselect v-model="search_subtypes" :options="subtypes" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Filter by Type" @input="updateSearch()"></multiselect>
                </div>
                <div class="w-1/5 flex-initial text-center px-4 py-2 m-2">
                    <multiselect v-model="search_statuses" :options="statuses" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Filter by Status" @input="updateSearch()"></multiselect>
                </div>
            </div>
        </div>
        <div class="m-5 bg-white">
            <div class="">
                <div class="bg-white shadow-md rounded my-6">
                    <table class="listing">
                        <thead>
                            <tr>
                                <th><a href="javascript:void(0)" @click="updateSort('id')">ID</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('name')">Name</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('scope')">Scope</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('subtype')">Type</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('length')">Length</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('status')">Status</a></th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="" v-for="attribute in attributes" v-bind:key="attribute.id">
                                <td class="py-4 px-6 border-b border-grey-light">{{ attribute.id }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">{{ attribute.name }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">{{ attribute.scope | capitalize }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">{{ attribute.subtype | capitalize }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">{{ attribute.length }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">
                                    <span v-bind:class="{'bg-green-500': (attribute.status === 'active'), 'bg-gray-600': (attribute.status === 'inactive')}" class="text-white font-bold py-1 px-2 rounded-full text-sm">{{ attribute.status | capitalize }}</span>
                                </td>
                                <td class="py-4 px-6 border-b border-grey-light">
                                    <router-link :to="{ name: 'edit_attribute', params: { id: attribute.id }}" v-if="attribute.scope === 'client'" class="mx-3"><i class="fas fa-pencil-alt"></i></router-link>
                                    <a href="javascript:void(0);" v-on:click="confirmDelete(attribute.id)" v-if="attribute.scope === 'client'" class="mx-3"><i class="fas fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <paginate
            :page-count="page_count"
            :click-handler="fetch"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'pagination-page'"
            :prev-class="'pagination-prev'"
            :next-class="'pagination-next'"
            :disabled-class="'pagination-inactive'">
        </paginate>

        <modal name="delete-confirm" class="confirm-modal" :height="220">
            <div class="modal-container">
                <div class="modal-content">
                    <div class="modal-c-title">Delete Attribute</div>
                    <div class="modal-c-text">Are you sure you want to delete this attribute?  This action cannot be undone.</div>
                </div>
                <div class="modal-buttons">
                    <button type="button" class="modal-button" @click="$modal.hide('delete-confirm')">Cancel</button>
                    <button type="button" class="modal-button-primary" @click="deleteAttribute()">Delete</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'

    export default {
        name: 'ListAttributes',
        components: {
            LayoutApp
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetch(1);
        },
        data() {
            return {
                attributes: [],
                delete_id: null,
                page_count: 1,
                sort: 'desc',
                order: 'id',
                statuses: [
                    {id: 'active', name: 'Active'},
                    {id: 'inactive', name: 'Inactive'},
                ],
                scopes: [
                    {id: 'system', name: 'System'},
                    {id: 'client', name: 'Client'},
                ],
                values_types: [
                    {id: 'open', name: 'Open'},
                    {id: 'predefined', name: 'Predefined'},
                ],
                subtypes: [
                    {id: 'email', name: 'Email'},
                    {id: 'phone', name: 'Phone'},
                    {id: 'string', name: 'String'},
                    {id: 'zip', name: 'ZIP'},
                    {id: 'zip_plusfour', name: 'ZIP Plus Four'},
                    {id: 'numeric_only', name: 'Numeric Only'},
                    {id: 'alpha_only', name: 'Alpha Only'},
                    {id: 'alphanumeric_only', name: 'Alpha Numeric Only'},
                    {id: 'boolean', name: 'Boolean'},
                    //{id: 'date', name: 'Date'},
                    //{id: 'datetime', name: 'Date/Time'},
                ],
                search: this.$route.query.search || '',
                search_statuses: [],
                search_scopes: [],
                search_values_types: [],
                search_subtypes: [],
            }
        },
        methods: {
            fetch(page) {
                this.current_page = page;

                let search_scopes = this.search_scopes.map(scope => scope.id)
                let search_statuses = this.search_statuses.map(status => status.id)
                let search_values_types = this.search_values_types.map(values_type => values_type.id)
                let search_subtypes = this.search_subtypes.map(subtype => subtype.id)

                axios.get(`/api/v1/attributes?page=${this.current_page}&sort=${this.sort}&order=${this.order}&search=${this.search}&search_scopes=${search_scopes}&search_statuses=${search_statuses}&search_values_types=${search_values_types}&search_subtypes=${search_subtypes}`).then(this.refresh);

                this.$router.push({
                    query: {
                        page: this.current_page,
                        sort: this.sort,
                        order: this.order,
                        search: this.search,
                    }
                }).catch(err => {})
            },
            refresh({data}) {
                this.attributes = data.data;
                this.page_count = data.meta.pages;
                window.scrollTo(0,0);
            },
            confirmDelete(id) {
                this.delete_id = id;

                this.$modal.show('delete-confirm');
            },
            deleteAttribute() {
                axios.delete(`/api/v1/attributes/${this.delete_id}`).then(this.postDelete);
            },
            postDelete() {
                this.$modal.hide('delete-confirm');
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The attribute has been deleted',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch(1);
            },
            updateSort(field) {
                if (field === this.order) {
                    this.sort = (this.sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.sort = 'asc';
                }

                this.order = field;

                this.fetch(1);
            },
            updateSearch() {
                this.fetch(1);
            },
        }
    }
</script>
