<template>
  <div v-if="is_loaded">
    <nav class="bg-gray-900 text-white pt-2 md:pt-1 pb-1 px-1 h-13 mt-0 w-full top-0">

        <div class="flex flex-wrap items-center">
            <div class="flex flex-shrink md:w-1/3 justify-center md:justify-start text-white ml-1">
                <a :href="portal_url"><img width="130" height="60" src="/img/SBR_horiz_med_light.png" /></a>
            </div>

            <div class="flex flex-1 md:w-1/3 justify-center md:justify-start text-white px-2" >
                <div v-if="this.current_bot_id !== null" class="w-full flex flex-1">
                    <select v-on:change="updateBot()" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="current_bot_id" name="current_bot_id" v-model="current_bot_id">
                        <option v-for="bot in bots" v-bind:key="bot.id" :value="bot.id">{{ bot.name }} ({{ bot.fb_page_name }})</option>
                    </select>
                    <div class="pointer-events-none inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                </div>
			</div>

            <div class="flex w-full pt-2 content-center justify-between md:w-1/3 md:justify-end">
				<ul class="list-reset flex justify-between flex-1 md:flex-none items-center">
				    <li class="flex-1 md:flex-none md:mr-3">
					    <a class="inline-block py-2 px-4 text-white no-underline" :href="help_url" target="_blank">Help</a>
				    </li>
                    <li class="flex-1 md:flex-none md:mr-3">
					    <a class="inline-block py-2 px-4 text-white no-underline" :href="support_url">Support</a>
				    </li>
				    <li class="flex-1 md:flex-none md:mr-3">
						<div class="relative inline-block">
							<button @click="toggleUserDropdown('user_dropdown')" class="drop-button text-white focus:outline-none">
                                <span class="pr-2"></span>
                                Hi, {{user.first_name}}
                                <svg class="h-3 fill-current inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                </svg>
                            </button>
                            <div id="user_dropdown" class="dropdownlist absolute bg-black text-white pin-r mt-3 p-3 overflow-auto z-30 invisible">
                                <a :href="portal_url+'/settings/profile'" class="p-2 hover:bg-grey-darkest text-white text-sm no-underline hover:no-underline block"><i class="fa fa-user fa-fw"></i> Profile</a>
                                <a :href="portal_url+'/settings/account'" class="p-2 hover:bg-grey-darkest text-white text-sm no-underline hover:no-underline block"><i class="fa fa-cog fa-fw"></i> Settings</a>
                                <div class="border border-grey-darkest"></div>
                                <a href="javascript:void(0)" @click="logoutUser()" class="p-2 hover:bg-grey-darkest text-white text-sm no-underline hover:no-underline block"><i class="fas fa-sign-out-alt fa-fw"></i> Log Out</a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <div class="flex flex-col md:flex-row">
      <div class="bg-nav shadow-lg fixed bottom-0 md:relative pt-5 z-10 h-screen w-full md:w-48">
        <h5 class="pl-2 pb-2 text-sm text-gray-100 uppercase">System</h5>
        <ul class="list-reset pb-10">
            <router-link tag="li" class="py-3 pl-5 text-nav cursor-pointer" to="/"><i class="pr-2 fas fa-home"></i> Home</router-link>
            <router-link tag="li" class="py-3 pl-5 text-nav cursor-pointer" to="/bots"><i class="pr-2 fas fa-robot"></i> Bots</router-link>
            <router-link tag="li" class="py-3 pl-5 text-nav cursor-pointer" to="/attributes"><i class="pr-2 fas fa-database"></i> Attributes</router-link>
            <router-link tag="li" class="py-3 pl-5 text-nav cursor-pointer" to="/integrations"><i class="pr-2 fas fa-bezier-curve"></i> Integrations</router-link>
        </ul>
        <h5 class="pl-2 pb-2 text-sm text-white uppercase" v-if="this.current_bot_id !== null">Current Bot</h5>
        <ul class="list-reset pb-10" v-if="this.current_bot_id !== null">
            <router-link tag="li" class="py-3 pl-5 text-nav cursor-pointer" to="/engagements"><i class="pr-2 fas fa-bullseye"></i> Engagements</router-link>
            <router-link tag="li" class="py-3 pl-5 text-nav cursor-pointer" to="/segments"><i class="pr-2 fas fa-list"></i> Segments</router-link>
            <router-link tag="li" class="py-3 pl-5 text-nav cursor-pointer" to="/sequences"><i class="pr-2 fas fa-sort-amount-down"></i> Sequences</router-link>
            <router-link tag="li" class="py-3 pl-5 text-nav cursor-pointer" to="/webforms"><i class="pr-2 fab fa-wpforms"></i> Webforms</router-link>
            <router-link tag="li" class="py-3 pl-5 text-nav cursor-pointer" to="/broadcasts"><i class="pr-2 fas fa-bullhorn"></i> Broadcasts</router-link>
            <router-link tag="li" class="py-3 pl-5 text-nav cursor-pointer" to="/comments"><i class="pr-2 fas fa-comments"></i> Comments</router-link>
            <router-link tag="li" class="py-3 pl-5 text-nav cursor-pointer" to="/inbox"><i class="pr-2 fas fa-inbox"></i> Inbox <span class="text-white font-bold mx-3 py-1 px-2 rounded-full text-sm bg-blue-500" v-if="inbox_count > 0">{{ inbox_count }}</span></router-link>
            <router-link tag="li" class="py-3 pl-5 text-nav cursor-pointer" to="/reports"><i class="pr-2 fas fa-chart-bar"></i> Reports</router-link>
            <router-link tag="li" class="py-3 pl-5 text-nav cursor-pointer" to="/people"><i class="pr-2 fas fa-user-check"></i> People</router-link>
        </ul>
      </div>

      <div class="main-content flex-1 bg-body pt-5 pb-24">
          <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

    import axios from 'axios';
    import store from '../../store'

    export default {
        name: 'layout-app',
        created() {
            this.fetchBots();
            this.fetchUser();
            this.fetchInboxCount();
        },
        mounted() {
            this.$root.$on('bots_updated', () => {
                this.fetchBots();
            });
        },
        data() {
            return {
                bots: [],
                user: {
                    id: null,
                    first_name: null,
                    last_name: null
                },
                current_bot_id: null,
                is_loaded: false,
                inbox_count: 0,
                portal_url: process.env.MIX_PORTAL_URL,
                help_url: process.env.MIX_HELP_URL,
                support_url: process.env.MIX_SUPPORT_URL
            }
        },
        methods: {
            fetchBots() {
                axios.get(this.botsUrl()).then(this.refreshBots);
            },
            botsUrl() {
                return `/api/v1/bots`;
            },
            refreshBots({data}) {
                this.bots = data.data;

                if (this.bots.length > 0) {
                    if (this.$store.getters.current_bot_id !== null && this.$store.getters.current_bot_id !== '' && this.$store.getters.current_bot_id !== undefined) {
                        if (this.bots.find( ({ id }) => id === this.$store.getters.current_bot_id ) !== undefined) {
                            this.current_bot_id = this.$store.getters.current_bot_id;
                        } else {
                            this.current_bot_id = this.bots[0].id;
                            this.$store.commit('updateBotID', this.current_bot_id);
                        }
                    } else {
                        this.current_bot_id = this.bots[0].id;
                        this.$store.commit('updateBotID', this.current_bot_id);
                    }
                } else {
                    this.current_bot_id = null;
                    this.$store.commit('updateBotID', null);
                }
            },
            fetchInboxCount() {
                if (this.$store.getters.current_bot_id) {
                    axios.get(`/api/v1/inbox/count?fb_bot_id=${this.$store.getters.current_bot_id}`).then(this.refreshInboxCount);
                }
            },
            refreshInboxCount({data}) {
                this.inbox_count = data.data;
            },
            updateBot() {
                this.$store.commit('updateBotID', this.current_bot_id);
                window.location = '/';
            },
            fetchUser() {
                axios.get(this.userUrl()).then(this.refreshUser);
            },
            userUrl() {
                return `/api/v1/me`;
            },
            refreshUser({data}) {
                this.user = data.data;
                this.is_loaded = true;
            },
            toggleUserDropdown() {
                document.getElementById('user_dropdown').classList.toggle("invisible");
            },
            logoutUser() {
                this.$store.dispatch('logout')
                    .then(() => {
                        window.location = process.env.MIX_PORTAL_LOGIN_URL
                    })
                    .catch(err => console.log(err))
            }
        }
    }
</script>
