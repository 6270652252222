<template>
  <div id="app">
    <component :is="layout">
        <router-view :layout.sync="layout"/>
    </component>
    <modals-container/>
    <notifications group="alerts" />
  </div>
</template>

<script>

export default {
  name: 'app',
  data() {
    return {
      layout: 'div',
    };
  },
  created: function () {
    let store = this.$store;
    let root = this;

    this.$http.interceptors.response.use(undefined, function (response) {
        if (response.response.status === 401 && response.config && !response.config.__isRetryRequest) {
          store.dispatch('logout')
          root.logout()

          throw response;
        } else if (response.response.status === 403 && response.config && !response.config.__isRetryRequest) {
          notify({
              group: 'alerts',
              type: 'error',
              title: (response.response.data.error || response.response.data.message || 'An error has occurred'),
              duration: 5000,
              speed: 1000
          });

          throw response;
        } else if(response.response.status >= 400) {
          throw response;
        }

        return response;
    });
  },
  computed : {
    isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout')
      .then(() => {
        window.location = process.env.MIX_PORTAL_LOGIN_URL + '?redirect=' + window.location;
      })
    }
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
