<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Update Comment Match</h1>
        </div>
        <div class="m-5 bg-white">
          <h2 class="p-4">Comment Match Details</h2>
          <div class="bg-white shadow-md rounded my-6 p-4">
            <div class="w-full">

                <!-- Row One -->
                <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="form-label" for="name">Name</label>
                        <input class="form-text" id="name" type="text" v-model="comment.name">
                        <p class="text-red-500 text-xs italic" v-if="errors && errors.name">{{ errors.name[0] }}</p>
                    </div>
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="form-label" for="status">Status</label>
                        <div class="relative">
                            <select class="form-select" id="status" name="status" v-model="comment.status">
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                            <div class="form-select-arrow">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                        <p class="text-red-500 text-xs italic" v-if="errors && errors.status">{{ errors.status[0] }}</p>
                    </div>
                </div>

                <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="form-label" for="type">Type</label>
                        <div class="relative">
                            <select class="form-select" id="type" name="type" v-model="comment.type">
                                <option value="post">Post</option>
                                <option value="comment">Comment</option>
                            </select>
                            <div class="form-select-arrow">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                        <p class="text-red-500 text-xs italic" v-if="errors && errors.status">{{ errors.status[0] }}</p>
                    </div>
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label class="form-label" for="fb_sequence_id">Trigger Sequence</label>
                        <div class="relative pb-3">
                            <select class="form-select" id="fb_sequence_id" name="fb_sequence_id" v-model="comment.fb_sequence_id">
                                <option :value="null">- None -</option>
                                <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                        </div>
                        <p class="text-red-500 text-xs italic" v-if="errors && errors.fb_sequence_id">{{ errors.fb_sequence_id[0] }}</p>
                    </div>
                </div>
            </div>
          </div>
        </div>

        <div class="m-5 bg-white shadow-md rounded">
            <h2 class="p-4">Matches</h2>
            <div class="bg-white my-6 p-4">
                <div class="w-full">
                    <!-- Row 1 -->
                    <div class="flex flex-wrap -mx-3 mb-6" v-for="(match, index) in comment.matches" v-bind:key="match.id">
                        <div class="w-full md:w-2/5 px-3 mb-6 md:mb-0">
                            <label class="form-label" :for="'match_keyword_'+index">Keyword/Phrase</label>
                            <input class="form-text" :id="'match_keyword_'+index" name="'match_keyword_'+index" type="text" v-model="comment.matches[index].keyword">
                            <validation-notice :param="'matches.'+index+'.keyword'" :errors="errors"></validation-notice>
                        </div>
                        <div class="w-full md:w-2/5 px-3 mb-6 md:mb-0">
                            <label class="form-label" :for="'match_rule_'+index">Rule</label>
                            <div class="relative">
                                <select class="form-select" :id="'match_rule_'+index" :name="'match_rule_'+index" v-model="comment.matches[index].rule">
                                    <option value="exact">Exact Match</option>
                                    <option value="start">Starts With</option>
                                    <option value="contain">Contains</option>
                                </select>
                                <div class="form-select-arrow">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>
                            <validation-notice :param="'matches.'+index+'.rule'" :errors="errors"></validation-notice>
                        </div>
                        <div class="w-full md:w-1/5 cursor-pointer h-full pt-6 pl-6 text-right">
                            <button class="btn btn-alert" @click="removeMatch(index)"><i class="fas fa-minus-circle"></i> REMOVE</button>
                        </div>
                    </div>

                    <div class="flex flex-wrap mx-3 mb-6">
                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-3" @click="addMatch()"><i class="fas fa-plus-circle"></i> ADD</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-wrap m-5 px-3 pt-3">
            <button class="btn btn-primary" v-on:click="save">
                Update Comment Match
            </button>

            <button class="btn btn-secondary" v-on:click="cancel">
                Cancel
            </button>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'
    import Vue from 'vue'
    import ValidationNotice from '../../shared/ValidationNotice';

    export default {
        name: 'UpdateComment',
        components: {
            LayoutApp,
            ValidationNotice,
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetchSequences();
            this.fetchComment();
        },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        data() {
            return {
                sequences: [],
                comment: {
                    name: '',
                    fb_bot_id: this.$store.getters.current_bot_id,
                    type: 'post',
                    fb_sequence_id: null,
                    status: 'active',
                    matches: [{
                        'keyword': '',
                        'rule': 'exact'
                    }]
                },
                errors: []
            }
        },
        methods: {
            fetchSequences() {
                axios.get(this.sequencesUrl()).then(this.refreshSequences);
            },
            sequencesUrl() {
                return `/api/v1/sequences?fb_bot_id=${this.comment.fb_bot_id}`;
            },
            refreshSequences({
                data
            }) {
                this.sequences = data.data;
            },
            fetchComment() {
                axios.get(this.commentsUrl()).then(this.refreshComment);
            },
            commentsUrl() {
                return `/api/v1/comments/${this.id}`;
            },
            refreshComment({
                data
            }) {
                this.comment = data.data;
            },
            save() {
              axios.put('/api/v1/comments/'+this.id, this.comment).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The comment match has been updated',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_comments'});
              }).catch(error => {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
                } else {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while updating the comment match',
                        duration: 5000,
                        speed: 1000
                    });
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            addMatch() {
                this.comment.matches.push({
                    name: '',
                    rule: 'exact',
                });
            },
            removeMatch(index) {
                Vue.delete(this.comment.matches, index);
            },
        }
    }
</script>
