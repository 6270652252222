<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Viewing {{ person.first_name }} {{ person.last_name }}</h1>
            <!--<a class="mr-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" v-if="person.handover_primary === 1" @click="handover()" href="javascript:void(0);">
                Handover to Inbox
            </a>
            <a class="mr-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" v-if="person.handover_primary === 0" @click="takeover()" href="javascript:void(0);">
                Takeover from Inbox
            </a>-->
        </div>
        <div class="flex mb-4">
            <div class="w-1/5">
                <div class="m-5 bg-white shadow-md rounded my-6 p-2">
                    <h5>Segments</h5>
                    <div class="flex p-2" v-for="segment in person.segments" v-bind:key="segment.id">
                        <div class="w-5/6 text-left text-sm text-left">{{ segment.name }}<br /><span class="text-xs text-gray-700">{{ segment.pivot.added_at | formatDate }}</span></div>
                        <div class="w-1/6 text-gray-700 text-right text-sm text-right" @click="confirmRemoveFromSegment(segment.id)"><i class="fas fa-trash"></i></div>
                    </div>
                    <button @click="addToSegmentDialog()" class="m-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Add</button>
                </div>

                <div class="m-5 bg-white shadow-md rounded my-6 p-2">
                    <h5>Attributes</h5>
                    <div class="flex p-2" v-for="attribute in person.attributes" v-bind:key="attribute.id">
                        <div class="w-5/6 text-left text-sm text-left">{{ attribute.name }}<br /><span class="text-sm text-gray-700">{{ attribute.pivot.value }}</span></div>
                        <div class="w-1/6 text-gray-700 text-right text-sm text-right" v-if="attribute.scope !== 'system'" @click="confirmRemoveAttribute(attribute.id)"><i class="fas fa-trash"></i></div>
                    </div>
                    <button @click="addAttributeDialog()" class="m-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Add</button>
                </div>
            </div>
            <div class="w-4/5">
                <div class="m-5 bg-white shadow-md rounded my-6 p-2">
                    <h5>Messages</h5>

                    <div class="flex p-2 font-bold">
                        <div class="flex-1 text-left text-sm text-left pr-2">Message</div>
                        <div class="flex-1 text-gray-700 text-left text-sm text-left pr-2">In/Out</div>
                        <div class="flex-1 text-gray-700 text-left text-sm text-left pr-2">Message Type</div>
                        <div class="flex-1 text-gray-700 text-left text-sm text-left">Date Sent</div>
                    </div>

                    <div class="flex p-2" v-for="message in messages" v-bind:key="message.id">
                        <div class="flex-1 text-left text-sm text-left pr-2 truncate" v-if="message.attachments === null">{{ message.text }}</div>
                        <div class="flex-1 text-left text-sm text-left pr-2 flex flex-row" v-if="message.attachments !== null">
                            <div v-for="(attachment, attachment_index) in message.attachments" v-bind:key="attachment_index" >
                                <img :src="attachment.payload.url" width="60" height="60" class="pr-2" v-if="attachment.type === 'image'" />
                                <audio controls :src="attachment.payload.url" class="pr-2" v-if="attachment.type === 'audio'"></audio>
                                <video controls width="70%" :src="attachment.payload.url" class="pr-2" v-if="attachment.type === 'video'"></video>
                            </div>
                        </div>
                        <div class="flex-1 text-gray-700 text-left text-sm text-left pr-2">{{ message.type | capitalize }}</div>
                        <div class="flex-1 text-gray-700 text-left text-sm text-left pr-2">{{ message.internal_type | capitalize }}</div>
                        <div class="flex-1 text-gray-700 text-left text-sm text-left">{{ message.created_at | formatDate }}</div>
                    </div>
                </div>

                <paginate
                    :page-count="message_pages"
                    :click-handler="fetchMessages"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'pagination-page'"
                    :prev-class="'pagination-prev'"
                    :next-class="'pagination-next'"
                    :disabled-class="'pagination-inactive'">
                </paginate>
            </div>
        </div>

        <modal name="add-segment" class="confirm-modal" :height="240">
            <div class="modal-container">
                <div class="modal-content">
                    <div class="modal-c-title">Add Segment</div>
                    <div class="modal-c-text">
                        Select a segment below to add the user.
                    </div>
                    <div class="modal-c-text">
                        <div class="relative">
                            <select class="form-select" id="segment_id" name="segment_id" v-model="add_segment_id">
                                <option :value="null">- Select Segment -</option>
                                <option v-for="segment in segments" v-bind:key="segment.id" :value="segment.id">{{ segment.name }}</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                        </div>
                        <p class="text-red-500 text-xs italic" v-if="segment_errors && segment_errors.segment_id">{{ segment_errors.segment_id[0] }}</p>
                    </div>
                </div>
                <div class="modal-buttons">
                    <button type="button" class="modal-button" @click="$modal.hide('add-segment')">Cancel</button>
                    <button type="button" class="modal-button-primary" @click="addSegment()">Add</button>
                </div>
            </div>
        </modal>

        <modal name="remove-segment" class="confirm-modal" :height="220">
            <div class="modal-container">
                <div class="modal-content">
                    <div class="modal-c-title">Remove Segment</div>
                    <div class="modal-c-text">Are you sure you want to remove this segment from this person?  This action cannot be undone.</div>
                </div>
                <div class="modal-buttons">
                    <button type="button" class="modal-button" @click="$modal.hide('remove-segment')">Cancel</button>
                    <button type="button" class="modal-button-primary" @click="removeSegment()">Delete</button>
                </div>
            </div>
        </modal>

        <modal name="add-attribute" class="confirm-modal" :height="300">
            <div class="modal-container">
                <div class="modal-content">
                    <div class="modal-c-title">Add Attribute</div>
                    <div class="modal-c-text">
                        Select an attribute below with a value to add the user.
                    </div>
                    <div class="modal-c-text">
                        <div class="relative">
                            <select class="form-select" id="attribute" name="attribute" v-model="add_attribute">
                                <option :value="null">- Select Attribute -</option>
                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute">{{ attribute.name }}</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                        </div>
                        <p class="text-red-500 text-xs italic" v-if="attribute_errors && attribute_errors.attribute_id">{{ attribute_errors.attribute_id[0] }}</p>
                    </div>
                    <div class="modal-c-text mt-2">
                        <input class="form-text" v-model="add_attribute_value" v-if="add_attribute && add_attribute.values_type === 'open'">
                        <div class="relative" v-if="add_attribute && add_attribute.values_type === 'predefined'">
                            <select class="form-select" id="add_attribute_select" name="add_attribute_select" v-model="add_attribute_value">
                                <option :value="null" disabled>- Select Value -</option>
                                <option v-for="(value, value_index) in add_attribute.valid_values.values" v-bind:key="value_index" :value="value.value">{{ value.label }}</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                        </div>
                        <p class="text-red-500 text-xs italic" v-if="attribute_errors && attribute_errors.value">{{ attribute_errors.value[0] }}</p>
                    </div>
                </div>
                <div class="modal-buttons">
                    <button type="button" class="modal-button" @click="$modal.hide('add-attribute')">Cancel</button>
                    <button type="button" class="modal-button-primary" @click="addAttribute()">Add</button>
                </div>
            </div>
        </modal>

        <modal name="remove-attribute" class="confirm-modal" :height="220">
            <div class="modal-container">
                <div class="modal-content">
                    <div class="modal-c-title">Remove Attribute</div>
                    <div class="modal-c-text">Are you sure you want to remove this attribute from this person?  This action cannot be undone.</div>
                </div>
                <div class="modal-buttons">
                    <button type="button" class="modal-button" @click="$modal.hide('remove-attribute')">Cancel</button>
                    <button type="button" class="modal-button-primary" @click="removeAttribute()">Delete</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'

    export default {
        name: 'ViewPerson',
        components: {
            LayoutApp
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetch();
            this.fetchMessages(1);
            this.fetchSegments();
            this.fetchAttributes();
        },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        data() {
            return {
                person: {
                    id: '',
                    first_name: '',
                    last_name: '',
                    added_at: '',
                    source: '',
                    handover_primary: '',
                    segments: [],
                    attributes: []
                },
                messages: [],
                message_pages: 1,
                attributes: [],
                segments: [],
                add_segment_id: null,
                add_attribute: null,
                add_attribute_value: '',
                remove_segment_id: null,
                remove_attribute_id: null,
                attribute_errors: [],
                segment_errors: [],
            }
        },
        methods: {
            fetch() {
                axios.get(this.url()).then(this.refresh);
            },
            url() {
                return `/api/v1/people/${this.id}?fb_bot_id=${this.$store.getters.current_bot_id}`;
            },
            refresh({data}) {
                this.person = data.data;
                window.scrollTo(0,0);
            },
            fetchSegments() {
                axios.get(this.segmentsUrl()).then(this.refreshSegments);
            },
            segmentsUrl() {
                return `/api/v1/segments?fb_bot_id=${this.$store.getters.current_bot_id}&search_types=static`;
            },
            refreshSegments({data}) {
                this.segments = data.data;
            },
            fetchAttributes() {
                axios.get(this.attributesUrl()).then(this.refreshAttributes);
            },
            attributesUrl() {
                return `/api/v1/attributes?per_page=-1&search_scopes=client`;
            },
            refreshAttributes({data}) {
                this.attributes = data.data;
            },
            fetchMessages(page) {
                axios.get(this.messagesUrl(page)).then(this.refreshMessages);
            },
            messagesUrl(page) {
                return `/api/v1/people/${this.id}/messages?page=${page}&fb_bot_id=${this.$store.getters.current_bot_id}`;
            },
            refreshMessages({data}) {
                this.messages = data.data;
                this.message_pages = data.meta.pages;
                window.scrollTo(0,0);
            },
            addToSegmentDialog() {
                this.$modal.show('add-segment');
            },
            addSegment() {
                axios.post(`/api/v1/people/${this.id}/segments/${this.add_segment_id}`).then(this.postSegmentAdd).catch(error => {
                    if (error.response.status === 422) {
                        this.segment_errors = error.response.data.errors || {};
                    } else {
                        this.$notify({
                            group: 'alerts',
                            type: 'error',
                            title: 'An error occurred while associating the segment',
                            duration: 5000,
                            speed: 1000
                        });
                    }
                });
            },
            postSegmentAdd() {
                this.$modal.hide('add-segment');
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The segment has been added',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch();
            },

            confirmRemoveFromSegment(segment_id) {
                this.remove_segment_id = segment_id;

                this.$modal.show('remove-segment');
            },
            removeSegment() {
                axios.delete(`/api/v1/people/${this.id}/segments/${this.remove_segment_id}`).then(this.postSegmentRemove);
            },
            postSegmentRemove() {
                this.$modal.hide('remove-segment');
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The segment has been removed',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch();
            },


            addAttributeDialog() {
                this.$modal.show('add-attribute');
            },
            addAttribute() {
                axios.post(`/api/v1/people/${this.id}/attributes/${this.add_attribute.id}`, {value: this.add_attribute_value}).then(this.postAttributeAdd).catch(error => {
                    if (error.response.status === 422) {
                        this.attribute_errors = error.response.data.errors || {};
                    } else {
                        this.$notify({
                            group: 'alerts',
                            type: 'error',
                            title: 'An error occurred while associating the attribute',
                            duration: 5000,
                            speed: 1000
                        });
                    }
                });
            },
            postAttributeAdd() {
                this.$modal.hide('add-attribute');
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The attribute has been added',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch();
            },


            confirmRemoveAttribute(attribute_id) {
                this.remove_attribute_id = attribute_id;
                this.$modal.show('remove-attribute');
            },
            removeAttribute() {
                axios.delete(`/api/v1/people/${this.id}/attributes/${this.remove_attribute_id}`).then(this.postAttributeRemove);
            },
            postAttributeRemove() {
                this.$modal.hide('remove-attribute');
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The attribute has been removed',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch();
            },
            handover() {
                axios.post(`/api/v1/people/${this.id}/handover`).then(this.fetch).catch(error => {
                    if (error.response.status === 422) {
                        this.attribute_errors = error.response.data.errors || {};
                    } else {
                        this.$notify({
                            group: 'alerts',
                            type: 'error',
                            title: 'An error occurred while handing over to the Inbox',
                            duration: 5000,
                            speed: 1000
                        });
                    }
                });
            },
            takeover() {
                axios.post(`/api/v1/people/${this.id}/takeover`).then(this.fetch).catch(error => {
                    if (error.response.status === 422) {
                        this.attribute_errors = error.response.data.errors || {};
                    } else {
                        this.$notify({
                            group: 'alerts',
                            type: 'error',
                            title: 'An error occurred while taking over from the Inbox',
                            duration: 5000,
                            speed: 1000
                        });
                    }
                });
            },
        }
    }
</script>
