<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Create New Attribute</h1>
        </div>
        <div class="flex flex-row">
            <div class="flex-grow">
                <div class="m-5 bg-white shadow-md rounded">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Attribute Details</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('details')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="bg-white my-6 p-4">
                    <div class="w-full">

                        <!-- Row One -->
                        <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="form-label" for="name">Name</label>
                            <input class="form-text" id="name" type="text" v-model="attribute.name">
                            <p class="text-red-500 text-xs italic" v-if="errors && errors.name">{{ errors.name[0] }}</p>
                        </div>
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="form-label" for="status">Status</label>
                            <div class="relative">
                            <select class="form-select" id="status" name="status" v-model="attribute.status">
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                            </div>
                            <p class="text-red-500 text-xs italic" v-if="errors && errors.status">{{ errors.status[0] }}</p>
                        </div>
                        </div>

                        <!-- Row Two -->
                        <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="form-label" for="type">Type</label>
                            <div class="relative">
                            <select class="form-select" id="type" name="type" v-model="attribute.type" @change="updateType()">
                                <option value="string">String</option>
                                <option value="alpha_only">String (alpha only)</option>
                                <option value="numeric_only">String (numeric only)</option>
                                <option value="alphanumeric_only">String (alphanumeric only)</option>
                                <option value="email">Email</option>
                                <option value="phone">Phone</option>
                                <option value="zip">ZIP</option>
                                <option value="zip_plusfour">ZIP + 4</option>
                                <option value="boolean">Yes/No</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                            </div>
                            <p class="text-red-500 text-xs italic" v-if="errors && errors.type">{{ errors.type[0] }}</p>
                        </div>
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="form-label" for="length">Length</label>
                            <input class="form-text" id="length" type="length" v-model="attribute.length" :disabled="disable_length">
                            <p class="text-red-500 text-xs italic" v-if="errors && errors.length">{{ errors.length[0] }}</p>
                        </div>
                        </div>

                        <!-- Row Three -->
                        <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="form-label" for="values_type">Values</label>
                            <div class="relative">
                            <select class="form-select" id="values_type" name="values_type" v-model="attribute.values_type" :disabled="disable_values_type">
                                <option value="open">Open</option>
                                <option value="predefined">Predefined</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                            </div>
                            <p class="text-red-500 text-xs italic" v-if="errors && errors.values_type">{{ errors.values_type[0] }}</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="m-5 bg-white shadow-md rounded" v-if="attribute.values_type === 'predefined'">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Predefined Values</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('predefined_values')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="pl-4">
                        <p class="text-red-500 text-xs italic" v-if="errors && errors.valid_values">{{ errors.valid_values[0] }}</p>
                        <p class="text-red-500 text-xs italic" v-if="errors && errors['valid_values.values']">{{ errors['valid_values.values'][0] }}</p>
                    </div>
                    <div class="bg-white my-6 p-4">
                        <div class="w-full">
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0"><label class="form-label" for="name">Value</label></div>
                                <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0"><label class="form-label" for="name">Label</label></div>
                                <div class="w-full md:w-1/5 cursor-pointer h-full pl-6 text-left"></div>
                            </div>
                            <div class="flex flex-wrap -mx-3 mb-6" v-for="(value, index) in attribute.valid_values.values" v-bind:key="index">
                                <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                    <input class="form-text" :id="'valid_values_'+index" :name="'valid_values_'+index" type="text" v-model="attribute.valid_values.values[index].value">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors['valid_values.values.'+index+'.value']">{{ errors['valid_values.values.'+index+'.value'][0] }}</p>
                                </div>
                                <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                    <input class="form-text" :id="'valid_values_'+index" :name="'valid_values_'+index" type="text" v-model="attribute.valid_values.values[index].label">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors['valid_values.values.'+index+'.label']">{{ errors['valid_values.values.'+index+'.label'][0] }}</p>
                                </div>
                                <div class="w-full md:w-1/5 cursor-pointer h-full pl-6 text-left">
                                    <button class="btn btn-alert" @click="removeValidValue(index)"><i class="fas fa-minus-circle"></i> REMOVE</button>
                                </div>
                            </div>

                            <div class="flex flex-wrap mx-3 mb-6">
                                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-3" @click="addValidValue()"><i class="fas fa-plus-circle"></i> ADD</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-wrap m-5 px-3 pt-3">
                    <button class="btn btn-primary" v-on:click="save">
                        Create Attribute
                    </button>

                    <button class="btn btn-secondary" v-on:click="cancel">
                        Cancel
                    </button>
                </div>
            </div>

            <div class="w-64" v-if="show_help_section">
                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'details'">
                    <h2 class="p-4">Attribute Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Attributes help to identify and categorize people that chat with the system.</p>
                            <p class="text-xs italic pb-2"><strong>Name</strong> - Provide a descriptive, unique name for the attribute to help identify it for future edits and use.  Example: Email Address.</p>
                            <p class="text-xs italic pb-2"><strong>Type</strong> - Type helps to define the format of the data accepted for this attribute. Specific types, like email, help enforce validation via webforms and collect attribute input.</p>
                            <p class="text-xs italic pb-2"><strong>Length</strong> - Available for types that don't have a length predefined, such as ZIP codes.  This will be the number of characters allowed for the field.</p>
                            <p class="text-xs italic pb-2"><strong>Values</strong> - Available for String Types only.  Open allows any value to be used (that matches the validation rules set by the Type), Predefined allows a listing of values to be set
                             and must match when being set (all values will be converted to lower case for matching).</p>
                        </div>
                    </div>
                </div>

                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'predefined_values'">
                    <h2 class="p-4">Predefined Value Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Listing of all possible values the attribute will allow. If a value is provided that does not match any values in this list, it will not be accepted.</p>
                            <p class="text-xs italic pb-2"><strong>Value</strong> - The actual stored value for the attribute.</p>
                            <p class="text-xs italic pb-2"><strong>Label</strong> - The label displayed when selecting a this value from a listing (such as in the admin UI or from a webform field).</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App';
    import Vue from 'vue'

    export default {
        name: 'CreateAttribute',
        components: {
            LayoutApp
        },
        created() {
            this.$emit('update:layout', LayoutApp);
        },
        data() {
            return {
                attribute: {
                  name: '',
                  type: 'string',
                  length: 0,
                  values_type: 'open',
                  valid_values: {
                      values: []
                  },
                  status: 'active'
                },
                disable_values_type: false,
                disable_length: false,
                errors: [],
                show_help_section: null,
            }
        },
        methods: {
            save() {
              axios.post('/api/v1/attributes', this.attribute).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The attribute has been created',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_attributes'});
              }).catch(error => {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
                } else {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred creating the attribute',
                        duration: 5000,
                        speed: 1000
                    });
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            addValidValue() {
                this.attribute.valid_values.values.push({
                    value: '',
                    label: '',
                });
            },
            removeValidValue(index) {
                Vue.delete(this.attribute.valid_values.values, index);
            },
            updateType() {
                if (this.attribute.type === 'email' || this.attribute.type === 'phone' || this.attribute.type === 'zip' || this.attribute.type === 'zip_plusfour' || this.attribute.type === 'boolean') {
                    this.attribute.values_type = 'open';
                    this.attribute.valid_values = {values: []};
                    this.disable_values_type = true;
                    this.disable_length = false;
                } else {
                    this.disable_values_type = false;
                    this.disable_length = false;
                }

                if (this.attribute.type === 'zip') {
                    this.attribute.length = 5;
                    this.disable_length = true;
                }

                if (this.attribute.type === 'zip_plusfour') {
                    this.attribute.length = 4;
                    this.disable_length = true;
                }

                if (this.attribute.type === 'boolean') {
                    this.attribute.length = 3;
                    this.disable_length = true;
                }
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }
</script>
