<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Edit Engagement</h1>
        </div>
        <div class="flex flex-row">
            <div class="flex-grow">
                <div class="m-5 bg-white shadow-md rounded">
                    <h2 class="p-4">Engagement Details</h2>
                    <div class="bg-white my-6 p-4">
                        <div class="w-full">
                            <!-- Row One -->
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="name">Name</label>
                                    <input class="form-text" id="name" type="text" v-model="engagement.name">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.name">{{ errors.name[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="status">Status</label>
                                    <div class="relative">
                                        <select class="form-select" id="status" name="status" v-model="engagement.status">
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.status">{{ errors.status[0] }}</p>
                                </div>
                            </div>

                            <!-- Row Two -->
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="type">Type</label>
                                    <div class="relative">
                                        <select class="form-select" id="type" name="type" v-model="engagement.type">
                                            <option value="fb_url">Facebook m.me URL</option>
                                            <option value="fb_chat_widget">Facebook Chat Widget</option>
                                            <option value="fb_checkbox">Facebook Checkbox</option>
                                            <option value="fb_send_button">Facebook Send to Messenger Button</option>
                                            <option value="fb_message_button">Facebook Message Us Button</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.status">{{ errors.status[0] }}</p>
                                </div>

                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0" v-if="engagement.type === 'fb_url'">
                                    <label class="form-label" for="type">URL</label>
                                    <input class="form-text" id="name" type="text" v-model="fb_url" readonly>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="m-5 bg-white shadow-md rounded">
                    <h2 class="p-4">Engagement Type Settings</h2>
                    <div class="bg-white my-6 p-4">
                        <p class="text-red-500 text-xs italic" v-for="(error, index) in errors.params" v-bind:key="index">{{ error }}</p>

                        <!-- FB URL -->
                        <div class="w-full" v-if="engagement.type === 'fb_url'">
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="name">Ref Key</label>
                                    <input class="form-text" id="name" type="text" v-model="engagement.fb_ref">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.fb_ref">{{ errors.fb_ref[0] }}</p>
                                </div>
                            </div>

                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="fb_attribute_id">Attribute</label>
                                    <div class="relative">
                                        <select class="form-select" id="fb_attribute_id" name="fb_attribute_id" v-model="engagement.params.fb_attribute_id">
                                            <option :value="null">- Select Attribute -</option>
                                            <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.fb_attribute_id">{{ errors.fb_attribute_id[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="fb_attribute_value">Attribute Value</label>
                                    <input class="form-text" id="fb_attribute_value" name="fb_attribute_value" type="text" v-model="engagement.params.fb_attribute_value">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.fb_attribute_value">{{ errors.params.fb_attribute_value[0] }}</p>
                                </div>
                            </div>

                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="fb_sequence_id">Trigger Sequence</label>
                                    <div class="relative">
                                        <select class="form-select" id="fb_sequence_id" name="fb_sequence_id" v-model="engagement.params.fb_sequence_id">
                                            <option :value="null">- Select Sequence -</option>
                                            <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.fb_sequence_id">{{ errors.fb_sequence_id[0] }}</p>
                                </div>
                            </div>
                        </div>

                        <!-- FB Chat Widget -->
                        <div class="w-full" v-if="engagement.type === 'fb_chat_widget'">
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="name">Ref Key</label>
                                    <input class="form-text" id="name" type="text" v-model="engagement.fb_ref">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.fb_ref">{{ errors.fb_ref[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="theme_color">Theme Color</label>
                                    <v-swatches
                                        v-model="engagement.params.theme_color"
                                        show-fallback
                                        fallback-input-type="color"
                                    ></v-swatches>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.theme_color">{{ errors.params.theme_color[0] }}</p>
                                </div>
                            </div>


                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="greeting_dialog_delay">Greeting Delay</label>
                                    <input class="form-text" id="greeting_dialog_delay" type="number" min="0" max="10" v-model="engagement.params.greeting_dialog_delay">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.greeting_dialog_delay">{{ errors.params.greeting_dialog_delay[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="greeting_dialog_display">Greeting Display</label>
                                    <div class="relative">
                                        <select class="form-select" name="greeting_dialog_display" id="greeting_dialog_display" v-model="engagement.params.greeting_dialog_display">
                                            <option value="show">Show</option>
                                            <option value="fade">Fade</option>
                                            <option value="hide">Hide</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.greeting_dialog_display">{{ errors.params.greeting_dialog_display[0] }}</p>
                                </div>
                            </div>

                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="logged_in_greeting">Greeting Message (logged in user)</label>
                                    <textarea class="form-text" id="logged_in_greeting" v-model="engagement.params.logged_in_greeting"></textarea>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.logged_in_greeting">{{ errors.params.logged_in_greeting[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="logged_out_greeting">Greeting Message (logged out user)</label>
                                    <textarea class="form-text" id="logged_out_greeting" type="text" v-model="engagement.params.logged_out_greeting"></textarea>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.logged_out_greeting">{{ errors.params.logged_out_greeting[0] }}</p>
                                </div>
                            </div>

                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="optin_message">Opt-In Message</label>
                                    <textarea class="form-text" id="optin_message" v-model="engagement.params.optin_message"></textarea>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.optin_message">{{ errors.params.optin_message[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="optin_button">Opt-In Button</label>
                                    <input type="text" class="form-text" id="optin_button" v-model="engagement.params.optin_button" />
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.optin_button">{{ errors.params.optin_button[0] }}</p>
                                </div>
                            </div>

                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="fb_attribute_id">Attribute</label>
                                    <div class="relative">
                                        <select class="form-select" name="fb_attribute_id" id="fb_attribute_id" v-model="engagement.params.fb_attribute_id">
                                            <option :value="null">- Select Attribute -</option>
                                            <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.fb_attribute_id">{{ errors.fb_attribute_id[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="fb_attribute_value">Attribute Value</label>
                                    <input class="form-text" id="fb_attribute_value" name="fb_attribute_value" type="text" v-model="engagement.params.fb_attribute_value">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.fb_attribute_value">{{ errors.params.fb_attribute_value[0] }}</p>
                                </div>
                            </div>

                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="fb_sequence_id">Trigger Sequence</label>
                                    <div class="relative">
                                        <select class="form-select" id="fb_sequence_id" name="fb_sequence_id" v-model="engagement.params.fb_sequence_id">
                                            <option :value="null">- Select Sequence -</option>
                                            <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.fb_sequence_id">{{ errors.fb_sequence_id[0] }}</p>
                                </div>
                            </div>
                        </div>

                        <!-- FB Checkbox -->
                        <div class="w-full" v-if="engagement.type === 'fb_checkbox'">
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="name">Ref Key</label>
                                    <input class="form-text" id="name" type="text" v-model="engagement.fb_ref">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.fb_ref">{{ errors.fb_ref[0] }}</p>
                                </div>
                            </div>

                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="size">Size</label>
                                    <div class="relative">
                                        <select class="form-select" name="size" id="size" v-model="engagement.params.size">
                                            <option value="small">Small</option>
                                            <option value="medium">Medium</option>
                                            <option value="standard">Standard</option>
                                            <option value="large">Large</option>
                                            <option value="xlarge">XLarge</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.greeting_display">{{ errors.params.greeting_display[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="skin">Skin</label>
                                    <div class="relative">
                                        <select class="form-select" name="skin" id="skin" v-model="engagement.params.skin">
                                            <option value="light">Light</option>
                                            <option value="dark">Dark</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.skin">{{ errors.params.skin[0] }}</p>
                                </div>
                            </div>

                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="center_align">Alignment</label>
                                    <div class="relative">
                                        <select class="form-select" name="center_align" id="center_align" v-model="engagement.params.center_align">
                                            <option value="false">Left</option>
                                            <option value="true">Center</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.center_align">{{ errors.params.center_align[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="allow_login">Allow Login</label>
                                    <div class="relative">
                                        <select class="form-select" name="allow_login" id="allow_login" v-model="engagement.params.allow_login">
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.allow_login">{{ errors.params.allow_login[0] }}</p>
                                </div>
                            </div>

                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="optin_message">Opt-In Message</label>
                                    <textarea class="form-text" id="optin_message" v-model="engagement.params.optin_message"></textarea>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.optin_message">{{ errors.params.optin_message[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="optin_button">Opt-In Button</label>
                                    <input type="text" class="form-text" id="optin_button" v-model="engagement.params.optin_button" />
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.optin_button">{{ errors.params.optin_button[0] }}</p>
                                </div>
                            </div>

                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="fb_attribute_id">Attribute</label>
                                    <div class="relative">
                                        <select class="form-select" id="fb_attribute_id" name="fb_attribute_id" v-model="engagement.params.fb_attribute_id">
                                            <option :value="null">- Select Attribute -</option>
                                            <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.fb_attribute_id">{{ errors.fb_attribute_id[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="fb_attribute_value">Attribute Value</label>
                                    <input class="form-text" id="fb_attribute_value" name="fb_attribute_value" type="text" v-model="engagement.params.fb_attribute_value">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.fb_attribute_value">{{ errors.params.fb_attribute_value[0] }}</p>
                                </div>
                            </div>

                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="fb_sequence_id">Trigger Sequence</label>
                                    <div class="relative">
                                        <select class="form-select" id="fb_sequence_id" name="fb_sequence_id" v-model="engagement.params.fb_sequence_id">
                                            <option :value="null">- Select Sequence -</option>
                                            <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.fb_sequence_id">{{ errors.fb_sequence_id[0] }}</p>
                                </div>
                            </div>
                        </div>

                        <!-- FB Send to Messenger Button -->
                        <div class="w-full" v-if="engagement.type === 'fb_send_button'">
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="name">Ref Key</label>
                                    <input class="form-text" id="name" type="text" v-model="engagement.fb_ref">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.fb_ref">{{ errors.fb_ref[0] }}</p>
                                </div>
                            </div>

                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="size">Size</label>
                                    <div class="relative">
                                        <select class="form-select" name="size" id="size" v-model="engagement.params.size">
                                            <option value="standard">Standard</option>
                                            <option value="large">Large</option>
                                            <option value="xlarge">XLarge</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.greeting_display">{{ errors.params.greeting_display[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="color">Color</label>
                                    <div class="relative">
                                        <select class="form-select" name="color" id="color" v-model="engagement.params.color">
                                            <option value="blue">Blue</option>
                                            <option value="white">White</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.color">{{ errors.params.color[0] }}</p>
                                </div>
                            </div>

                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="enforce_login">Enforce Login</label>
                                    <div class="relative">
                                        <select class="form-select" name="enforce_login" id="enforce_login" v-model="engagement.params.enforce_login">
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.enforce_login">{{ errors.params.enforce_login[0] }}</p>
                                </div>
                            </div>

                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="fb_attribute_id">Attribute</label>
                                    <div class="relative">
                                        <select class="form-select" id="fb_attribute_id" name="fb_attribute_id" v-model="engagement.params.fb_attribute_id">
                                            <option :value="null">- Select Attribute -</option>
                                            <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.fb_attribute_id">{{ errors.fb_attribute_id[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="fb_attribute_value">Attribute Value</label>
                                    <input class="form-text" id="fb_attribute_value" name="fb_attribute_value" type="text" v-model="engagement.params.fb_attribute_value">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.fb_attribute_value">{{ errors.params.fb_attribute_value[0] }}</p>
                                </div>
                            </div>

                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="fb_sequence_id">Trigger Sequence</label>
                                    <div class="relative">
                                        <select class="form-select" id="fb_sequence_id" name="fb_sequence_id" v-model="engagement.params.fb_sequence_id">
                                            <option :value="null">- Select Sequence -</option>
                                            <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.fb_sequence_id">{{ errors.fb_sequence_id[0] }}</p>
                                </div>
                            </div>
                        </div>

                        <!-- FB Message Us Button -->
                        <div class="w-full" v-if="engagement.type === 'fb_message_button'">
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="size">Size</label>
                                    <div class="relative">
                                        <select class="form-select" name="size" id="size" v-model="engagement.params.size">
                                            <option value="standard">Standard</option>
                                            <option value="large">Large</option>
                                            <option value="xlarge">XLarge</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.greeting_display">{{ errors.params.greeting_display[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="color">Color</label>
                                    <div class="relative">
                                        <select class="form-select" name="color" id="color" v-model="engagement.params.color">
                                            <option value="blue">Blue</option>
                                            <option value="white">White</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.params && errors.params.color">{{ errors.params.color[0] }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-wrap m-5 px-3 pt-3">
                    <button class="btn btn-primary" v-on:click="save">
                        Update Engagement
                    </button>

                    <button class="btn btn-secondary" v-on:click="cancel">
                        Cancel
                    </button>
                </div>
            </div>

            <div class="w-64" v-if="show_help_section">
                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'details'">
                    <h2 class="p-4">Engagement Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Engagements provide a way to use Facebook provided tools for bringing people into bots outside of Facebook Page chats.</p>
                            <p class="text-xs italic pb-2"><strong>Name</strong> - Provide a descriptive, unique name for the engagement to help identify it for future edits and use.  Example: Site Chat Widget.</p>
                            <p class="text-xs italic pb-2"><strong>Type</strong> - The type of engagement being used.</p>
                            <p class="text-xs italic pb-2"><strong>URL</strong> - Available only with the m.me URL type, the URL provided by Facebook acting as a short link to the associated Facebook Page chat.  This will automatically update when a
                            Ref Key is set.</p>
                        </div>
                    </div>
                </div>

                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'type_details'">
                    <h2 class="p-4">Engagement Type Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Specific settings available for the different engagement types.</p>
                            <p class="text-xs italic pb-2"><strong>Ref Key</strong> - String key used to identify the incomming request (must be unique for all engagements associated with the bot). This determines the attribute to tag (if provided) and the
                            sequence to trigger (if provided). Available for m.me URL, chat widget, checkbox, and send to messenger button.</p>
                            <p class="text-xs italic pb-2"><strong>Attribute</strong> - Optional, an attribute that can be tagged for the person interacting with the bot. Available for m.me URL, chat widget, checkbox, and send to messenger button.</p>
                            <p class="text-xs italic pb-2"><strong>Attribute Value</strong> - The value to use when tagging the person with the given attribute. Available for m.me URL, chat widget, checkbox, and send to messenger button.</p>
                            <p class="text-xs italic pb-2"><strong>Trigger Sequence</strong> - Sequence to trigger when the person interacts with this engagement. Available for m.me URL, chat widget, checkbox, and send to messenger button.</p>
                            <p class="text-xs italic pb-2"><strong>Theme Color</strong> - Color theme to be used for the chat widget.  Available for only chat widget.</p>
                            <p class="text-xs italic pb-2"><strong>Greeting Delay</strong> - Delay (in seconds) before the greeting will be displayed to a person.  Available for only chat widget.</p>
                            <p class="text-xs italic pb-2"><strong>Greeting Message (Logged in User)</strong> - Greeting message displayed to a person that is currently logged into Facebook.  Available for only chat widget.</p>
                            <p class="text-xs italic pb-2"><strong>Greeting Message (Logged out User)</strong> - Greeting message displayed to a person that is not currently logged into Facebook.  Available for only chat widget.</p>
                            <p class="text-xs italic pb-2"><strong>Size</strong> - Size of the Facebook provided widget.  Available for checkbox, send to messenger button, and message us button.</p>
                            <p class="text-xs italic pb-2"><strong>Skin</strong> - Color theme used for the Facebook provided widget (light or dark).  Available for only checkbox.</p>
                            <p class="text-xs italic pb-2"><strong>Alignment</strong> - Text alignment for the associated widget text (left or center).  Available for only checkbox.</p>
                            <p class="text-xs italic pb-2"><strong>Allow Login</strong> - Allow a person to login to Facebook when interacting with the widget.  Available for only checkbox.</p>
                            <p class="text-xs italic pb-2"><strong>Opt-In Message</strong> - Opt-In message that will be sent to the person when the checkbox is checked and an action is taken.  Available for only checkbox.</p>
                            <p class="text-xs italic pb-2"><strong>Opt-In Button Text</strong> - Opt-In button text for the button provided with the above opt-in message.  Available for only checkbox.</p>
                            <p class="text-xs italic pb-2"><strong>Color</strong> - Color theme to use for the button (blue or white). Available for send to messenger button and message us button.</p>
                            <p class="text-xs italic pb-2"><strong>Enforce Login</strong> - Requires people logged into Facebook to login again before proceeding.  Available for send to messenger button.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'
    import Vue from 'vue'

    import VSwatches from 'vue-swatches'
    import 'vue-swatches/dist/vue-swatches.css'

    export default {
        name: 'EditEngagement',
        components: {
            LayoutApp,
            VSwatches
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetchEngagement()
            this.fetchBot(this.$store.getters.current_bot_id)
            this.fetchAttributes()
            this.fetchSequences()
        },
        computed: {
            fb_url: function() {
                let fb_attribute_value = (this.engagement.params.fb_attribute_id !== '' && this.engagement.params.fb_fb_attribute_value !== '' ? '_'+this.engagement.params.fb_fb_attribute_value : '')

                return 'https://m.me/'+this.bot.fb_page_id+(this.engagement.fb_ref !== '' ? '?ref='+this.engagement.fb_ref + fb_attribute_value : '')
            },
            id () {
                return this.$route.params.id;
            },
        },
        data() {
            return {
                pages: [],
                attributes: [],
                sequences: [],
                bot: {
                    id: null,
                    fb_page_id: '',
                },
                engagement: {
                    name: '',
                    fb_bot_id: this.$store.getters.current_bot_id,
                    status: 'active',
                    type: 'fb_url',
                    fb_ref: '',
                    params: {
                        fb_attribute_id: null,
                        fb_attribute_value: '',
                        fb_sequence_id: null,
                        theme_color: '',
                        greeting_dialog_delay: 0,
                        greeting_dialog_display: 'show',
                        logged_in_greeting: '',
                        logged_out_greeting: '',
                        optin_message: '',
                        optin_button: '',
                        size: 'large',
                        skin: 'light',
                        allow_login: true,
                        center_align: true,
                        enforce_login: false,
                        color: 'blue',
                    }
                },
                errors: [],
                show_help_section: null,
            }
        },
        methods: {
            fetchEngagement(egagement_id) {
                axios.get(this.engagementUrl()).then(this.refreshEngagement);
            },
            engagementUrl() {
                return `/api/v1/engagements/${this.id}`;
            },
            refreshEngagement({ data }) {
                this.engagement = data.data;
            },
            fetchAttributes() {
                axios.get(this.attributesUrl()).then(this.refreshAttributes);
            },
            attributesUrl() {
                return `/api/v1/attributes?per_page=-1&scope=client`;
            },
            refreshAttributes({ data }) {
                this.attributes = data.data;
            },
            fetchSequences() {
                axios.get(this.sequencesUrl()).then(this.refreshSequences);
            },
            sequencesUrl() {
                return `/api/v1/sequences?per_page=-1&fb_bot_id=${this.engagement.fb_bot_id}`;
            },
            refreshSequences({ data }) {
                this.sequences = data.data;
            },
            fetchBot(bot_id) {
                axios.get(this.botUrl(bot_id)).then(this.refreshBot);
            },
            botUrl(bot_id) {
                return `/api/v1/bots/${bot_id}/view`;
            },
            refreshBot({ data }) {
                this.bot = data.data;
            },
            save() {
              let item = JSON.parse(JSON.stringify(this.engagement));

              axios.put('/api/v1/engagements/'+this.id, item).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The engagement has been created',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_engagements'});
              }).catch(error => {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
                } else {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while creating the engagement',
                        duration: 5000,
                        speed: 1000
                    });
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }
</script>
