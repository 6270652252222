<template>
    <div class="h-full">
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Inbox</h1>
        </div>
        <div class="m-5 bg-white">
            <div class="bg-white shadow-md rounded my-6 flex flex-row">
                <div class="w-1/3 flex-initial text-center px-4 py-2 m-2">
                    <input class="form-text" type="text" id="search" name="search" v-model="search" placeholder="Search" @blur="updateSearch()" />
                </div>
                <div class="w-1/3 flex-initial text-center px-4 py-2 m-2">
                    <multiselect v-model="search_inbox_statuses" :options="inbox_statuses" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Filter by Status" @input="updateSearch()"></multiselect>
                </div>
            </div>
        </div>
        <div class="m-5 h-screen2/3">
            <div class="flex flex-row h-screen2/3">
                <div v-if="inbox.length < 1" class="p-4 ">
                    <p>No inbox conversations available for given status.  Filter by status above to see messages.</p>
                </div>
                <div class="flex-none flex-col w-64 bg-white shadow-md rounded mr-3" v-if="inbox.length > 0">
                    <list-person v-for="person in inbox" v-bind:key="person.id" v-bind:person="person" v-on:select-person="selectPerson(person)"></list-person>
                </div>
                <div class="flex flex-grow flex-col h-full bg-white shadow-md rounded mx-3" v-if="selected_person !== null">
                    <div class="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full">
                        <div class="flex flex-col h-full overflow-x-auto mb-4">
                            <div class="flex flex-col h-full">
                                <div class="grid grid-cols-12 gap-y-2">
                                    <list-message v-for="message in messages" :key="message.id" v-bind:message="message"></list-message>
                                    <div class="inbox-message-footer"></div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-row items-center h-16 rounded-xl bg-white w-full" v-if="messages.length > 0 && selected_person !== null && selected_person.inbox_status !== 'complete'">
                            <!--<div>
                                <button class="flex items-center justify-center text-gray-400 hover:text-gray-600" :disabled="selected_person === null">
                                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"></path>
                                    </svg>
                                </button>
                            </div>-->
                            <div class="flex-grow ml-4">
                                <div class="relative w-full">
                                    <input type="text" class="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10" v-model="inbox_message_send_text" :disabled="selected_person === null || selected_person.inbox_status === 'complete'" />
                                    <button class="absolute flex items-center justify-center h-full w-12 right-0 top-0 text-gray-400 hover:text-gray-600" :disabled="selected_person === null">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div class="ml-4">
                                <button class="btn btn-primary flex items-center justify-center" :disabled="selected_person === null || selected_person.inbox_status === 'complete'" @click="sendMessage()">
                                    <span>Send</span>
                                    <span class="ml-2">
                                        <svg class="w-4 h-4 transform rotate-45 -mt-px" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"></path>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-none flex-col">
                    <div class="flex-none flex-row w-64 bg-white shadow-md rounded ml-3 mb-3 p-3" v-if="selected_person !== null">
                        <h5>Inbox Status</h5>
                        <div class="relative">
                            <select class="form-select" id="inbox_status" name="inbox_status" v-model="selected_person.inbox_status" @change="updateInboxStatus()">
                                <option value="waiting">Waiting</option>
                                <option value="active">Active</option>
                                <option value="complete">Complete</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                        </div>
                    </div>
                    <div class="flex-none flex-row w-64 bg-white shadow-md rounded ml-3 my-3 p-3" v-if="selected_person !== null">
                        <h5>Segments</h5>
                        <div class="flex p-2" v-for="segment in selected_person.segments" v-bind:key="segment.id">
                            <div class="w-5/6 text-left text-sm text-left">{{ segment.name }}<br /><span class="text-xs text-gray-700">{{ segment.pivot.added_at | formatDate }}</span></div>
                        </div>
                    </div>
                    <div class="flex-none flex-row w-64 bg-white shadow-md rounded ml-3 my-3 p-3" v-if="selected_person !== null">
                        <h5>Attributes</h5>
                        <div class="flex p-2" v-for="attribute in selected_person.attributes" v-bind:key="attribute.id">
                            <div class="w-5/6 text-left text-sm text-left">{{ attribute.name }}<br /><span class="text-sm text-gray-700">{{ attribute.pivot.value }}</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<table class="listing">
                <thead>
                    <tr>
                        <th><a @click="updateSort('id')">ID</a></th>
                        <th><a @click="updateSort('last_name')">From</a></th>
                        <th><a @click="updateSort('text')">Message</a></th>
                        <th><a @click="updateSort('created_at')">Date</a></th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="hover:bg-grey-lighter" v-for="inbox in inbox" v-bind:key="inbox.id">
                        <td class="py-4 px-6 border-b border-grey-light">{{ inbox.id }}</td>
                        <td class="py-4 px-6 border-b border-grey-light"><router-link :to="{ name: 'view_person', params: { id: inbox.person_id }}" class="mx-3">{{ inbox.first_name }} {{ inbox.last_name }}</router-link></td>
                        <td class="py-4 px-6 border-b border-grey-light">{{ inbox.text }}</td>
                        <td class="py-4 px-6 border-b border-grey-light">{{ inbox.created_at | formatDate }}</td>
                        <td class="py-4 px-6 border-b border-grey-light">
                            <a href="javascript:void(0);" v-on:click="confirmDelete(inbox.id)" class="mx-3"><i class="fas fa-trash-alt"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>-->
        </div>

        <!--<paginate
            :page-count="page_count"
            :click-handler="fetch"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'pagination-page'"
            :prev-class="'pagination-prev'"
            :next-class="'pagination-next'"
            :disabled-class="'pagination-inactive'">
        </paginate>-->

        <modal name="delete-confirm" class="confirm-modal" :height="220">
            <div class="modal-container">
                <div class="modal-content">
                    <div class="modal-c-title">Delete Message</div>
                    <div class="modal-c-text">Are you sure you want to delete this message from the Inbox?  This action cannot be undone.</div>
                </div>
                <div class="modal-buttons">
                    <button type="button" class="modal-button" @click="$modal.hide('delete-confirm')">Cancel</button>
                    <button type="button" class="modal-button-primary" @click="deleteInbox()">Delete</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import axios from 'axios'
    import LayoutApp from '../../layouts/App'
    import ListPerson from './components/ListPerson'
    import ListMessage from './components/ListMessage'

    export default {
        name: 'ListInbox',
        components: {
            LayoutApp,
            ListPerson,
            ListMessage,
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetch(1);


        },
        updated: function () {
            this.$nextTick(function () {
                if (this.scroll_messages) {
                    let el = this.$el.getElementsByClassName('inbox-message-footer')[0];

                    if (el) {
                        el.scrollIntoView({ behavior: 'smooth', block: 'end' });
                    }

                    this.scroll_messages = false
                }
            })
        },
        data() {
            return {
                inbox: [],
                delete_id: null,
                page_count: 1,
                sort: 'desc',
                order: 'id',
                messages: [],
                search: '',
                search_inbox_statuses: [],
                messages_page_count: 1,
                selected_person: null,
                scroll_messages: false,
                inbox_message_send_text: '',
                inbox_statuses: [
                    {id: 'active', name: 'Active'},
                    {id: 'waiting', name: 'Waiting'},
                    {id: 'complete', name: 'Complete'},
                ],
            }
        },
        methods: {
            fetch(page) {
                this.current_page = page;

                let search_inbox_statuses = this.search_inbox_statuses.map(status => status.id)

                axios.get(`/api/v1/inbox?fb_bot_id=${this.$store.getters.current_bot_id}&page=${this.current_page}&sort=${this.sort}&order=${this.order}&search_inbox_statuses=${search_inbox_statuses}`).then(this.refresh);
            },
            refresh({data}) {
                this.inbox = data.data;
                this.page_count = data.meta.pages;
                this.selected_person = null;

                window.scrollTo(0,0);
            },
            fetchMessages(person_id, page, sort, order) {
                axios.get(`/api/v1/inbox/${person_id}?page=${page}&sort=${sort}&order=${order}`).then(this.refreshMessages);
            },
            refreshMessages({data}) {
                this.messages = data.data.slice().reverse();
                this.messages_page_count = data.meta.pages;

                if (data.meta.page === 1) {
                    this.scroll_messages = true

                    window.Echo.private(`inbox.${this.selected_person.id}`).listen('FacebookMessageReceived', (e) => {
                        console.log(e)
                        this.messages.push(e.message)
                        // TODO: Update person here so we get the latest info?
                        this.scroll_messages = true
                    });
                }
            },
            sendMessage() {
                let message = {
                    text: this.inbox_message_send_text
                }

                axios.post(`/api/v1/inbox/${this.selected_person.id}/reply?fb_bot_id=${this.$store.getters.current_bot_id}`, message).then(this.refreshSentMessage);
            },
            refreshSentMessage({data}) {
                if (data.success) {
                    this.inbox_message_send_text = '';
                } else {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred sending the reply.',
                        duration: 5000,
                        speed: 1000
                    });
                }
            },
            confirmDelete(id) {
                this.delete_id = id;

                this.$modal.show('delete-confirm');
            },
            deleteInbox() {
                axios.delete(`/api/v1/inbox/${this.delete_id}`).then(this.postDelete);
            },
            postDelete() {
                this.$modal.hide('delete-confirm');
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The inbox message has been deleted',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch(1);
            },
            updateSort(field) {
                if (field === this.order) {
                    this.sort = (this.sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.sort = 'asc';
                }

                this.order = field;

                this.fetch(1);
            },
            selectPerson(person) {
                if (this.selected_person !== null) {
                    window.Echo.leaveChannel(`inbox.${this.selected_person.id}`);
                }

                this.selected_person = person
                this.fetchMessages(person.id, 1, 'desc', 'id')
            },
            updateInboxStatus() {
                let status = {
                    inbox_status: this.selected_person.inbox_status
                }

                axios.post(`/api/v1/inbox/${this.selected_person.id}/status?fb_bot_id=${this.$store.getters.current_bot_id}`, status).then(this.refreshInboxStatus);
            },
            refreshInboxStatus({data}) {
                if (this.selected_person.inbox_status === 'complete') {
                    this.fetch(1)
                }
            },
            updateSearch() {
                this.fetch(1);
            },
        }
    }
</script>
