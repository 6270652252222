<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Home</h1>
        </div>

        <div class="flex">
            <div class="m-5 bg-white w-1/2 shadow-md rounded my-6">
                <div class="">
                    <div class="bg-white" v-if="!is_setup && login_url !== ''">
                        <div class="px-6 py-4">
                            <div class="font-bold text-xl mb-2">Connect with Facebook</div>
                            <p class="text-gray-700 text-base">In order to create a Bot, you first need to authorize access to
                                Facebook.
                            </p>
                        </div>
                        <div class="px-6 py-4">
                            <div>
                                <a v-bind:href="login_url" class="flex items-center justify-center w-full px-4 py-2 mt-2 space-x-3 text-sm text-center bg-blue-500 text-white transition-colors duration-200 transform border rounded-lg dark:text-gray-300 dark:border-gray-300 hover:bg-gray-600 dark:hover:bg-gray-700">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                    </svg>
                                    <span class="text-sm text-white dark:text-gray-200">Continue with Facebook</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white" v-else-if="is_setup">
                        <div class="px-6 py-4">
                            <div class="font-bold text-xl mb-2">Connected with Facebook!</div>
                            <p class="text-gray-700 text-base">You're all set!  If you need to access new pages, just use the re-authorize button below to allow access.</p>
                        </div>
                        <div class="px-6 py-4">
                            <a v-bind:href="login_url" class="flex items-center justify-center w-full px-4 py-2 mt-2 space-x-3 text-sm text-center bg-blue-500 text-white transition-colors duration-200 transform border rounded-lg dark:text-gray-300 dark:border-gray-300 hover:bg-gray-600 dark:hover:bg-gray-700">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                </svg>
                                <span class="text-sm text-white dark:text-gray-200">Continue with Facebook</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="m-5 w-1/2  rounded my-6">

            </div>
        </div>

        <div class="flex">
            <div class="m-5 bg-white w-1/2 shadow-md rounded my-6">
                <div class="">
                    <div class="bg-white">
                        <div class="px-6 py-4">
                            <div class="font-bold text-xl mb-2">Growth/Loss</div>
                            <line-chart :data="growth_loss_data"></line-chart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="m-5 bg-white w-1/2 shadow-md rounded my-6">
                <div class="">
                    <div class="bg-white">
                        <div class="px-6 py-4">
                            <div class="font-bold text-xl mb-2">Messages Sent</div>
                            <column-chart :data="message_summary_data"></column-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment-timezone'
    import LayoutApp from '../layouts/App'

    export default {
        name: 'home',
        components: {
            LayoutApp
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetch();
            this.fetchGrowthLoss();
            this.fetchMessageSummary();
        },
        data() {
            return {
                login_url: '',
                is_setup: false,
                growth_loss_data: null,
                message_summary_data: null,
            }
        },
        methods: {
            fetch(page) {
                axios.get(this.url(page)).then(this.refresh);
            },
            url() {
                return `/api/v1/home`;
            },
            refresh({data}) {
                this.login_url = data.login_url;
                this.is_setup = data.is_setup;
            },
            reportUrl() {
                return `/api/v1/reports/view`;
            },
            fetchGrowthLoss() {
                let start = moment().subtract(30, 'days').format('YYYY-MM-DD');
                let end = moment().format('YYYY-MM-DD');
                axios.get(this.reportUrl()+`/?fb_bot_id=${this.$store.getters.current_bot_id}&type=growth_loss_summary&group_by=day&start=${start}&end=${end}`).then(this.refreshGrowthLoss);
            },
            refreshGrowthLoss({data}) {
                this.growth_loss_data = data.data;
            },
            fetchMessageSummary() {
                let start = moment().subtract(30, 'days').format('YYYY-MM-DD');
                let end = moment().add(1, 'days').format('YYYY-MM-DD');
                axios.get(this.reportUrl()+`/?fb_bot_id=${this.$store.getters.current_bot_id}&type=message_summary&group_by=day&start=${start}&end=${end}`).then(this.refreshMessageSummary);
            },
            refreshMessageSummary({data}) {
                this.message_summary_data = data.data;
            }
        }
    }
</script>
