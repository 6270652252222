<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Edit Segment</h1>
        </div>
        <div class="flex flex-row">
            <div class="flex-grow">
                <div class="m-5 bg-white shadow-md rounded">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Segment Details</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('details')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="pl-4">
                        <p class="text-red-500 text-xs italic" v-if="errors && errors.segment">{{ errors.segment[0] }}</p>
                    </div>
                    <div class="bg-white my-6 p-4">
                        <div class="w-full">

                        <!-- Row One -->
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="name">Name</label>
                            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="name" type="text" v-model="segment.name">
                            <p class="text-red-500 text-xs italic" v-if="errors && errors.name">{{ errors.name[0] }}</p>
                            </div>
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="status">Status</label>
                            <div class="relative">
                                <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="status" name="status" v-model="segment.status">
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                                </select>
                                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                </div>
                            </div>
                            <p class="text-red-500 text-xs italic" v-if="errors && errors.status">{{ errors.status[0] }}</p>
                            </div>
                        </div>

                        <!-- Row Two -->
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="type">Type</label>
                            <div class="relative">
                                <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="type" name="type" v-model="segment.type" disabled>
                                <option value="static">Static</option>
                                <option value="dynamic">Dynamic</option>
                                </select>
                                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                </div>
                            </div>
                            <p class="text-red-500 text-xs italic" v-if="errors && errors.status">{{ errors.status[0] }}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="m-5 bg-white" v-if="segment.type === 'dynamic'">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Dynamic Segment Details</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('dynamic')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="bg-white px-6 pb-2">
                        <p class="text-red-500 text-xs italic" v-for="(error, index) in errors.dynamic_body" v-bind:key="index">{{ error }}</p>

                        <div class="w-full m-6">

                            <!-- Row 1 -->
                            <div class="border-solid border-2 border-gray-600 bg-gray-300 mb-4 mr-4" v-for="(item, index) in segment.dynamic_body.items" v-bind:key="index">
                                <div class="flex flex-wrap my-3" v-for="(row, row_index) in item.items" v-bind:key="row_index">
                                    <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                                        <div class="relative">
                                            <select class="form-select" v-model="row.attribute">
                                                <option :value="null" disabled>- Select Attribute -</option>
                                                <option v-for="attribute in attributes" v-bind:key="attribute.id" :value="attribute">{{ attribute.name }}</option>
                                            </select>
                                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                                        <div class="relative" v-if="row.attribute">
                                            <select class="form-select" v-model="row.operator">
                                                <option value="=">is equal to</option>
                                                <option value="!=">is not equal to</option>
                                                <option value="<">is less than</option>
                                                <option value="<=">is less than or equal to</option>
                                                <option value=">">is greater than</option>
                                                <option value=">=">is greater than or equal to</option>
                                            </select>
                                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                                        <input class="form-text" v-model="row.value" v-if="row.attribute && row.attribute.values_type === 'open'">
                                        <div class="relative" v-if="row.attribute && row.attribute.values_type === 'predefined'">
                                            <select class="form-select" v-model="row.value">
                                                <option :value="null" disabled>- Select Value -</option>
                                                <option v-for="(value, value_index) in row.attribute.valid_values.values" v-bind:key="value_index" :value="value.value">{{ value.label || value.value }}</option>
                                            </select>
                                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-full md:w-1/12 cursor-pointer h-full pl-6">
                                        <button class="btn btn-alert" @click="removeRow(row_index, index)"><i class="fas fa-minus-circle"></i></button>
                                    </div>
                                    <div class="w-full md:w-1/12 cursor-pointer h-full pt-8 pl-6" v-if="row_index === (item.items.length - 1)">
                                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-3" @click="addRow(index)"><i class="fas fa-plus-circle"></i> OR</button>
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-wrap mx-3 mb-6">
                                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-3" @click="addBlock()"><i class="fas fa-plus-circle"></i> AND</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-wrap m-5 px-3 pt-3">
                    <button class="btn btn-primary" v-on:click="save">
                        Update Segment
                    </button>

                    <button class="btn btn-secondary" v-on:click="cancel">
                        Cancel
                    </button>
                </div>
            </div>

            <div class="w-64" v-if="show_help_section">
                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'details'">
                    <h2 class="p-4">Segment Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Segments are used to organize people together through manual additions (static) or based on attribute values (dynamic).</p>
                            <p class="text-xs italic pb-2"><strong>Name</strong> - Provide a descriptive, unique name for the segment to help identify it for future edits and use.  Example: Site Form Submissions.</p>
                            <p class="text-xs italic pb-2"><strong>Type</strong> - Static lists have people added manually, through Sequence actions.  Dynamic lists have people added based on their current
                            attribute value(s) assigned, and the rules specified for dynamic list below.</p>
                        </div>
                    </div>
                </div>

                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'dynamic'">
                    <h2 class="p-4">Dynamic Segments Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Defines the logic on how people are associated with this list.</p>
                            <p class="text-xs pb-2">Each section, represents a set of conditional logic where only one item must be met for the condition to be true.  When additional sections are added, at least
                                one condition in each section must be true for the entire set to be true.</p>
                            <p class="text-xs italic pb-2">Example: One section with two conditions, and another section with one condition might read as the following - (When State is equal to "MO" OR
                                State is equal to "IL") AND (When Source is equal to "Registration Form").  Here, the conditional will only ever be true when the a person's State attribute is set to "MO" or "IL"
                                and when their Source attribute is set to "Registration Form."
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'
    import Vue from 'vue'

    export default {
        name: 'EditSegment',
        components: {
            LayoutApp
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            // Is this right?  Feels odd, but keeps the segment from building before the
            // attributes are ready.
            this.fetchAttributes().then(this.fetchSegment);
        },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        data() {
            return {
                pages: [],
                attributes: [],
                segment: {
                  id: '',
                  name: '',
                  fb_page_id: this.$store.getters.current_bot_id,
                  status: '',
                  type: 'static',
                  dynmaic_body: []
                },
                errors: [],
                show_help_section: null,
            }
        },
        methods: {
            fetchSegment(data) {
                axios.get(this.segmentUrl(this.id)).then(this.refreshSegment)
            },
            segmentUrl() {
                return `/api/v1/segments/${this.id}`;
            },
            refreshSegment({data}) {
                this.segment = data.data;

                if (this.segment.type === 'dynamic') {
                    for (let block_index in this.segment.dynamic_body.items) {
                        for (let row_index in this.segment.dynamic_body.items[block_index].items) {
                            for (let attribute_index in this.attributes) {
                                if (this.attributes[attribute_index].id === this.segment.dynamic_body.items[block_index].items[row_index].attribute_id) {
                                    this.segment.dynamic_body.items[block_index].items[row_index].attribute = this.attributes[attribute_index];
                                    break;
                                }
                            }
                        }
                    }
                }
            },
            fetchAttributes() {
                return axios.get(this.attributesUrl()).then(this.refreshAttributes);
            },
            attributesUrl() {
                return `/api/v1/attributes?per_page=-1&scope=client`;
            },
            refreshAttributes({
                data
            }) {
                this.attributes = data.data;
            },
            save() {
              let item = JSON.parse(JSON.stringify(this.segment));

              if (this.segment.type === 'dynamic') {
                for (let i = 0 ; i < item.dynamic_body.items.length ; ++i) {
                    for (let j = 0 ; j < item.dynamic_body.items[i].items.length ; ++j) {
                        if (item.dynamic_body.items[i].items[j].attribute) {
                            item.dynamic_body.items[i].items[j].attribute_id = item.dynamic_body.items[i].items[j].attribute.id;
                            delete item.dynamic_body.items[i].items[j].attribute;
                        } else {

                        }
                    }
                }
              }

              axios.put('/api/v1/segments/'+this.id, item).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The segment has been updated',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_segments'});
              }).catch(error => {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
                } else {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while updating the segment',
                        duration: 5000,
                        speed: 1000
                    });
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            addBlock() {
                this.segment.dynamic_body.items.push({
                    operator: 'OR',
                    items: [{
                        attribute: null,
                        attribute_id: null,
                        operator: '=',
                        value: null
                    }]
                })
            },
            addRow(block_index) {
                this.segment.dynamic_body.items[block_index].items.push({
                    attribute: null,
                    attribute_id: null,
                    operator: '=',
                    value: null
                })
            },
            removeRow(index, block_index) {
                Vue.delete(this.segment.dynamic_body.items[block_index].items, index)

                if (this.segment.dynamic_body.items[block_index].items.length === 0) {
                    Vue.delete(this.segment.dynamic_body.items, block_index)
                }
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }
</script>
