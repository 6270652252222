<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Create New Report</h1>
        </div>
        <div class="flex flex-row">
            <div class="flex-grow">
                <div class="m-5 bg-white shadow-md rounded">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Report Details</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('details')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="pl-4">
                        <p class="text-red-500 text-xs italic" v-if="errors && errors.report">{{ errors.report[0] }}</p>
                    </div>

                    <div class="bg-white my-6 p-4">
                        <div class="w-full">

                            <!-- Row One -->
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="form-label" for="name">Name</label>
                                <input class="form-text" id="name" type="text" v-model="report.name">
                                <p class="text-red-500 text-xs italic" v-if="errors && errors.name">{{ errors.name[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="form-label" for="type">Type</label>
                                <div class="relative">
                                    <select class="form-select" id="type" name="type" v-model="report.type">
                                    <option :value="null">- Select Report Type -</option>
                                    <option value="growth_loss_summary">Growth/Loss Summary</option>
                                    <option value="message_summary">Message Summary</option>
                                    <option value="message_export">Message Export</option>
                                    <option value="broadcast_export">Broadcast Export</option>
                                    <option value="segment_person_export">Segment Person Export</option>
                                    </select>
                                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                    </div>
                                </div>
                                <p class="text-red-500 text-xs italic" v-if="errors && errors.type">{{ errors.type[0] }}</p>
                                </div>
                            </div>

                            <!-- Row Two -->
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="form-label" for="type">Date Range</label>
                                <datepicker input-class="form-text" ref="datepicker" v-model="report.parameters.date_range" type="daterange" :range="true" :lang="daterange.lang"></datepicker>
                                <p class="text-red-500 text-xs italic" v-if="errors && errors.parameters && errors.parameters.date_start">{{ errors.parameters.date_start[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0" v-if="showGroupBy()">
                                <label class="form-label" for="group_by">Group By</label>
                                <div class="relative">
                                    <select class="form-select" id="group_by" name="group_by" v-model="report.parameters.group_by">
                                    <option :value="null">- Select Group By -</option>
                                    <option value="day">Day</option>
                                    <option value="week">Week</option>
                                    <option value="month">Month</option>
                                    <option value="year">Year</option>
                                    </select>
                                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                    </div>
                                </div>
                                <p class="text-red-500 text-xs italic" v-if="errors && errors.parameters && errors.parameters.group_by">{{ errors.parameters.group_by[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0" v-if="showDirection()">
                                <label class="form-label" for="direction">Direction</label>
                                <div class="relative">
                                    <select class="form-select" id="direction" name="direction" v-model="report.parameters.direction">
                                    <option :value="null">- Select Direction -</option>
                                    <option value="">All</option>
                                    <option value="in">In</option>
                                    <option value="out">Out</option>
                                    </select>
                                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                    </div>
                                </div>
                                <p class="text-red-500 text-xs italic" v-if="errors && errors.parameters && errors.parameters.direction">{{ errors.parameters.direction[0] }}</p>
                                </div>
                            </div>

                            <!-- Row Three -->
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0" v-if="showSegmentSelect()">
                                <label class="form-label" for="segments">Segments</label>
                                <multiselect v-model="report.parameters.segments" :options="segments" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Select a Segment"></multiselect>
                                <p class="text-red-500 text-xs italic" v-if="errors && errors.parameters && errors.parameters.segment_ids">{{ errors.parameters.segment_ids[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0" v-if="showSequenceSelect()">
                                <label class="form-label" for="sequence_id">Sequence</label>
                                <div class="relative">
                                    <select class="form-select" id="sequence_id" name="sequence_id" v-model="report.parameters.sequence_id">
                                    <option :value="null">- Select Sequence -</option>
                                    <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                                    </select>
                                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                    </div>
                                </div>
                                <p class="text-red-500 text-xs italic" v-if="errors && errors.parameters && errors.parameters.sequence_id">{{ errors.parameters.sequence_id[0] }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-wrap m-5 px-3 pt-3">
                    <button class="btn btn-primary" v-on:click="save">
                        Create Report
                    </button>

                    <button class="btn btn-secondary" v-on:click="cancel">
                        Cancel
                    </button>
                </div>
            </div>

            <div class="w-64" v-if="show_help_section">
                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'details'">
                    <h2 class="p-4">Report Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Reports provide a way to aggregate and collect data as CSV exports.</p>
                            <p class="text-xs italic pb-2"><strong>Name</strong> - Provide a descriptive, unique name for the report to help identify it for future downloads.  Example: Contact Congress Call Summary November 2020.</p>
                            <p class="text-xs italic pb-2"><strong>Type</strong> - Type of report to be created. Growth/Loss Summary provides a summary of one or more segments growth, loss, and total subscribed people over a given date range.
                            Message Summary provides an aggregate summary of different message types over a given date range.  Message Export provides a complete export of all messages for a given date range.  Broadcast Export provides a complete
                            export of all broadcasts sent during a given date range.  Segment Person Export provides a complete export of all people associated with one or more segments.</p>
                            <p class="text-xs italic pb-2"><strong>Date Range</strong> - The start and end date to use when compiling the report information.</p>
                            <p class="text-xs italic pb-2"><strong>Group By</strong> - If available, the summary aggregate to use.</p>
                            <p class="text-xs italic pb-2"><strong>Segments</strong> - If available, the segments to be associated with the reported data.</p>
                            <p class="text-xs italic pb-2"><strong>Sequence</strong> - If available, the sequence to be associated with the reported data.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment-timezone'
    import LayoutApp from '../../layouts/App'

    export default {
        name: 'CreateReport',
        components: {
            LayoutApp
        },
        created() {
            this.$emit('update:layout', LayoutApp)
            this.fetchSegments()
            this.fetchSequences()
        },
        watch: {
            'report.parameters.date_range' (newDate, oldDate) {
                this.closeDatepicker();
            }
        },
        data() {
            return {
                daterange: {
                    lang: {
                        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
                        placeholder: {
                        date: 'Select Date',
                        dateRange: 'Select Date Range'
                        }
                    },
                },
                segments: [],
                sequences: [],
                report: {
                  name: '',
                  fb_bot_id: this.$store.getters.current_bot_id,
                  type: null,
                  parameters: {
                      date_range: '',
                      group_by: null,
                      segments: [],
                      sequence_id: null,
                      direction: null,
                  }
                },
                errors: [],
                show_help_section: false,
            }
        },
        methods: {
            closeDatepicker() {
                this.$refs.datepicker.closePopup()
            },
            fetchSegments() {
                axios.get(this.segmentsUrl()).then(this.refreshSegments);
            },
            segmentsUrl() {
                return `/api/v1/segments?fb_bot_id=${this.$store.getters.current_bot_id}&search_types=static`;
            },
            refreshSegments({ data }) {
                this.segments = data.data;
            },
            fetchSequences() {
                axios.get(this.sequenceUrl()).then(this.refreshSequences);
            },
            sequenceUrl() {
                return `/api/v1/sequences?fb_bot_id=${this.$store.getters.current_bot_id}`;
            },
            refreshSequences({ data }) {
                this.sequences = data.data;
            },
            save() {
              let report = this.buildReport()

              axios.post('/api/v1/reports', report).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The report has been created',
                    duration: 5000,
                    speed: 1000
                });

                this.$router.push({name: 'list_reports'});
              }).catch(error => {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
                } else {
                    this.$notify({
                        group: 'alerts',
                        type: 'error',
                        title: 'An error occurred while creating the report',
                        duration: 5000,
                        speed: 1000
                    });
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            showGroupBy() {
                if (this.report.type === 'growth_loss_summary' || this.report.type === 'message_summary')
                    return true
                else
                    return false
            },
            showDirection() {
                if (this.report.type === 'message_export')
                    return true
                else
                    return false
            },
            showSegmentSelect() {
                if (this.report.type === 'growth_loss_summary' || this.report.type === 'broadcast_export' || this.report.type === 'segment_person_export')
                    return true
                else
                    return false
            },
            showSequenceSelect() {
                if (this.report.type === 'message_summary' || this.report.type === 'message_export')
                    return true
                else
                    return false
            },
            buildReport() {
                let report = {
                    name: this.report.name,
                    fb_bot_id: this.$store.getters.current_bot_id,
                    type: this.report.type,
                    parameters: {
                        date_start: moment(this.report.parameters.date_range[0]).format('YYYY-MM-DD'),
                        date_end: moment(this.report.parameters.date_range[1]).format('YYYY-MM-DD'),
                    }
                }

                if (this.report.type === 'growth_loss_summary' || this.report.type === 'message_summary') {
                    report.parameters.group_by = this.report.parameters.group_by
                }

                if (this.report.type === 'message_export') {
                    report.parameters.direction = this.report.parameters.direction
                }

                if (this.report.type === 'growth_loss_summary' || this.report.type === 'broadcast_export' || this.report.type === 'segment_person_export') {
                    report.parameters.segment_ids = []

                    for (let i in this.report.parameters.segments) {
                        report.parameters.segment_ids.push(this.report.parameters.segments[i].id)
                    }
                }

                if (this.report.type === 'message_summary' || this.report.type === 'message_export') {
                    report.parameters.sequence_id = this.report.parameters.sequence_id
                }

                if (this.report.type === 'segment_person_export') {
                    report.parameters.attribute_ids = []
                }

                return report
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }
</script>
