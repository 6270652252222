import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        current_bot_id: (localStorage.getItem('current_bot_id') ? parseInt(localStorage.getItem('current_bot_id')) : null),
        status: '',
        token: window.$cookies.get('token') || '', //localStorage.getItem('token') || '',
        user: {}
    },
    mutations: {
        updateBotID(state, bot_id) {
            state.current_bot_id = bot_id;
            localStorage.setItem('current_bot_id', bot_id);
        },
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, token, user) {
            state.status = 'success'
            state.token = token
            state.user = user
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
        },
    },
    actions: {
        logout({commit}) {
            console.log('logout called');
            return new Promise((resolve, reject) => {
                commit('logout')
                delete axios.defaults.headers.common['Authorization']
                window.$cookies.remove('token', '/', process.env.MIX_ROOT_APP_URL)
                resolve()
            })
        }
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        current_bot_id: state => state.current_bot_id,
    }
})
