<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Edit Bot</h1>
        </div>
        <div class="flex flex-row">
            <div class="flex-grow">
                <div class="m-5 bg-white shadow-md rounded ">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Bot Details</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('details')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="bg-white my-6 p-4">
                        <div class="w-full">
                            <!-- Row One -->
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="name">Name</label>
                                    <input class="form-text" id="name" type="text" v-model="bot.name">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.name">{{ errors.name[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="form-label" for="status">Status</label>
                                    <div class="relative">
                                        <select class="form-select" id="status" name="status" v-model="bot.status">
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.status">{{ errors.status[0] }}</p>
                                </div>
                            </div>

                            <!-- Row Two -->
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="start_fb_sequence_id">Start Sequence</label>
                                    <div class="relative">
                                        <select class="form-select" id="start_fb_sequence_id" name="start_fb_sequence_id" v-model="bot.start_fb_sequence_id">
                                            <option :value="null">- None -</option>
                                            <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.start_fb_sequence_id">{{ errors.start_fb_sequence_id[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="catchall_fb_sequence_id">Catchall Sequence</label>
                                    <div class="relative">
                                        <select class="form-select" id="catchall_fb_sequence_id" name="start_fb_sequence_id" v-model="bot.catchall_fb_sequence_id">
                                            <option :value="null">- None -</option>
                                            <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.catchall_fb_sequence_id">{{ errors.catchall_fb_sequence_id[0] }}</p>
                                </div>
                            </div>

                            <!-- Row Three -->
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="fb_page_id">Facebook Page</label>
                                    <div class="relative">
                                        <select class="form-select" id="fb_page_id" name="fb_page_id" v-model="bot.fb_page_id">
                                            <option v-for="page in pages" v-bind:key="page.id" :value="page.id">{{ page.name }}</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors.fb_page_id">{{ errors.fb_page_id[0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="enable_greeting">Greeting</label>
                                    <div class="relative">
                                        <select class="form-select" id="enable_greeting" name="enable_greeting" v-model="bot.messenger_profile_details.enable_greeting">
                                            <option :value="true">Enable</option>
                                            <option :value="false">Disable</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors['bot.messenger_profile_details.enable_greeting']">{{ errors['bot.messenger_profile_details.enable_greeting'][0] }}</p>
                                </div>
                            </div>

                            <!-- Row Four -->
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="enable_persistent_menu">Persistent Menu</label>
                                    <div class="relative">
                                        <select class="form-select" id="enable_persistent_menu" name="enable_persistent_menu" v-model="bot.messenger_profile_details.enable_persistent_menu">
                                            <option :value="true">Enable</option>
                                            <option :value="false">Disable</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors['bot.messenger_profile_details.enable_persistent_menu']">{{ errors['bot.messenger_profile_details.enable_persistent_menu'][0] }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="m-5 bg-white shadow-md rounded " v-if="bot.messenger_profile_details.enable_persistent_menu">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Persistent Menu Details</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('persistent_menu')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="bg-white px-6 pb-2">
                        <p class="text-red-500 text-xs italic" v-if="errors && errors['bot.messenger_profile_details.enable_persistent_menu']">{{ errors['bot.messenger_profile_details.enable_persistent_menu'][0] }}</p>
                        <p class="text-red-500 text-xs italic" v-if="errors && errors['bot.messenger_profile_details.persistent_menu']">{{ errors['bot.messenger_profile_details.persistent_menu'][0] }}</p>
                        <p class="text-red-500 text-xs italic" v-if="errors && errors['bot.messenger_profile_details.persistent_menu.call_to_actions']">{{ errors['persistent_menu.call_to_actions'][0] }}</p>
                        <div class="w-full flex m-6">
                            <!-- Pallette -->
                            <div class="w-1/6 flex flex-col">
                                <label v-on:click="addAction('postback')" class="pallette-label">
                                    <i class="fas fa-sort-amount-down pr-3"></i> Trigger Sequence
                                </label>

                                <label v-on:click="addAction('web_url')" class="pallette-label">
                                    <i class="fas fa-link pr-3"></i> Open URL
                                </label>
                            </div>

                            <!-- Builder -->
                            <div class="w-1/2 overflow-hidden">
                                <draggable :list="bot.messenger_profile_details.persistent_menu[0].call_to_actions" handle=".handle">
                                    <div v-for="(action, index) in bot.messenger_profile_details.persistent_menu[0].call_to_actions" v-bind:key="index" class="w-full mb-6">
                                        <div class="handle flex flex-wrap bg-gray-100 mx-6 p-4 rounded border-gray-700 border" v-bind:class="{ 'border-blue-500': current_action_index === index, 'border-red-600 text-red-600': action_errors[index] }">
                                            <div class="w-full px-3 mb-6 md:mb-0">
                                                <!-- Trigger Sequence Action -->
                                                <div v-if="action.type === 'postback'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg"><a @click="setActiveAction(index)" href="javascript:void(0)">Trigger Sequence</a></div>
                                                        <div class="text-lg">
                                                            <i v-on:click="removeAction(index)" class="ml-2 fas fa-minus-circle cursor-pointer"></i>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Open URL Action -->
                                                <div v-if="action.type === 'web_url'">
                                                    <div class="flex">
                                                        <div class="flex-1 text-lg"><a @click="setActiveAction(index)" href="javascript:void(0)">Open URL</a></div>
                                                        <div class="text-lg">
                                                            <i v-on:click="removeAction(index)" class="ml-2 fas fa-minus-circle cursor-pointer"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </draggable>
                            </div>

                            <!-- Properties -->
                            <div class="w-1/3">
                                <div class="mb-6">
                                    <div v-if="current_action_index !== null" class="flex flex-wrap bg-gray-100 mx-6 p-4 rounded border-gray-700 border">
                                        <div class="w-full px-3 mb-6 md:mb-0">
                                            <!-- Trigger Sequence Button -->
                                            <div v-if="bot.messenger_profile_details.persistent_menu[0].call_to_actions[current_action_index].type === 'postback'">
                                                <div class="flex">
                                                    <div class="flex-1 text-lg">Trigger Sequence Button</div>
                                                </div>
                                                <div class="text-sm pb-4 italic">Select a sequence to trigger when a user selects this option.</div>

                                                <label class="form-label" for="block_0_text">Button Text</label>
                                                <input type="text" class="form-text" id="block_0_text" name="action_0_text" v-model="bot.messenger_profile_details.persistent_menu[0].call_to_actions[current_action_index].title" />
                                                <p class="text-red-500 text-xs italic" v-if="errors && errors['bot.messenger_profile_details.persistent_menu.0.call_to_actions.'+current_action_index+'.title']">{{ errors['bot.messenger_profile_details.persistent_menu.0.call_to_actions.'+current_action_index+'.title'][0] }}</p>

                                                <label class="form-label" for="block_0_list_id">Sequence</label>
                                                <div class="relative pb-3">
                                                    <select class="form-select" id="block_0_payload" name="block_0_payload" v-model="bot.messenger_profile_details.persistent_menu[0].call_to_actions[current_action_index].payload.fb_sequence_id">
                                                        <option :value="null">- Select Sequence -</option>
                                                        <option v-for="sequence in sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                                                    </select>
                                                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                                    </div>
                                                </div>

                                                <p class="text-red-500 text-xs italic" v-if="errors && errors['bot.messenger_profile_details.persistent_menu.0.call_to_actions.'+current_action_index+'.payload']">{{ errors['bot.messenger_profile_details.persistent_menu.0.call_to_actions.'+current_action_index+'.payload'][0] }}</p>
                                                <p class="text-red-500 text-xs italic" v-if="errors && errors['bot.messenger_profile_details.persistent_menu.0.call_to_actions.'+current_action_index+'.payload.fb_sequence_id']">{{ errors['bot.messenger_profile_details.persistent_menu.0.call_to_actions.'+current_action_index+'.payload.fb_sequence_id'][0] }}</p>
                                            </div>

                                            <!-- URL Action -->
                                            <div v-if="bot.messenger_profile_details.persistent_menu[0].call_to_actions[current_action_index].type === 'web_url'">
                                                <div class="flex">
                                                    <div class="flex-1 text-lg">Open URL Button</div>
                                                </div>
                                                <div class="text-sm pb-4 italic">Open a provided URL within Messenger.</div>

                                            <label class="form-label" for="action_0_text">Button Text</label>
                                                <input type="text" class="form-text" id="action_0_text" name="action_0_text" v-model="bot.messenger_profile_details.persistent_menu[0].call_to_actions[current_action_index].title" />
                                                <p class="text-red-500 text-xs italic" v-if="errors && errors['bot.messenger_profile_details.persistent_menu.0.call_to_actions.'+current_action_index+'.title']">{{ errors['bot.messenger_profile_details.persistent_menu.0.call_to_actions.'+current_action_index+'.title'][0] }}</p>

                                                <label class="form-label" for="actions_0_url">URL</label>
                                                <input type="text" class="form-text" id="actions_0_url" name="actions_0_url" v-model="bot.messenger_profile_details.persistent_menu[0].call_to_actions[current_action_index].url" />
                                                <p class="text-red-500 text-xs italic" v-if="errors && errors['bot.messenger_profile_details.persistent_menu.0.call_to_actions.'+current_action_index+'.url']">{{ errors['bot.messenger_profile_details.persistent_menu.0.call_to_actions.'+current_action_index+'.url'][0] }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="m-5 bg-white shadow-md rounded " v-if="bot.messenger_profile_details.enable_greeting">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Greeting Details</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('greeting')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="bg-white px-6 pb-2">
                        <p class="text-red-500 text-xs italic" v-if="errors && errors['bot.messenger_profile_details.greeting']">{{ errors['bot.messenger_profile_details.greeting'][0] }}</p>
                    </div>
                    <div class="bg-white px-6 pb-2">
                        <div class="w-full">
                            <!-- Row One -->
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="greeting_text">Greeting Text</label>
                                    <input class="form-text" id="greeting_text" type="text" max="160" v-model="bot.messenger_profile_details.greeting[0].text">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors['bot.messenger_profile_details.greeting.0.text']">{{ errors['bot.messenger_profile_details.greeting.0.text'][0] }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="m-5 bg-white shadow-md rounded">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Whitelisted Domains</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('whitelisted_domains')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="bg-white my-6 p-4">
                        <p class="text-red-500 text-xs italic" v-if="errors && errors['bot.messenger_profile_details.whitelisted_domains']">{{ errors['bot.messenger_profile_details.whitelisted_domains'][0] }}</p>

                        <div class="w-full">
                            <div class="flex flex-wrap -mx-3 mb-6" v-for="(value, index) in bot.messenger_profile_details.whitelisted_domains" v-bind:key="index">
                                <div class="w-full md:w-4/5 px-3 mb-6 md:mb-0">
                                    <input class="form-text" :id="'whitelisted_domains_'+index" :name="'whitelisted_domains_'+index" type="text" v-model="bot.messenger_profile_details.whitelisted_domains[index]">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors['bot.messenger_profile_details.whitelisted_domains.'+index]">{{ errors['bot.messenger_profile_details.whitelisted_domains.'+index][0] }}</p>
                                </div>
                                <div class="w-full md:w-1/5 cursor-pointer h-full pl-6 text-left">
                                    <button class="btn btn-alert" @click="removeWhitelistedDomain(index)"><i class="fas fa-minus-circle"></i> REMOVE</button>
                                </div>
                            </div>

                            <div class="flex flex-wrap mx-3 mb-6">
                                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-3" @click="addWhitelistedDomain()"><i class="fas fa-plus-circle"></i> ADD</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-wrap m-5 px-3 pt-3">
                    <button class="btn btn-primary" v-on:click="save">
                        Update Bot
                    </button>

                    <button class="btn btn-secondary" v-on:click="cancel">
                        Cancel
                    </button>
                </div>
            </div>

            <div class="w-64" v-if="show_help_section">
                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'details'">
                    <h2 class="p-4">Bot Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Bots are used to organize interactions and person data, relating to a specific Facebook page.</p>
                            <p class="text-xs italic pb-2"><strong>Name</strong> - Provide a descriptive, unique name for the bot to help identify it for future edits and use.  Example: Campaign Bot.</p>
                            <p class="text-xs italic pb-2"><strong>Facebook Page</strong> - Select the Facebook Page this bot will be associated with for handling messages.  Note: if you don't see a Page you manage, please visit the Home page and Re-Authorize
                             your account, providing permissions on the missing page.</p>
                        </div>
                    </div>
                </div>

                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'persistent_menu'">
                    <h2 class="p-4">Persistent Menu Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Persistent Menus appear next to the chat text input through Facebook, providing for useful shortcuts (up to 3) for users to trigger outside of chatting.</p>
                            <p class="text-xs italic pb-2"><strong>Trigger Sequence</strong> - Provides a button that can trigger a sequence.</p>
                            <p class="text-xs italic pb-2"><strong>Open URL</strong> - Provides a button that can open an external URL in a new tab/window.</p>
                        </div>
                    </div>
                </div>

                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'get_started'">
                    <h2 class="p-4">Get Started Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Persistent Menus appear next to the chat text input through Facebook, providing for useful shortcuts (up to 3) for users to trigger outside of chatting.</p>
                            <p class="text-xs italic pb-2"><strong>Trigger Sequence</strong> - Provides a button that can trigger a sequence.</p>
                            <p class="text-xs italic pb-2"><strong>Open URL</strong> - Provides a button that can open an external URL in a new tab/window.</p>
                        </div>
                    </div>
                </div>

                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'greeting'">
                    <h2 class="p-4">Greeting Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Persistent Menus appear next to the chat text input through Facebook, providing for useful shortcuts (up to 3) for users to trigger outside of chatting.</p>
                            <p class="text-xs italic pb-2"><strong>Trigger Sequence</strong> - Provides a button that can trigger a sequence.</p>
                            <p class="text-xs italic pb-2"><strong>Open URL</strong> - Provides a button that can open an external URL in a new tab/window.</p>
                        </div>
                    </div>
                </div>

                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'whitelisted_domains'">
                    <h2 class="p-4">Whitelisted Domains Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Whitelisted domains are required by Facebook for loading custom elements within chat, and embedding checkbox components on pages.  Be sure to keep chat.seebot.run in the list, or certain chat components might not
                                work for your users.  If you host the checkbox component on a page, or use one of our webforms on a page, you'll want to include the domain where those items will be hosted.  Note:  Domains MUST be served over HTTPS and must be a fully
                                qualified domain (internal, development domains, IP addresses, or localhost are not allowed).
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'
    import Vue from 'vue'
    import ValidationNotice from '../../shared/ValidationNotice';
    import draggable from 'vuedraggable'

    export default {
        name: 'EditBot',
        components: {
            LayoutApp,
            ValidationNotice,
            draggable
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetchPages();
            this.fetchBot();
            this.fetchSequences();
        },
        computed: {
            id () {
                return this.$route.params.id;
            }
        },
        data() {
            return {
                pages: [],
                sequences: [],
                bot: {
                    id: '',
                    name: '',
                    fb_page_id: '',
                    status: 'active',
                    start_fb_sequence_id: null,
                    catchall_fb_sequence_id: null,
                    messenger_profile_details: {
                        enable_greeting: false,
                        greeting: [{
                            'locale': 'default',
                            'text': '',
                        }],
                        enable_persistent_menu: false,
                        persistent_menu: [{
                            'locale': 'default',
                            'composer_input_disabled': false,
                            'call_to_actions': []
                        }],
                        whitelisted_domains: [],
                    },
                },
                current_action_index: null,
                errors: [],
                action_errors: [],
                show_help_section: false,
            }
        },
        methods: {
            fetchPages() {
                axios.get(this.pagesUrl()).then(this.refreshPages);
            },
            pagesUrl() {
                return `/api/v1/me/pages`;
            },
            refreshPages({data}) {
                this.pages = data.pages;
            },
            fetchSequences() {
                axios.get(this.sequencesUrl()).then(this.refreshSequences);
            },
            sequencesUrl() {
                return `/api/v1/sequences?fb_bot_id=${this.id}`;
            },
            refreshSequences({data}) {
                this.sequences = data.data;
            },
            fetchBot() {
                axios.get(this.botUrl()).then(this.refreshBot);
            },
            botUrl() {
                return `/api/v1/bots/${this.id}`;
            },
            refreshBot({data}) {
                this.bot = data.data;
            },
            save() {
              axios.put('/api/v1/bots/'+this.id, this.bot).then(response => {
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    title: 'The bot has been updated',
                    duration: 5000,
                    speed: 1000
                });
                this.$root.$emit('bots_updated');

                this.$router.push({name: 'list_bots'});
              }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {}
                }
              });
            },
            cancel() {
                this.$router.back();
            },
            addAction(type) {
                if (this.bot.messenger_profile_details.persistent_menu[0].call_to_actions.length < 3) {
                    if (type === 'postback') {
                        this.bot.messenger_profile_details.persistent_menu[0].call_to_actions.push({
                            'type': 'postback',
                            'title': '',
                            'payload': {
                                'fb_sequence_id': null
                            }
                        });
                    } else if (type === 'web_url') {
                        this.bot.messenger_profile_details.persistent_menu[0].call_to_actions.push({
                            'type': 'web_url',
                            'title': '',
                            'url': '',
                            'webview_height_ratio': 'full'
                        });
                    }

                    this.current_action_index = this.bot.messenger_profile_details.persistent_menu[0].call_to_actions.length - 1;
                } else {
                    // Alert user only 3 options are allowed.
                }
            },
            setActiveAction(index) {
                this.current_action_index = index;
            },
            removeAction(index) {
                this.current_action_index = null;
                this.action_errors = {};
                this.errors = [];

                Vue.delete(this.bot.messenger_profile_details.persistent_menu[0].call_to_actions, index);
            },
            addWhitelistedDomain() {
                this.bot.messenger_profile_details.whitelisted_domains.push('');
            },
            removeWhitelistedDomain(index) {
                Vue.delete(this.bot.messenger_profile_details.whitelisted_domains, index);
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }
</script>
